import baseUrl from "../../../utils/baseUrl";
import getToken from "../../../utils/getToken";

export default async function ToggleFollow({isFollowed, currentUserProfileId, followUserProfileId}) {
  return fetch(baseUrl() + `/api/v1/user_profiles/${currentUserProfileId}/${isFollowed ? "unfollow" : "follow"}`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + getToken(),
    },
    body: JSON.stringify({
      user_profile: {
        user_profile_id: followUserProfileId,
      },
    }),
  })
}
