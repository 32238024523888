import React, { useEffect, useState } from "react";
import { Grid, GridItem, Box, Text, HStack, Center, Spacer, Flex } from '@chakra-ui/react';

import { useQuery, useQueryClient } from "react-query";
import { useLocation, useNavigate, useParams } from "react-router-dom";

import Page from "../components/Page";
import FilledButton from "../components/FilledButton";
import { ReactComponent as LikeShapeIcon } from "../icons/like.shape.svg";

import baseUrl from "../utils/baseUrl";
import getToken from "../utils/getToken";
import { queryGet } from "../utils/queryClient";
import { t } from "i18next";
import { isEmpty } from "lodash";
import { saveData } from "../utils/saveData";
import { getArtistId, getMemberIdAnyway } from "../utils/etcUtils";

async function likeMembers(artistId, memberIds) {
  return fetch(baseUrl() + `/artists/${artistId}/like_members`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + getToken(),
    },
    body: JSON.stringify({
      memberIds: memberIds
    }),
  }).then((data) => data.json())
}

async function likeArtist(friendlyId) {
  return fetch(baseUrl() + `/artists/${friendlyId}/like`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + getToken(),
    },
  }).then((data) => data.json());
}

export default function SelectMembersPage() {
  const params = useParams();
  const artistId = params.artist_id;

  const { state } = useLocation();
  const [selectedMemberList, setSelectedMemberList] = useState([]);

  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const callbackUrl = state?.callbackUrl?.replace(':artist_id', artistId);

  const { data: response } = useQuery(
    [`${artistId}members`],
    () => queryGet(`/artists/${artistId}/members`),
  );

  useEffect(() => {
    if(response && isEmpty(selectedMemberList)){
      const memberIds = response.members.reduce((ids, member) => {
        if (member.isLiked) { ids.push(getMemberIdAnyway(member)); }
        return ids;
      }, []);
      setSelectedMemberList(memberIds)
    }
  }, [response])


  const selectedMember = (memberId) => {
    const tempList = [...selectedMemberList];

    if(tempList.includes(memberId)) {
      setSelectedMemberList(tempList.filter((tempId) => memberId !== tempId))
    } else {
      tempList.push(memberId)
      setSelectedMemberList(tempList)
    }
  }

  const confirmedLikeMembers = async () => {
    if (state?.registerArtist) {
      const response = await likeArtist(artistId);
      if(response && response.success) {
        saveData("artist", artistId);
        queryClient.clear();
      } else {
        return alert(t("requestFailed"));
      }
    }

    if(selectedMemberList.length <= 0){
      return alert(t("selectMemberPage.errorAlert"))
    }

    likeMembers(artistId, selectedMemberList).then((response) => {
      if(response && response.success) {
        // queryClient.invalidateQueries("userProfile");
        queryClient.clear();

        if(state?.firstTime) {
          // navigate(`/artists/${artistId}/photo_cards`,{
          //   state: {
          //     artistId: artistId,
          //     members: response?.liked_members,
          //     showBackButton: true,
          //     firstTime: true,
          //     editable: true,
          // }})
          navigate(`/home`, { replace: true });
        } else if (callbackUrl) {
          return navigate(callbackUrl);
        } else {
          return navigate(-2);
        }
      } else {
        alert(t("requestFailed"))
      }
    })
  }

  const hasMember = (memberId) => {
    return selectedMemberList.includes(memberId)
  }

  return (
    <Page title={t("selectMemberPage.pageName")} noGutter={true}>
      <Flex direction="column" height="calc((var(--vh, 1vh) * 100) - env(safe-area-inset-top) - 48px)">
      <Spacer/>
      <Box pt="30px" pb="100px">
        <Text fontSize='26px'color={"#444444"} fontWeight={700} lineHeight={"120%"} pb="18px">
          {t("selectMemberPage.title")}
        </Text>
        {
          response?.members.map((member, index) =>{
            return (
              <Box pb={response.members.length - 1 === index ? "0px" : "16px"} key={getMemberIdAnyway(member)}>
                <HStack onClick={()=> selectedMember(getMemberIdAnyway(member))}>
                  <Text fontSize={"40px"} lineHeight={"48px"} fontWeight={800} color={hasMember(getMemberIdAnyway(member)) ? "#5C3DFA" : "#E7E7E7"}>{member.name}</Text>
                  <LikeShapeIcon fill={hasMember(getMemberIdAnyway(member)) ? "#5C3DFA" : "#FFFFFF"} width={42} height={42} style={{marginLeft: "3px"}}/>
                </HStack>
              </Box>
            )
        })}
      </Box>
      <Spacer/>
      </Flex>
      <Center className="mt-5">
        <FilledButton
          text={t("selectMemberPage.next")}
          onClicked={confirmedLikeMembers}
          minWidth={"335px"}
          minHeight={"52px"}
          paddingX={"24px"}
          paddingY={"16px"}
          className="bottomButton"
        />
      </Center>
    </Page>
  )
}
