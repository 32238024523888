import React, { useState } from "react";
import { Image, Text, Stack, Grid, GridItem, Wrap, WrapItem } from '@chakra-ui/react';
import { findIndex } from "lodash";
import { ReactComponent as CheckIcon } from "../icons/check.svg";
import PhotoCard from "./photocard/PhotoCard";
import { getGridSize, getPhotoCardHeight } from "../utils/photoCardSize";

export default function PhotoCardsByEvent({photoCardsByEvent, selectedPhotoCards, onClickedPhotoCard, collectingMode=true, showEventName=true, gridType="lg", ratio=1, searchText, searchPhotoCardsByEvent, showCollectedCount=false, enableAll=true, editable=true, removeCollected=false}) {
  const getIndex = (photoCard) => {
    return findIndex(selectedPhotoCards, (c) => c.photoCardId === photoCard.photoCardId)
  }
  const isEnabledCard = (photoCard) => {
    if(selectedPhotoCards){
      return getIndex(photoCard) >= 0;
    }
    else{
      return photoCard.collectedCount > 0
    }
  }

  const tradingCardCount = (photoCard) => {
    return selectedPhotoCards.filter((p) => p.photoCardId === photoCard.photoCardId).length
  }

  const [endOfScroll, setEndOfScroll] = useState(false);
  const listComponent = document.querySelector('.endOfList');
  document.addEventListener('scroll', () => {
    if (listComponent && (window.scrollY > listComponent.getBoundingClientRect().top)) {
      setEndOfScroll(true)
    }
  });

  const getPaginatedEvent = () => {
    // if(!endOfScroll) return photoCardsByEvent?.events
    return photoCardsByEvent?.events
  }

  return (
    <div className="endOfList">
    {
      showEventName
      ?
        (searchText ? searchPhotoCardsByEvent?.events?.filter((f) => f.event?.name?.toUpperCase().includes(searchText.toUpperCase())) : getPaginatedEvent())?.map((e) => {
          if(e.photoCards <= 0){
            return (<div key={e.event?.name}></div>)
          }else{
            return(
              <Stack key={e.event?.name} paddingBottom={"18px"}>
                <Wrap paddingTop={"10px"} paddingBottom={"4px"} align={"center"} marginLeft={"-1px"} spacing={0}>
                  <WrapItem alignItems={"center"}>
                    <Text fontSize={"20px"} marginRight={"4px"}>{e.event?.name?.slice(0,2)}</Text>
                    <Text fontSize={"13px"}>{e.event?.name}</Text>
                  </WrapItem>
                </Wrap>
                <Grid templateColumns={`repeat(${getGridSize(gridType)}, 1fr)`} gap={"4px"}>
                  {(removeCollected ? e.photoCards.filter((p) => p.collectedCount > 0) : e.photoCards).map((photoCard) => {
                    return (
                      <GridItem key={photoCard.photoCardId}>
                        {
                          (editable || collectingMode) ? (
                            <WrapItem className="relative">
                              <Image
                                src={photoCard.mediumThumbnailUrl || photoCard.pictureUrl}
                                className={enableAll ? "" : isEnabledCard(photoCard) ? "grayscale-0" : (editable ? "grayscale" : "grayscale opacity-50")}
                                height={"100%"}
                                width={"100%"}
                                border={collectingMode && isEnabledCard(photoCard) ? "2px" : ""}
                                borderColor={collectingMode && isEnabledCard(photoCard) ? "secondary.1000" : ""}
                                borderRadius="8px"
                                onClick={() => onClickedPhotoCard(photoCard, e.event)}
                                style={{boxSizing: "border-box"}}
                                loading={endOfScroll ? "lazy" : null}
                              />
                              {
                                showCollectedCount ?
                                (
                                  isEnabledCard(photoCard) ?
                                  <div className="absolute bottom-0 right-0 text-sm" style={{ backgroundColor: "#5C3DFA", borderRadius: "4px", color: "white", padding: "4px 8px" }}>
                                    {photoCard.collectedCount > 0 ? Math.max(photoCard.collectedCount - tradingCardCount(photoCard), 0) : photoCard.collectedCount}
                                  </div>
                                  :
                                  <div className="absolute bottom-0 right-0 text-sm" style={{ backgroundColor: "#444444", borderRadius: "4px", color: "white", padding: "4px 8px" }}>
                                    {photoCard.collectedCount}
                                  </div>
                                )
                                :
                                (
                                  collectingMode && isEnabledCard(photoCard) ?
                                    <div
                                      className="absolute right-0 bottom-0"
                                      style={{ backgroundColor: "#5C3DFA", borderTopLeftRadius: "8px", borderBottomRightRadius: "8px", padding: "3px" }}
                                    >
                                      <CheckIcon width={"16px"} height={"16px"} fill="white"/>
                                    </div>
                                  :
                                  <></>
                                )
                              }
                            </WrapItem>
                          ) : (
                            photoCard && <PhotoCard photoCard={photoCard} onClick={() => onClickedPhotoCard(photoCard, e.event)}/>
                          )
                        }
                      </GridItem>
                    )
                  })}
                </Grid>
              </Stack>
            )
          }
        })
      :
      <Grid templateColumns={`repeat(${getGridSize(gridType)}, 1fr)`} marginTop={"10px"} gap={"4px"}>
        {
          getPaginatedEvent()?.flatMap((e) => removeCollected ? e.photoCards.filter((p) => p.collectedCount > 0) : e.photoCards)?.map((p) => {
            return(
              <GridItem key={p.photoCardId}>
                <WrapItem className="relative">
                  <Image
                    src={p.pictureUrl}
                    className={enableAll ? "" : isEnabledCard(p) ? "grayscale-0" : (editable ? "grayscale opacity-50" : "grayscale")}
                    width={"100%"}
                    height={getPhotoCardHeight(gridType)}
                    border={collectingMode && isEnabledCard(p) ? "2px" : ""}
                    borderColor={collectingMode && isEnabledCard(p) ? "secondary.1000" : ""}
                    borderRadius="8px"
                    loading={endOfScroll ? "lazy" : null}
                  />
                  {
                    showCollectedCount ?
                    <div className="absolute top-0 right-0" style={{ backgroundColor: "#5C3DFA", borderRadius: "4px", padding: 3, color: "white" }}>
                      {p.collectedCount}
                    </div>
                    : <></>
                  }
                </WrapItem>
              </GridItem>
            )
          })
        }
        </Grid>
    }
    </div>
  )
}
