import React, { useEffect, useState } from "react";

import { Center, Flex, SimpleGrid, Spacer, Spinner, Switch } from '@chakra-ui/react';
import { t } from "i18next";
import { chunk, isEmpty, isUndefined } from "lodash";
import EasyVirtualized from 'react-easy-virtualized';
import styled from "styled-components";

import { useQueryClient } from "react-query";
import LightColors from "../../constants/LightColors";
import TradingPhotoCardResponse from "../api/model/TradingPhotoCardResponse";
import useExploreTradingPhotoCardsQueryV2 from "../api/queries/useExploreTradingPhotoCardsQueryV2";
import SecondaryLineButton from "../buttons/SecondaryLineButton";
import TradingPhotoCardFilterBottomDrawer from "../drawer/TradingPhotoCardFilterBottomDrawer";
import HorizontalScrollMembers from "../lists/HorizontalScrollMembers";
import ArtistIconSelector from "../selectors/ArtistIconSelector";
import GridSkeletons from "../skeletons/GridSkeletons";
import EmptyExploreTradingPhotoCard from "./EmptyExploreTradingPhotoCard";
import ExploreTradingPhotoCard from "./ExploreTradingPhotoCard";

// const BoxTabWrapper = styled.div`
//   padding: 0px 24px;
// `

const FilterButtonText = styled.div`
  color: ${(props) => (props.isChecked ? LightColors.primary : LightColors.textmainlv1)};
  font-size: 14px;
  font-weight: 600;
  line-height: 17px
`


export default function SalesTabPanel({fabWrapperRef}) {
  // const [boxTabIndex, setBoxTabIndex] = useState(0);
  return(
    <div>
      {/* <BoxTabWrapper>
        <BoxTabs tabLabels={[t("SalesTabPanel.purchaseSubTab"), t("SalesTabPanel.saleSubTab")]} onTabSelected={(index) => setBoxTabIndex(index)}/>
      </BoxTabWrapper>
      {
        boxTabIndex === 0 ? <ExploreSaleTab/> : <MySaleTradingPhotoCarsTab/>
      } */}
      <ExploreSaleTab fabWrapperRef={fabWrapperRef}/>
    </div>
  )
}


const ArtistIconSelectorWrapper = styled.div`
  padding-bottom: 8px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.05);
`

const TitleWrapper = styled.div`
  padding: 16px 24px 0px 24px;
`

const MembersWrapper = styled.div`
  padding: 8px 0px 14px 0px;
`

const Title = styled.div`
  font-size: 17px;
  font-weight: 700;
  line-height: 20px;
  text-align: left;
  color: ${LightColors.textmainlv2};
`

const CardsWrapper = styled.div`
  padding: 0px 24px;
`

const ExploreSaleTab = ({fabWrapperRef}) => {
  const queryClient = useQueryClient();

  const [selectedArtist, setSelectedArtist] = useState(null);
  const [selectedMember, setSelectedMember] = useState(null);
  const [selectedEventIds, setSelectedEventIds] = useState([]);
  const [onlyIdentityVerified, setOnlyIdentityVerified] = useState(false);
  const [selectedPhotoCardCategories, setSelectedPhotoCardCategories] = useState([]);

  const [isFilteringDrawerOpen, setIsFilteringDrawerOpen] = useState(false);
  const [paginatedTradingPhotoCards, setPaginatedTradingPhotoCards] = useState();

  const handlePaginatedTradingPhotoCards = (paginatedData) => {
    const tradingPhotoCards = paginatedData.pages?.flatMap((page) =>
      page.tradingPhotoCards.map((tradingPhotoCard) => new TradingPhotoCardResponse(tradingPhotoCard))
    )
    setPaginatedTradingPhotoCards(tradingPhotoCards);
  }

  const { data, isFetching, fetchNextPage, hasNextPage } = useExploreTradingPhotoCardsQueryV2({
    artistId: selectedArtist?.artistId,
    memberIds: selectedMember?.memberId ?? [],
    eventIds: selectedEventIds,
    onlyIdentityVerified: onlyIdentityVerified,
    photoCardCategories: selectedPhotoCardCategories,
    category: "sale",
  });

  const loadMore = () => {
    if(!isFetching && hasNextPage) {
      fetchNextPage()
    }
  }

  useEffect(() => {
    if (!isEmpty(data)) {
      handlePaginatedTradingPhotoCards(data);
    }
  }, [data]);

  const initFilters = () => {
    setSelectedEventIds([]);
    setSelectedPhotoCardCategories([]);
    setOnlyIdentityVerified(false);
  }

  const onSelectedArtist = (artist) => {
    setSelectedArtist(artist);
    if (selectedArtist && selectedArtist.artistId !== artist.artistId) {
      initFilters();
    }
  }

  const handleFilterSelected = (filteredObj) => {
    setIsFilteringDrawerOpen(false);
    if (!isEmpty(filteredObj)) {
      setOnlyIdentityVerified(filteredObj.identityVerified);
      setSelectedEventIds(filteredObj.eventIds);
      setSelectedPhotoCardCategories(filteredObj.photoCardCategories);

      queryClient.invalidateQueries(["ExploreTradingPhotoCardsV2"]);
    }
  }

  const isCheckedPhotoCardFilter = () => {
    return onlyIdentityVerified || !isEmpty(selectedPhotoCardCategories) || !isEmpty(selectedEventIds)
  }

  const onSelectedMember = (member) => {
    if(isEmpty(member)) {
      setSelectedMember(null);
    } else {
      setSelectedMember(member);
    }
  }

  const onOpenFilterDrawer = (isOpen) => {
    if(fabWrapperRef.current) {
      fabWrapperRef.current.style.visibility = isOpen ? 'hidden' : 'visible';
    }
    setIsFilteringDrawerOpen(isOpen);
  }

  return (
    <div>
      <ArtistIconSelectorWrapper>
        <ArtistIconSelector onSelected={(artist) => onSelectedArtist(artist)}/>
      </ArtistIconSelectorWrapper>
      <TitleWrapper>
        <Flex align={"center"} marginY={"2px"}>
          <Title>
            {t("tradingPhotoCardPage.exchangeTabTitle", { fandom: selectedArtist?.fandomName || "-", saleType: t("tradingPhotoCardPage.sale") })}
          </Title>
          <Spacer/>
          <SecondaryLineButton
            text={
              <FilterButtonText isChecked={isCheckedPhotoCardFilter()}>
                {t("photoCardPage.filter")}
              </FilterButtonText>
            }
            rightElement={
              <Switch
                size={'sm'}
                alignContent={"center"}
                colorScheme={"primary"}
                isChecked={isCheckedPhotoCardFilter()}
                onChange={() => onOpenFilterDrawer(true)}
                style={{"--switch-track-height": "0.6rem"}}
                // TODO Switch BGColor
              />
            }
            style={{
              margin: "1.5px 0px",
              border: isCheckedPhotoCardFilter() ? `1.5px solid ${LightColors.primary}` : `1px solid rgba(0, 0, 0, 0.05)`,
              boxShadow: isCheckedPhotoCardFilter() ? "0px 0px 5px 0px rgba(255, 118, 250, 0.2), 0px 0px 8px 0px rgba(161, 142, 255, 0.3)" : "",
              padding: "8px 14px",
              gap: "4px",
            }}
            onClickButton={() => onOpenFilterDrawer(true)}
          />
        </Flex>
      </TitleWrapper>
      <MembersWrapper>
        <HorizontalScrollMembers
          members={selectedArtist?.members}
          selectedMember={selectedMember}
          onSelectedMember={(member) => onSelectedMember(member)}
        />
      </MembersWrapper>
      <CardsWrapper>
        {
          isUndefined(paginatedTradingPhotoCards) ?
          <GridSkeletons gridSize={3} skeletonSize={3} skeletonHeight={'96px'} showText={true}/> :
          (
            isEmpty(paginatedTradingPhotoCards) ?
            <EmptyExploreTradingPhotoCard/> :
            <div style={{paddingBottom: "calc(56px + env(safe-area-inset-bottom))"}}>
              <EasyVirtualized
                useParentScrollElement={true}
                onLoadMore={loadMore}
                hasMore={hasNextPage}
                loader={
                  <Center marginTop={"8px"}>
                    <Spinner size='sm' color="primary.500" marginRight={"20px"}/>
                  </Center>
                }
              >
                {
                chunk(paginatedTradingPhotoCards, 3).map((chunkCards, chunkIndex) => (
                  <SimpleGrid key={chunkIndex} columns={3} spacing={"7px"} marginBottom={"24px"}>
                    {
                      chunkCards.map((card) => {
                        return(
                          <ExploreTradingPhotoCard
                            key={card.tradingPhotoCardId}
                            tradingPhotoCard={card}
                          />
                        )
                      })
                    }
                  </SimpleGrid>
                  ))
                }
              </EasyVirtualized>
            </div>
          )
        }
      </CardsWrapper>
      {
        isFilteringDrawerOpen &&
        <TradingPhotoCardFilterBottomDrawer
          isOpen={isFilteringDrawerOpen}
          onClose={() => onOpenFilterDrawer(false)}
          onConfirm={(filteredObj) => handleFilterSelected(filteredObj)}
          identityVerified={onlyIdentityVerified}
          artistId={selectedArtist?.artistId}
          eventIds={selectedEventIds}
          photoCardCategories={selectedPhotoCardCategories}
        />
      }
    </div>
  )
}
