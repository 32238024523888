import { useInfiniteQuery } from "react-query";
import { queryGet } from "../../../utils/queryClient";

const DEFAULT_PAGE = 20;

export default function useUserProfilesLikedPost(postId, perPage = DEFAULT_PAGE) {
  const fetchUserProfilesLikedPost = async ({ pageParam = 1 }) => {
    const queryParams = createQueryParams({ postId, perPage, pageParam });
    const url = `/api/v1/posts/${postId}/liked_user_profiles?${queryParams.toString()}`;

    try {
      return await queryGet(url);
    } catch (error) {
      throw new Error("사용자 프로필 검색 중 오류가 발생했습니다.");
    }
  };

  return useInfiniteQuery(
    ["UserProfilesLikedPost", postId],
    fetchUserProfilesLikedPost,
    {
      enabled: !!postId,
      getNextPageParam: (lastPage) => lastPage?.meta?.pagination?.nextPage || undefined,
    }
  );
}

// 쿼리 파라미터 생성 함수
function createQueryParams({ postId, perPage, pageParam }) {
  return new URLSearchParams({
    postId,
    per_page: perPage,
    page: pageParam,
  });
}
