import { isEmpty, isNull } from "lodash";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import LightColors from "../../constants/LightColors";
import ProfileAvatar from "../thumbnails/ProfileAvatar";
import { openNewTab } from "../../utils/UIUtils";

const NotificationItemContainer = styled.div`
  display: flex;
  align-items: center;
  border-bottom: 1px solid ${LightColors.separatorlv2};
  padding-top: 8px;
  padding-bottom: 14px
`;

const ProfileAvatarWrapper = styled.div`
  width: 38px;
  height: 38px;
`

const ItemWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: left;
  padding-left: 12px;
  padding-right: 12px;
`;

const ContentWrapper = styled.div`
  display: flex;
  align-items: left;
`;

const Title = styled.div`
  font-size: 16px;
  font-weight: 600;
  color: ${LightColors.textmainlv1};
`;

const Content = styled.span`
  font-weight: 500;
  color: ${LightColors.textmainlv2};
  font-size: 14px;
`;

const CreatedAt = styled.span`
  font-weight: 500;
  color: ${LightColors.textmainlv4};
  font-size: 13px;
  padding-left: 2px;
`;

const Picture = styled.img`
  width: 38px;
  height: 38px;
  object-fit: cover;
  border-radius: 8px;
`;


const NotificationItem = ({ notification }) => {
  const navigate = useNavigate();

  const NavigateNotifierProfile = () => {
    if (!isEmpty(notification.notifierId)) {
      navigate(`/feed/user_profile/${notification?.notifierId}`)
    }
  }

  const NotifierThumbnailUrl = () => {
    if (isNull(notification.notifierId)) {
      if (isNull(notification.notifierThumbnailUrl)) {
        return "/assets/icons/ghost_profile_icon.png";
      } else {
        return null;
      }
    }
    return notification.notifierThumbnailUrl;
  }

  const NavigateNotifiable = () => {
    if(isEmpty(notification.notifiableId)) return;

    if (notification.notifiableType === "Post") {
      navigate(`/posts/${notification.notifiableId}`)
    } else if (notification.notifiableType === "Review") {
      navigate(`/reviews/${notification.notifiableId}`)
    } else if (notification.notifiableType === "PhotoCard") {
      navigate(`/photo_cards/${notification.notifiableId}`)
    } else if (notification.notifiableType === "Follow") {
      openNewTab(`/feed/user_profile/${notification.notifiableId}`)
    }
  }

  return (
    <NotificationItemContainer>
      <ProfileAvatarWrapper onClick={() => NavigateNotifierProfile()}>
        <ProfileAvatar
          profileImageUrl={NotifierThumbnailUrl()}
          widthAndHeight={"34px"}
        />
      </ProfileAvatarWrapper>
      {/* <Thumbnail src={notification.authorThumbnailUrl} alt="author" /> */}
      <ItemWrapper onClick={() => NavigateNotifiable()}>
        {
          !isEmpty(notification.title) && <Title>{notification.title}</Title>
        }
        <ContentWrapper>
          <Content>
            {notification.content} <CreatedAt>{notification.createdAt}</CreatedAt>
          </Content>
        </ContentWrapper>
      </ItemWrapper>
      {notification.pictureUrl && (
        <Picture
          src={notification.pictureUrl}
          alt="notification"
          onClick={() => NavigateNotifiable()}
        />
      )}
    </NotificationItemContainer>
  );
};


export default NotificationItem;
