import { Box, Center, HStack, Text } from '@chakra-ui/react';
import React from "react";
import LightColors from "../../constants/LightColors";

export default function PrimaryButton(
  {
    text,
    disabled,
    icon,
    ...props
  }
) {
  return (
    <Box
      className="text-center"
      textColor={disabled ? "#B9B9B9" : LightColors.purewhite}
      backgroundColor={disabled ? "#E7E7E7" : LightColors.primary}
      borderRadius={"52px"}
      lineHeight={"17px"}
      paddingX={"12px"}
      paddingY={"8px"}
      fontWeight={500}
      fontSize={"14px"}
      {...props}
    >
      {
        icon ?
        <HStack spacing={"2px"}>
          <Text fontWeight={600} lineHeight={"17px"} textColor={LightColors.purewhite}>{text}</Text>
          {icon}
        </HStack> :
        <Center><p>{text}</p></Center>
      }
    </Box>
  )
}
