// src/pages/KeywordGridPage.js
import { VStack } from '@chakra-ui/react';
import { motion } from 'framer-motion';
import { isEmpty } from 'lodash';
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';

import { LineButton } from '../../components/buttons/LineButton';
import RegularSolidButton from '../../components/buttons/RegularSolidButton';
import StickyHeader from "../../components/StickyHeader";
import LightColors from '../../constants/LightColors';

const Title = styled.div`
  padding-top: calc(64px + env(safe-area-inset-top));
  text-align: left;
  font-size: 24px;
  font-weight: 700;
  line-height: 31px;
  color: ${LightColors.textmainlv1};
  margin: 16px 0;
  padding-left: 24px;
  padding-right: 24px;
  white-space: pre-wrap;
`;

const GridContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 10px;
  margin: auto;
  padding: 20px;
  width: 100%;
`;

const KEYWORDS = [
  { key: "morning_person", value: "아침형 인간" },
  { key: "unique_taste", value: "개성있는 취향" },
  { key: "public_transport_enthusiast", value: "대중교통 매니아" },
  { key: "foodie", value: "맛집잘알" },
  { key: "contact_addict", value: "연락중독" },
  { key: "hate_phone", value: "전화싫어" },
  { key: "animal_lover", value: "동물애호가" },
  { key: "environmentalist", value: "환경보호" },
  { key: "feminism", value: "페미니즘" },
  { key: "travel_enthusiast", value: "여행 매니아" },
  { key: "non_alcoholic", value: "알콜 쓰레기" },
  { key: "good_at_handicrafts", value: "손재주 좋아" },
  { key: "twitter_instead_of_x", value: "엑스 말고 트위터" },
  { key: "meme_genius", value: "밈천재" },
  { key: "obsessive_fan", value: "지독한 얼빠" },
  { key: "delulu_fan", value: "망붕러" }
];

const SelectPersonalKeywordPage = () => {
  const [selectedKeywords, setSelectedKeywords] = useState([]); // 선택된 키워드를 저장할 상태
  const [showNextButton, setShowNextButton] = useState(false); // 다음 단계 버튼 표시 상태 추가

  const navigate = useNavigate();

  const handleKeywordClick = (keyword) => {
    setSelectedKeywords((prev) => {
      const updatedKeywords = prev.includes(keyword.key)
        ? prev.filter((key) => key !== keyword.key) // 이미 선택된 키워드라면 제거
        : [...prev, keyword.key]; // 선택되지 않은 키워드라면 추가

      // 선택된 키워드가 하나 이상일 경우 버튼 표시
      setShowNextButton(!isEmpty(updatedKeywords));
      return updatedKeywords;
    });
  };

  // 다음 단계로 이동하는 함수
  const handleNextStep = () => {
    const currentQueryParams = new URLSearchParams(window.location.search);
    navigate(`/fan-buddy/select-filter?keywords=${selectedKeywords.join(',')}&${currentQueryParams.toString()}`);
  };

  const isSelectedKeyword = (keywordKey) => {
    return selectedKeywords.includes(keywordKey)
  }

  return (
    <>
      <StickyHeader
        title={"덕메찾기"}
        alwaysShowTitle={true}
        showBackButton={true}
      />
      <motion.div
        initial={{ opacity: 0, y: -10 }} // 위에서 아래로 이동
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.3 }}
      >
        <Title>
          {"당신의 키워드 체크하여\n빙고판을 완성해주세요!"}
        </Title>
      </motion.div>
      <motion.div
        initial={{ opacity: 0, y: -10 }} // 위에서 아래로 이동
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.3, delay: 0.3 }}
      >
      <GridContainer>
        {KEYWORDS.map((keyword) => (
          <motion.div
            key={keyword.key}
            initial={{ scale: 0.95 }}
            animate={{ scale: 1 }}
            whileTap={{ scale: 0.98 }}
            transition={{
              type: "spring",
              stiffness: 300,
              damping: 15
            }}
          >
            <LineButton
              key={keyword.key}
              isSelected={isSelectedKeyword(keyword.key)}
              onClick={() => handleKeywordClick(keyword)}
              text={keyword.value}
              style={{minHeight: "82px", wordBreak: "keep-all"}}
            />
          </motion.div>
        ))}
      </GridContainer>
      </motion.div>
      {showNextButton && (
        <VStack spacing={2} style={{ position: "fixed", bottom: "calc(4px + env(safe-area-inset-bottom))", left: 0, right: 0, width: "100%", padding: "20px 0", display: "flex", justifyContent: "center", alignItems: "center" }}>
          <RegularSolidButton
            text="다음 단계로 이동"
            onClick={handleNextStep}
            style={{ fontSize: "15px", fontWeight: 500, padding: "16px 20px", width: "calc(100% - 48px)", borderRadius: "8px" }}
          />
        </VStack>
      )}
    </>
  );
};

export default SelectPersonalKeywordPage;
