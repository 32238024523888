import React, { useEffect, useState } from "react";

import { Container, Flex, Image } from '@chakra-ui/react';
import { useNavigate } from "react-router-dom";
import BaseAgreementBottomSheet from "../components/bottomSheet/BaseAgreementBottomSheet";
import RouteChangeTracker from "../utils/RouteChangeTracker";
import { refreshScreenSize } from "../utils/UIUtils";
import { getJWT } from "../utils/etcUtils";

export default function IntroPage() {
  const navigate = useNavigate();
  const [isOpenAlert, setIsOpenAlert] = useState(false)

  const startLogin = (isPromotionAgreed) => {
    navigate(`/request_verification_code?isPromotionAgreed=${isPromotionAgreed}&isTermsAgreed=true`)
  }

  const token = getJWT();
  // const token = getJWT();
  // if(token) {
    // const aritst = getArtistId();
    // if(aritst) {
    //   window.location.href = `/feed`
    // } else {
    //   window.location.href = `/artists?artistId=register_artist`
    // }
  // }
  useEffect(() => {
    if(token && navigate) {
      navigate(`/home`, { replace: true });
    }
  }, [token, navigate]);



  const handleAgreement = (isChild, isPromotionAgreed) => {
    if(isChild) {
      // setIsOpenAlert(true);
    } else {
      startLogin(isPromotionAgreed)
    }
  }

  refreshScreenSize();
  RouteChangeTracker();

  return (
    (!token) && <>
      <Container
        maxW={'xl'}
        h={{base: "calc(var(--vh, 1vh) * 100)"}}
        borderRadius={{xl: "45px"}}
        borderWidth={{xl: "10px"}}
        borderColor={{xl: "#FFF"}}
        boxShadow={{xl: "0px 0px 15px 2px rgba(255,255,255,0.8)"}}
        position={"relative"}
        overflow={"hidden"}
        centerContent
      >
        <video autoPlay muted loop playsInline className="video-background">
          <source src={"assets/backgrounds/bg_holography.mp4"} type="video/mp4" />
        </video>
        <Flex height={"100%"} direction={"column"} justifyContent={"space-between"}>
          <Image
            src='/assets/logos/pocapan.svg'
            alt='pocapan'
            justifyContent={"center"}
            alignItems={"center"}
            style={{
              height: "70%",
              filter: "drop-shadow(0px 0px 10px rgba(255, 255, 255, 0.3))",
            }}
          />
        </Flex>
      </Container>
      <BaseAgreementBottomSheet
        isOpen={true}
        onAgreementClick={(isChild, isPromotionAgreed) => handleAgreement(isChild, isPromotionAgreed)}
        transparentBackground={true}
      />
    </>
  )
}
