import { Box, Center, Image, Spacer, Spinner, Switch, Text, VStack, Wrap } from "@chakra-ui/react";
import { t } from "i18next";
import { first, isEmpty, isUndefined } from "lodash";
import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet-async";
import { useQueryClient } from "react-query";
import styled from "styled-components";

import CommonDialog from "../components/alerts/CommonDialog";
import EventResponse from "../components/api/model/EventResponse";
import upsertCollectedPhotoCards from "../components/api/mutations/upsertCollectedPhotoCards";
import useArtistQuery from "../components/api/queries/useArtistQuery";
import usePhotoCardEventsQuery from "../components/api/queries/usePhotoCardEventsQuery";
import MyPhotoCardBottomSheet from "../components/bottomSheet/MyPhotoCardBottomSheet";
import RegularSolidButton from "../components/buttons/RegularSolidButton";
import SecondaryLineButton from "../components/buttons/SecondaryLineButton";
import PhotoCardFilterBottomDrawer from "../components/drawer/PhotoCardFilterBottomDrawer";
import EmptyCase from "../components/errors/EmptyCase";
import LikedMembersList from "../components/lists/LikedMembersList";
import { ReportPhotoCardBox } from "../components/report/ReportPhotoCardBox";
import EmptyPhotoCardEventSkeletonV2 from "../components/skeletons/EmptyPhotoCardEventSkeletonV2";
import PhotoCardsByEventEditor from "../components/virtualized/PhotoCardsByEvent/PhotoCardsByEventEditor";
import LightColors from "../constants/LightColors";
import { ReactComponent as ArrowLeftIcon } from "../icons/arrow.left.svg";
import ScrollRestoration from "../utils/ScrollRestoration";
import { getArtistId } from "../utils/etcUtils";
import { useHandleHistoryBack } from "../utils/useHandleHistoryBack";

const PageWrapper = styled.div`
  padding-top: calc(env(safe-area-inset-top));
  background-attachment: scroll;
  height: 100vh;
`

const Row = styled.div`
  display: flex;
  flex-direction: row;
`

const LikedMembersListWrapper = styled.div`
  padding-top: 6px;
  padding-bottom: 16px;
  margin-bottom: 96px;
  padding-left: 24px;
  padding-right: 24px;
`
const ReportPhotoCardBoxWrapper = styled.div`
  margin-top: 18px;
`

const FilterButtonText = styled.div`
  color: ${(props) => (props.isChecked ? LightColors.primary : LightColors.textmainlv1)};
  font-size: 14px;
  font-weight: 600;
  line-height: 17px
`

export default function EditCollectedPhotoCardsPage() {
  // #region states & variables
  const [isOpenExitDialog, setIsOpenExitDialog] = useState(false);
  // 바텀시트 보이는지
  const [isMyPhotoCardsBottomSheetOpen, setIsMyPhotoCardsBottomSheetOpen] = useState(false);
  const [currentMember, setCurrentMember] = useState();

  // Initialize state for paginated data and updated data
  const [photoCardSummary, setPhotoCardSummary] = useState({});
  const [paginatedEvents, setPaginatedEvents] = useState();
  const [updatedCollectedData, setUpdatedCollectedData] = useState({});
  const [selectedCollectedData, setSelectedCollectedData] = useState({});
  const [isColorModeOnEditable, setIsColorModeOnEditable] = useState(false);
  const [selectedArtist, setSelectedArtist] = useState(null);
  const [isShowFilterBottomDrawer, setIsShowFilterBottomDrawer] = useState(false);
  const [selectedEventIds, setSelectedEventIds] = useState([]);
  const [selectedPhotoCardCategories, setSelectedPhotoCardCategories] = useState([]);

  const artistId = getArtistId();
  const queryClient = useQueryClient();
  const handleHistoryBack = useHandleHistoryBack();
  // #endregion

  // #region API
  const artist = useArtistQuery(artistId);
  const { data, isFetching, fetchNextPage, hasNextPage } = usePhotoCardEventsQuery({
    artistId: selectedArtist?.artistId || artistId,
    memberId: currentMember?.memberId,
    eventIds: selectedEventIds,
    photoCardCategories: selectedPhotoCardCategories,
  });
  // #endregion

  ScrollRestoration(window.location.pathname);

  // #region Function to handle updated data set.
  const updatedPhotoCardWithPrevData = (prevData, photoCard) => {
    const memberId = (photoCard.memberId || photoCard.member?.memberId || currentMember?.memberId)
    return {
      ...prevData,
      [memberId]: {
        ...prevData[memberId],
        [photoCard.photoCardId]: {
          ...prevData[memberId]?.[photoCard.photoCardId],
          collectedCount: photoCard.collectedCount,
        },
      },
    };
  }

  const handleUpdatedCollectedPhotoCard = (photoCard) => {
    setUpdatedCollectedData((prevData) => {
      return updatedPhotoCardWithPrevData(prevData, photoCard)
    });
  };

  const handleSelectedCollectedPhotoCard = (photoCard) => {
    setSelectedCollectedData((prevData) => {
      return updatedPhotoCardWithPrevData(prevData, photoCard)
    });
  };
  // #endregion


  // Function to handle the received paginated data
  const handlePaginatedData = (newPaginatedData) => {
    const updatedEvents = newPaginatedData.flatMap((page) =>
      page.events.map((event) => {
        const newEvent = {
          ...event,
          photoCards: event?.photoCards?.map((photoCard) => {
            const memberId = photoCard.memberId;

            let newCollectedCount = photoCard.collectedCount;
            if(selectedCollectedData[memberId] && selectedCollectedData[memberId][photoCard.photoCardId]) {
              newCollectedCount = selectedCollectedData[memberId][photoCard.photoCardId]?.collectedCount
            }else if(updatedCollectedData[memberId] && updatedCollectedData[memberId][photoCard.photoCardId]) {
              newCollectedCount = updatedCollectedData[memberId][photoCard.photoCardId]?.collectedCount
            }

            return {
              ...photoCard,
              ...{ collectedCount: newCollectedCount },
            };
          }),
        };
        // Exclude null or empty events and photoCards
        if (!isEmpty(newEvent) && !isEmpty(newEvent.photoCards)) {
          return new EventResponse(newEvent);
        }
        return null;
      })
    );
    setPaginatedEvents(updatedEvents.filter((event) => event !== null));
  };

  // Use useEffect to update paginatedData and updatedData on new data arrival
  useEffect(() => {
    if (!isEmpty(data)) {
      handlePaginatedData(data.pages);
      setPhotoCardSummary(first(data.pages)?.meta?.photoCardsInfo);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, updatedCollectedData, selectedCollectedData]);

  // #region events
  const loadMore = () => {
    if(hasNextPage){
      fetchNextPage()
    }
  }

  // 멤버 선택 변경
  const onChangedMember = (member) => {
    setCurrentMember(member);
  }

  // 네비게이션 뒤로가기 눌렀을 경우
    const onHistoryBackClick = () => {
      const upsertRequest = selectedCollectedData[currentMember?.memberId];
      if(isEmpty(upsertRequest)){
        return IgnoreAndExit();
      }
      return setIsOpenExitDialog(true);
    }

  const IgnoreAndExit = () => {
    setSelectedCollectedData({});
    setIsOpenExitDialog(false);
    return handleHistoryBack()
  }

  const onClickPhotoCard = (event, photoCard) => {
    if(photoCard.collectedCount > 0) {
      handleSelectedCollectedPhotoCard({...photoCard, collectedCount: 0});
    } else {
      handleSelectedCollectedPhotoCard({...photoCard, collectedCount: 1});
    }
  }

  const onCompletedEditable = async () => {
    const upsertRequest = selectedCollectedData[currentMember?.memberId];
    if(isEmpty(upsertRequest)){
      return IgnoreAndExit();
    }

    const response = await upsertCollectedPhotoCards(artistId, upsertRequest);
    response.collectedPhotoCards.forEach(photoCard => {
      handleUpdatedCollectedPhotoCard(photoCard);
    });

    window.opener?.postMessage({
      type: "update-collected-count-on-artist-photo-cards",
      params: {
        photoCardIds: response.collectedPhotoCards.map((photoCard) => photoCard.photoCardId),
      }
    }, window.location.origin);

    setSelectedCollectedData({});
    // handleHistoryBack()
  }

  const onColorModeSwitchChanged = () => {
    setIsColorModeOnEditable(!isColorModeOnEditable);
  }

  const onChangePhotoCardFilter = () => {
    setIsShowFilterBottomDrawer(!isShowFilterBottomDrawer)
  }

  const onConfirmPhotoCardFilter = (filteredObj) => {
    setIsShowFilterBottomDrawer(false);

    if (!isEmpty(filteredObj)) {
      setSelectedEventIds(filteredObj.eventIds);
      setSelectedPhotoCardCategories(filteredObj.photoCardCategories);

      queryClient.invalidateQueries(["photoCardEvents", artistId]);
      queryClient.invalidateQueries(["collectedPhotoCardIds"]);
    }
  }

  const isCheckedPhotoCardFilter = () => {
    return !isEmpty(selectedPhotoCardCategories) || !isEmpty(selectedEventIds)
  }
  // #endregion

  return (
    <PageWrapper className="homeBackground">
      <Helmet>
        <title>{t("tabs.collections")}</title>
        <meta name="theme-color" content={LightColors.purewhite} />
      </Helmet>
      <Header onConfirm={onCompletedEditable} onHistoryBackClick={onHistoryBackClick}/>
      <SwitchColorModeBar
        isColorModeOnEditable={isColorModeOnEditable}
        onColorModeSwitchChanged={onColorModeSwitchChanged}
      />
      <PhotoCardsSummaryRow
        totalCount={photoCardSummary?.totalCount}
        collectedCount={photoCardSummary?.collectedCount}
        isCheckedPhotoCardFilter={isCheckedPhotoCardFilter}
        onChangePhotoCardFilter={onChangePhotoCardFilter}
      />
      <LikedMembersListWrapper>
        <LikedMembersList artistMembers={artist?.members?.filter((member) => member.isLiked)} onChangeLikedMember={onChangedMember}/>
        {
          isUndefined(paginatedEvents) ?
          <EmptyPhotoCardEventSkeletonV2/> :
          (
            isEmpty(paginatedEvents) ?
            <div style={{width: "100%"}}>
              <EmptyCase emoji={"🥺"} description={t("photoCardPage.emptyPhotoCards")}/>
            </div> :
            <div className="w-full">
              <PhotoCardsByEventEditor
                events={paginatedEvents}
                onClickedPhotoCard={(photoCard, e) => onClickPhotoCard(e, photoCard)}
                endReached={loadMore}
                enableAll={false}
                isColorMode={isColorModeOnEditable}
              />
              {isFetching && <Center marginTop={"8px"}><Spinner size='sm' color="primary.500" marginRight={"20px"}/></Center>}
            </div>
          )
        }
        {
          artist && currentMember &&
          <ReportPhotoCardBoxWrapper>
            <ReportPhotoCardBox artist={selectedArtist} member={currentMember}/>
          </ReportPhotoCardBoxWrapper>
        }
      </LikedMembersListWrapper>
      {
        isMyPhotoCardsBottomSheetOpen &&
        <MyPhotoCardBottomSheet
          isMyPhotoCardsBottomSheetOpen={isMyPhotoCardsBottomSheetOpen}
          setIsMyPhotoCardsBottomSheetOpen={setIsMyPhotoCardsBottomSheetOpen}
        />
      }
    {
      isOpenExitDialog &&
      <CommonDialog
        isOpen={isOpenExitDialog}
        onClose={() => setIsOpenExitDialog(false)}
        description={t("photoCardPage.discardAlertDialog")}
        noButtonText={t("no")}
        yesButtonText={t("yes")}
        onClickDialogButton={() => IgnoreAndExit()}
      />
    }
    {
      isShowFilterBottomDrawer &&
      <PhotoCardFilterBottomDrawer
        artistId={artistId}
        isOpen={isShowFilterBottomDrawer}
        photoCardCategories={selectedPhotoCardCategories}
        eventIds={selectedEventIds}
        onClose={() => setIsShowFilterBottomDrawer(false)}
        onConfirm={(obj) => onConfirmPhotoCardFilter(obj)}
      />
    }
    </PageWrapper>
  )
}

const Header = ({onConfirm, onHistoryBackClick}) => {
  return(
    <Row style={{padding: "12px 24px"}}>
      <ArrowLeftIcon fill={LightColors.pureblack} onClick={() => onHistoryBackClick()}/>
      <Spacer/>
      <RegularSolidButton
        text={t("confirm")}
        onClicked={onConfirm}
      />
    </Row>
  )
}

const PhotoCardsSummaryRow = ({totalCount, collectedCount, isCheckedPhotoCardFilter, onChangePhotoCardFilter}) => {
  return(
    <Row style={{padding: "12px 24px"}}>
      <VStack align={"start"} spacing={"4px"}>
        <Text textColor={"#444"} fontWeight={600} fontSize={"17px"} lineHeight={"20px"}>
          {t("CollectionsHomePage.RegisteredPhotoCards", { count: totalCount })}
        </Text>
        <Text textColor={"#777"} fontWeight={400} fontSize={"13px"} lineHeight={"16px"}>
          {t("CollectionsHomePage.CollectedPhotoCards", { count: collectedCount })}
        </Text>
      </VStack>
      <Spacer/>
      <SecondaryLineButton
        text={
          <FilterButtonText isChecked={isCheckedPhotoCardFilter()}>
            {t("photoCardPage.filter")}
          </FilterButtonText>
        }
        rightElement={
          <Switch
            size={'sm'}
            alignContent={"center"}
            colorScheme={"primary"}
            isChecked={isCheckedPhotoCardFilter()}
            onChange={() => onChangePhotoCardFilter()}
            style={{"--switch-track-height": "0.6rem"}}
            // TODO Switch BGColor
          />
        }
        style={{
          margin: "1.5px 0px",
          border: isCheckedPhotoCardFilter() ? `1.5px solid ${LightColors.primary}` : `1px solid rgba(0, 0, 0, 0.05)`,
          boxShadow: isCheckedPhotoCardFilter() ? "0px 0px 5px 0px rgba(255, 118, 250, 0.2), 0px 0px 8px 0px rgba(161, 142, 255, 0.3)" : "",
          padding: "8px 14px",
          gap: "4px",
        }}
      />
    </Row>
  )
}

const SwitchColorModeBar = ({isColorModeOnEditable, onColorModeSwitchChanged}) => {
  return (
    <Row style={{padding: "10px 24px"}}>
      <Box
        paddingX={"20px"}
        paddingY={"14px"}
        width={"100%"}
        borderRadius={"12px"}
        backgroundColor="#0000000A"
      >
        <Wrap justify={"center"}>
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
          >
            <Image width="16px" height="16px" src="/assets/icons/color.mode.png" marginRight={"7px"}/>
            <Text color={"#444444"} fontSize={"14px"} fontWeight={500} lineHeight={"16.8px"}>
              {t("photoCardPage.showColorMode")}
            </Text>
          </Box>
          <Spacer/>
          <Switch
            size='sm'
            colorScheme="primary"
            isChecked={isColorModeOnEditable}
            onChange={()=>onColorModeSwitchChanged()}
          />
        </Wrap>
      </Box>
    </Row>
  )
}
