import { Flex, Spacer, Stack, Text } from "@chakra-ui/react";
import { t } from "i18next";

import { EmojiBySupportedCountry } from "../../constants/Countries";
import { NotificationBadge } from "../../pages/badge/NotificationBadge";


export default function HomeHeader({
  user,
  title=t("feedPage.title"),
  onClickTitle,
  onClickProfileButton
}) {
  const countryCode = localStorage.getItem("feedCountryCode");

  return(
    <Stack spacing={3}>
      {
        <Flex>
          <Text fontSize={"26px"} fontWeight={700} color={"#222"} onClick={onClickTitle}>
            {`${title} ${EmojiBySupportedCountry(countryCode || user?.country)}`}
          </Text>
          <Spacer/>
          <NotificationBadge/>
        </Flex>
      }
    </Stack>
  )
}
