import React from "react";

import { GridItem, Image, SimpleGrid, Stack, useBreakpointValue, WrapItem } from '@chakra-ui/react';
import { motion } from "framer-motion";
import { Virtuoso } from "react-virtuoso";
import styled from 'styled-components';

import colors from '../../../constants/colors';
import { EventNameBox } from '../../event/EventNameBox';
import CheckIcon from '../../icons/CheckIcon';

const EventNameBoxWrapper = styled.div`
  overflow: hidden;
  padding-top: 20px;
  padding-bottom: 9px;
`

const IconWrapper = styled.div`
  background-color: ${colors.primary};
  border-top-left-radius: 8px;
  border-bottom-right-radius: 8px;
  padding: 3px;
`


export default function PhotoCardsByEventEditor({
  events,
  onClickedPhotoCard,
  endReached,
  isColorMode,
}) {

  const isEnabledCard = (photoCard) => {
    return photoCard.collectedCount > 0
  }

  const columns = useBreakpointValue({
    base: 4,   // 모바일
    md: 6,     // iPad
    lg: 6,     // 큰 화면
  });

  // #region draw cards
  const drawCardsWithEventName = (e) => {
    if (e.photoCards <= 0) {
      return (<div key={e.eventId}></div>)
    } else {
      return(
        <motion.div
          initial={{ opacity: 0, y: -1 }}
          animate={{ opacity: 1, y: 0 }}
          exit={{ opacity: 0, y: -1 }}
          transition={{ duration: 0.5 }}
        >
          <Stack key={e.eventId} paddingBottom={"4px"}>
            <EventNameBoxWrapper>
              <EventNameBox event={e}/>
            </EventNameBoxWrapper>
            <SimpleGrid columns={columns} spacing={"4px"} marginInlineStart={0} marginTop={"0px!important"}>
              {(e.photoCards)?.map((photoCard) => {
                return (
                  <motion.div
                    initial={{ opacity: 0, y: -1 }}
                    animate={{ opacity: 1, y: 0 }}
                    exit={{ opacity: 0, y: -1 }}
                    transition={{ duration: 0.5 }}
                  >
                  <GridItem key={photoCard.photoCardId} aspectRatio={"55/87"}>
                    <WrapItem className="relative" aspectRatio={"55/87"}>
                      <Image
                        src={photoCard.mediumThumbnailUrl || photoCard.pictureUrl}
                        className={isColorMode ? "grayscale-0" : (isEnabledCard(photoCard) ? "grayscale-0" : "grayscale")}
                        aspectRatio={"55/87"}
                        width={"100%"}
                        objectFit={"cover"}
                        border={isEnabledCard(photoCard) ? "2px" : ""}
                        borderColor={isEnabledCard(photoCard) ? "secondary.1000" : ""}
                        borderRadius="8px"
                        onClick={() => onClickedPhotoCard(photoCard, e.event)}
                        style={{boxSizing: "border-box"}}
                        loading="lazy"
                      />
                      {
                        isEnabledCard(photoCard) &&
                        <IconWrapper className="absolute right-0 bottom-0">
                          <CheckIcon/>
                        </IconWrapper>
                      }
                    </WrapItem>
                  </GridItem>
                  </motion.div>
                )
              })}
            </SimpleGrid>
          </Stack>
        </motion.div>
      )
    }
  };
  // #endregion

  return (
    <Virtuoso
      useWindowScroll
      style={{ height: '100%' }}
      endReached={endReached}
      overscan={480}
      data={events}
      itemContent={(_, e) => {return drawCardsWithEventName(e)}
    }/>
  )
}
