import { UnauthorizedAccessPage } from "../../../pages";

export const ko = {
  translation: {
    pocapan: '포카판',
    requestFailed: '요청이 실패하였습니다.',
    next: '다음',
    done: '완료',
    save: '저장',
    confirm: '확인',
    cancel: '취소',
    login: '로그인',
    logout: '로그아웃',
    photoCardCount: '{{count}}개',
    cardsCount: '{{count}}장',
    entire: '전체',
    report: '신고',
    send: '전송',
    megaphone: '메가폰',
    picture: '사진',
    photoCard: '포토카드',
    poca: '포카',
    anonymous: '익명',
    title: "제목",
    comment: '댓글',
    like: '좋아요',
    leave: '나가기',
    entireMembers: '전체 멤버',
    sendMessage: '메시지 보내기',
    accept: '수락',
    decline: '거절',
    yes: '예',
    no: '아니오',
    global: "글로벌",
    select: "선택",
    follow: "팔로우",
    dofollow: "팔로우하기",
    followBack: "맞팔로우",
    doFollowBack: "맞팔로우하기",
    follower: "팔로워",
    following: "팔로잉",
    mutualFollowing: "맞팔중",
    tabs: {
      home: '홈',
      collections: '포카리스트',
      feed: '피드',
      pocapan: '포카리스트',
      store: '쇼핑',
      tradingPhotoCard: '양도판',
      reportPhotoCard: '포카제보',
      profile: '마이',
      notifications: '알림'
    },
    CollectionsHomePage: {
      RegisteredPhotoCards: '{{count}} 개의 포카',
      CollectedPhotoCards: '{{count}} 보유중',
      EditPhotoCardFabButton: "보유 포카 편집하기",
      ReportPhotoCardFabButton: "빠진 포카 추가하기",
      DummyBirthdayCafe: "생일카페, 촬영지, 광고판 등\n우리들만의 오프라인 장소를 모아볼 수 있어요!",
      DummyGoods: "인형, 키링, 앨범 등\n다양한 굿즈들을 모아볼 수 있어요!",
      DummyFashion: "최애의 착장 정보를 모아볼 수 있어요",
      DummyHomeMaster: "컨텐츠를 모아볼 수 있어요",
      DummyDescription: "열심히 포카판이 데이터를 정리중이에요!\n완료되면 알림을 보내드릴게요.",
      WatingButton: "오픈되면 알림받기",
      WatingCompleted: "알림 신청 완료",
      WatingToast: "오픈되면 알림을 보내드릴게요",
    },
    feedPage: {
      title: '피드',
      defaultProfile: 'Profile 🔒',
      activeProfile: "<bold>{{nickname}}</bold>으로 활동중입니다.",
      emptyProfile: "프라이빗 프로필 만들기",
      alertMarquee: '포카 양도는 안전한 거래를 위해 양도판에서 진행해주세요. 단순 포카 양도 글은 무통보 삭제됩니다!',
      onlyFandom: 'Only 팬덤 글만 보기',
      mutualFandom: '나와 겹치는 팬덤글만 보기',
      newPostNotification: '🔝 새로운 글 보기',
      uploadToastMessage: '업로드 완료!',
      uploadToastShowPost: '보기',
      fandomFeed: '{{fandom}}판',
      searchPlaceholder: "덕메를 검색해보세요",
      recentTab: "최근",
      followingTab: "팔로잉",
      friendshipTab: "친목",
      marketplaceTab: "홍보",
      forSaleFabButton: "양도해요🤝",
      fanBuddyFabButton: "#덕메구함👯",
      questionFabButton: "궁금해요🧐",
      freeTalkFabButton: "아무말대잔치💬",
    },
    feedIntroPage: {
      title: '피드 소개',
      introTitle: '이제 포카판에서도\n얼마든지 떠들수 있어요!',
      firstRowTitle: '익명 기능으로 몰래 떠들어요!',
      firstRowDescription: '나와 동일한 팬덤에게만 내 글이 노출돼요!',
      secondRowTitle: '메가폰으로 좋은 건 같이봐요',
      secondRowDescription: '인용해서 사족 붙이기도 가능',
      thirdRowTitle: 'Only팬덤 기능으로 우리끼리만 공개해요',
      thirdRowDescription: '친한친구 기능도 준비중이에요',
      confirmFeedAgreement: '변경된 약관에 동의한 것으로 간주합니다.'
    },
    feedReportPage: {
      pageTitle: '신고하기',
      menuInsult: '욕설 / 비하발언',
      menuFalseInformation: '허위 사실 유포',
      menuObscenity: '음란성',
      menuOverPosting: '게시판 / 댓글 도배',
      menuPromotion: '홍보성',
      menuOther: '기타',
      author: '작성자',
      content: '글 내용',
      selectReportReason: '신고 사유를 선택해주세요'
    },
    addPostPage: {
      pageTitle: '새글 작성',
      title: '프라이빗 프로필 만들기',
      titlePlaceHolder: '여기를 누르면 제목을 추가할 수 있어요',
      contentPlaceHolder: '게시글 내용을 작성해주세요',
      sellingPlaceHolder: "포카 양도는 양도판을 통해야 더 빨리 거래되고 채팅도 이용이 가능해요.\n그외 물품을 양도할때는 상품정보, 희망 가격, 택배 방법 등을 구체적으로 적어주세요.",
      lookingForFriendsPlaceHolder: "당신의 최애와 관심 분야를 소개해주세요.\n그리고 함께 덕질할 친구들과는 어떤 식으로 소통하고 싶은지 알려주면 좋아요!",
      questionPlaceHolder: "무엇이든 궁금한 게 있다면 마음껏 물어보세요.\n포카 시세부터 일상 고민까지, 무엇이든 환영해요!",
      freeTalkPlaceHolder: "아무 말이나 괜찮아요.\n일상 이야기부터 최애 자랑, 짤방까지 전부 대환영!",
      contentPicturesError: '최대 4장, 총 용량 50MB로 업로드 해주세요.',
      disclaimer: '욕설, 비방 등 상대방을 불쾌하게 하는 의견은 남기지 말아주세요. 신고를 당하면 커뮤니티 이용이 제한될 수 있어요.',
      onlyFandom: '{{fandom}}만 볼 수 있어요.',
      onlyFandomDescription: '설정하면 글이 선택한 팬덤에게만 보여요!',
      addPhotoCard: '포카 첨부',
      addPhotoCardBoard: '포카보드',
      friendsOnly: '친한 친구 (준비중)'
    },
    postDetailPage: {
      pageTitle: '글 상세 {{title}}',
      deleteMenu: '삭제하기',
      deletedDescription: '댓글을 작성한 원글이 삭제되었습니다.',
      postOnFeed: '이 댓글을 피드에도 올리기',
      alertDeletedPost: '원글이 삭제되었습니다.',
      commentPlaceHolder: '댓글 남기기',
      sourcePostDescription: '<bold>{{post}}</bold>글에 남긴 댓글입니다.'
    },
    userFeedProfilePage: {
      pageTitle: '프라이빗 프로필',
      postTab: '작성글',
      anonymousTab: '익명',
      commentTab: '댓글',
      navigateTradingProfile: "양도프로필 가기",
      menuChangeNickname: "닉네임 변경",
      menuHiddenTradingProfile: "양도프로필 비공개설정",
      menuOpenTradingProfile: "양도프로필 공개설정",
      anonymousOnlyToYou: "익명 탭은 나만 볼 수 있어요.",
    },
    addPhotoCardPage: { pageTitle: '포카 첨부' },
    post: {
      emptyPost: '작성된 글이 없습니다.',
      emptyFollowerPost: '팔로우한 사람의 글이 여기에 보여요.',
      megaphonedPost: '{{author}} 님이 메가폰 외치는 중',
      commentedPost: `"{{content}}" 글에 남긴 댓글`,
      deletedPost: '댓글을 작성한 원글이 삭제되었습니다.',
      deletedCommentMenu: '삭제하기',
      confirmDelete: '정말 삭제하시겠어요?',
      reportPostMenu: '글 신고하기',
      reportUserMenu: '유저 신고하기',
      deletePostMenu: '삭제하기',
      onlyFandom: 'Only\n{{fandom}}',
      reply: '답글달기'
    },
    createFeedUserProfilePage: {
      pageTitle: '프라이빗 프로필 만들기',
      title: '프라이빗 프로필 만들기',
      description: '피드에서는 안전과 사생활을 위해\n프라이빗 프로필로만 소통이 가능합니다!',
      nickNamePlaceHolder: '닉네임을 입력해 주세요'
    },
    reportBottomSheet: {
      megaphone: '바로 외치기',
      unmegaphone: '메가폰 취소',
      quotes: '의견 추가하면서 외치기'
    },
    exploreTradingPhotoCardBottomSheet: {
      errorNoProfileToast: '요청에 실패했습니다. 프로필이 생성되어있는지 확인해보세요.',
      tradingPhotoCardInfo: '양도 포카 정보',
      sendMessage: '메시지 보내기'
    },
    photoCardBottomNav: {
      collectedPhotoCardCount: '{{totalCount}}개 중 {{collectedCount}}',
      collectedPhotoCardPercentage: '{{member}} 드볼 {{percentage}} %',
      editModeButton: '보유 포카 편집'
    },
    introPage: { startButton: '포카판 시작하기' },
    requestVerificationCodePage: {
      emailErrorMessage: '이메일 주소가 올바른 형식인지 확인해주세요',
      title: '로그인에 사용할\n이메일을 알려 주세요',
      description: '아무에게도 공개되지 않으니 걱정마세요!',
      placeHolder: '여기를 눌러 이메일을 입력해 주세요'
    },
    loginWithCodePage: {
      title: '인증 코드를 입력해 주세요',
      description: '{{email}} 으로 보냈어요!',
      notReceivedMessage: '인증 메일이 오지 않았나요?',
      sendAgainMessage: '다시 보내기',
      successSendAlert: '이메일을 재전송 하였습니다.',
      failedSendAlert: '재전송에 실패하였습니다. 잠시 후 다시 시도해주세요.',
      verificationFailedAlert: '인증에 실패하였습니다. 다시 한번 확인해주세요.'
    },
    welcomePage: { title: '만나서 반가워요!', description: '당신의 덕질을 더 편하게 해드릴께요' },
    selectArtistPage: {
      pageName: '아티스트 선택',
      title: '아티스트를 골라주세요',
      description: '없는 그룹은 요청이 많아지면 추가할게요!',
      errorAlert: '한명의 아티스트를 선택해주세요.',
      whoIsNext: 'Who is next?'
    },
    selectMemberPage: {
      pageName: '멤버 선택',
      title: '최애 멤을 골라주세요',
      errorAlert: '멤버를 한명 이상 선택해주세요.',
      next: '완료',
      confirmed: '멤버 변경 완료'
    },
    photoCardPage: {
      pageName: '포카리스트',
      title: '갖고있는 포카를 선택하세요',
      description: '선택하지 않은 포카는 없는 포카로 자동 정리돼요!',
      errorAlert: '멤버를 한명 이상 선택해주세요.',
      navigationSkip: '다음에 할래요',
      navigationReport: '제보하기',
      navigationEdit: '편집',
      confirmErrorAlert: '포토카드를 한개 이상 선택해주세요.',
      listTitle: '포카리스트',
      bannerTitle: '나만의 포카보드 만들기',
      showPhotoCardsOnFeed: '포카리스트 피드에서 보기',
      manageArtist: '💖 아티스트 관리',
      emptyPhotoCards: '포카리스트가 없습니다.',
      showColorMode: '컬러 모드로 보기',
      discardAlertDialog: '변경사항이 있는데,\n편집을 취소하시겠어요?',
      likedArtistPhotoCardList: '{{artist}} 포카',
      filter: "필터",
    },
    checkPhotoCardBottomSheet: { title: '포카리스트', report: '제보하기' },
    emptyPhotoCard: {
      title: '체크한 포카가 여기에 표시됩니다',
      description: '내가 갖고 있는 포카를 체크해보세요!',
      startCollectPhotoCard: '체크하러 가기'
    },
    myPhotoCardBottomSheet: {
      smallSize: '작게 보기',
      middleSize: '중간 보기',
      largeSize: '크게 보기',
      shareImageTwitterButton: '이 이미지 트위터에 공유하기',
      collectedPhotoCards: '모은 포카',
      unCollectedPhotoCards: '없는 포카',
      wishedPhotoCards: '찜한 포카',
      showPhotoCardName: '포카 이름 보기',
      createPhotoCardBoard: '포카보드 만들기',
      shareTwiiterText: 'https://twitter.com/intent/tweet?text=1. 다운로드 아이콘을 이용해 이미지를 받으세요.%0A2. ‘아이폰’이라면 ‘파일’앱에서 이미지를 찾아주세요.%0A3. 트위터에 첨부해 덕메들에 게 자랑해보세요.%0A덕질을 편하게! 포카판&url=pocapan.com'
    },
    reportPhotoCardPage: {
      pageName: '포토카드 제보',
      title: '포토카드 제보',
      categoryDescription: '어떤 유형의 포카인가요?',
      eventDescription: '어떤 유형의 포카인가요?',
      requiredMissingAlert: '모두 선택해주세요.',
      reportDoneAlert: '감사합니다! 확인 후 최대한 빨리 업데이트 할게요!',
      selectPlaceHolder: '여기를 눌러 찾아주세요!',
      directlyInputOption: '직접입력',
      reportDone: '제보하기',
      duplicatedAlert: '사진이 업로드 중입니다.',
      categoryTitle: "어떤 문제로 신고할 건가요?",
      incorrect: "정보가 잘못됐어요",
      duplicated: "같은 포카가 있어요",
      blurryOrWatermark: "사진이 흐리거나 로고가 있어요",
      unofficial: "비공식 포카",
      other: "기타",
      contentTitle: "왜 신고하는지 말해주세요",
      placeHolderContent: "신고 이유가 있다면 적어주세요",
      replacementPhotoTitle: "새로운 포토카드 이미지가 있나요?",
    },
    ReportSearchEventPage: {
      placeHolder: "활동명을 검색해주세요",
      newAddEvent: `"<bold>{{eventName}}</bold>"으로 새로 만들기 +`,
      pending: "미승인",
      unknownEventText: "찾으시는 활동명이 없나요?\n원하는 활동명을 직접 입력해 추가할 수 있어요!"
    },
    userProfilePage: {
      pageName: '마이 포카판',
      title: '마이',
      myPhotoCard: '나의 포토카드',
      changeMember: '드볼 멤버 변경',
      initializePhotoCards: '보유 포토카드 초기화',
      notice: '공지사항',
      terms: '서비스 이용약관',
      privacy: '개인정보처리방침',
      deleteAccount: '계정삭제',
      logoutConfirmAlert: '로그아웃을 하시겠습니까?',
      deleteAccountConfirmAlert: '계정 삭제를 하시겠습니까?',
      initializePhotoCardsAlert: '모든 포카가 초기화 됩니다. 계속 진행하시겠습니까?',
      errorIntializePhotoCardsAlert: '잠시후 다시 시도해주세요.'
    },
    addTradingPhotoCardPage: {
      exchangePageName: '내 포카 교환하기',
      salesPageName: '내 포카 팔기',
      title: '양도하고 싶은\n포카를 골라주세요',
      exchangeTitle: '교환을 보낼 포카를\n선택해주세요',
      salesTitle: '판매할 포카를 선택해주세요',
      searchInputPlaceholder: '이름, 앨범명 검색',
      countButton: '{{count}} 추가',
      myPhotoCardTitle: '나의 포카',
      searchPhotoCardTitle: '전체 포카',
    },
    childLoginPage: {
      pageName: '보호자 미동의 이용제한',
      title: '보호자 미동의로\n이용이 제한되어있습니다',
      description: '보호자 동의가 없는 만 14세 미만의 경우 보호자의 동의가 완료될때까지 이용이 제한되고, 5일 이내 보호자 동의가 필요합니다.\n' +
        '미동의시 계정은 탈퇴되고 모든 정보는 파기됩니다.\n' +
        '(이용정지일 : 2023년 5월 24일)',
      enableAccountGuide: '보호자 동의는 메일로 진행되오니\n부모님께서 직접 아래 메일로 문의 바랍니다.\n\npocapan.official@gmail.com',
      deleteAccount: '계정 탈퇴하기',
      backToIntro: '처음으로 돌아가기'
    },
    blockUserPage: {
      pageName: '이용제한',
      title: '이용제한',
      description: '다수의 사용자로부터 신고가 들어와,\n' +
        '이용제한 조치 되었습니다.\n' +
        '이용 문의는 pocapan.official@gmail.com 으로\n' +
        '메일을 보내주세요.'
    },
    requiredLoginPage: {
      pageName: '로그인 필요',
      title: '로그인이 필요합니다',
      description: '알 수 없는 오류로 로그인이 풀렸습니다.\n안전한 이용을 위해 앱을 이용해주세요!'
    },
    emptyTradingPhotoCard: {
      title: '{{nickname}}님 양도할 포카를 등록해주세요!',
      description: '포카판이 매칭 대상을 찾아줄게요.',
      addTradingPhotoCard: '양도 포카 등록 하기'
    },
    emptyExploreTradingPhotoCard: {
      title: '아직 올라온 포카가 없습니다.',
      description: '다른 사람들이 양도중인 포카만 보여요',
      addTradingPhotoCard: '양도 포카 등록 하기'
    },
    emptyWishedTradingPhotoCard: {
      title: '아직 찜한 포카가 없네요!',
      description: '찜한 포카를 모아 한눈에 확인하세요',
      addTradingPhotoCard: '양도 포카 등록 하기'
    },
    emptyWishedMatchedTradingPhotoCard: {
      title: '아직 찜한 교환이 없네요!',
      description: '교환할까 말까 망설일때 한번 사용해보세요',
      addTradingPhotoCard: '양도 포카 등록 하기'
    },
    emptyMessages: { title: '아직 메시지가 없어요!', button: '양도 포카 둘러보기' },
    forceToUpdate: {
      pageTitle: '💌 포카판팀의 편지 💌',
      title: '앱 업데이트와 함께\n💖새로운 피드가 찾아옵니다💖',
      button: '업데이트 하러가기',
      firstRowDescription: '안녕하세요. 팀 포카판입니다!\n' +
        '벌써 포카판을 시작한지 1년이 되어가네요.\n' +
        '여러분이 많은 관심을 가져주시고\n' +
        '또 이용해주신 덕분에 여기까지 올 수 있었습니다.\n' +
        '포카판은 포토카드 교환 플랫폼에서\n' +
        '팬 중심의 덕질 문화 보금자리로 거듭나고자',
      secondRowDescription: '더 편하고 자유롭게 떠들수있도록\n새로운 피드를 오픈합니다.',
      thirdRowDescription: '떨리고 설레는 마음으로 여러분을 초대합니다.'
    },
    tradingPhotoCardPage: {
      pageTitle: '양도판',
      exchangeTabName: '교환하기',
      salesTabName: '구매하기',
      manageMyTradingPhotoCard: '양도 포카 관리',
      marqueeText: '🔍서치는 이제 그만! 양도판으로 교환 상대를 쉽게 찾아보아요! 교환 시 발생하는 문제에 대해서는 포카판이 별도로 책임 지기 어려우니 거래는 반드시 신중하게 해주세요!',
      thisCardOnlyForSale: '선택한 포카는 판매 전용 포카로 설정되어 있습니다.',
      tradingPhotoCardChatRooms: '해당 포카로 대화 중인 채팅방',
      noChatRoom: '아직 대화 중인 채팅방이 없습니다.',
      exchangeEventTradingPhotoCards: '양도 받을 수 있는 동일 이벤트 포카',
      emptyEventTradingPhotoCards: '동일 이벤트 포카가 아직 없어요',
      exchangeTradingPhotoCards: '교환 가능한 포카',
      addProfileBottomSheetTitle: '교환할 때 사용할 프로필을 설정해주세요',
      tradingManagerBarTitle: "내가 양도할 포카",
      tradingManagerBarSaleTitle: '나의 판매 포카',
      tradingManagerBarExchangeTitle: '나의 교환 포카',
      tradingManagerBarManage: '관리',
      aggregatedPhotoCardCardTitle: '양도 받을수 있는 해당 포카',
      showUserProfilesBottomSheetTitle: '어떤 {{fandom}}랑 교환하시겠어요?',
      notAvailableExchangeTradingPhotoCards: '교환 가능한 포카가 없어요🥲',
      offetSalesTradingPhotoCardsRow1: '아래 {{fandom}}들한테',
      offetSalesTradingPhotoCardsRow2: '직접 구매',
      offetSalesTradingPhotoCardsRow3: '도 가능해요!',
      matchedTradingPhotoCardBottomDrawerTitle: '양도 포카 정보',
      matchedTradingPhotoCardMine: '내 포카',
      matchedTradingPhotoCardTarget: '상대방 포카',
      addBlackListMenu: '이 카드 더이상 매칭 안받기',
      reportMenu: '신고하기',
      reMatching: '다시 찾기',
      exchange: '양도',
      sale: '판매',
      salesSwitchTitle: '판매 전용 포카만 보기',
      exchangeTabTitle: '{{fandom}}들이 {{saleType}} 중인 카드들',
      salesSwitchDescription: '직접 메시지를 보내 양도를 받으세요!',
      saleBadge: '판매용',
      excludeMatching: '매칭제외',
      allowMatching: '매칭허용',
      offerPrice: '제시',
      offerPriceWithEmoji: '제시🧚‍♂️',
      tradingPhotoCardInfoBar: '양도 카드 관리',
      noProfileToast: '요청에 실패했습니다. 프로필이 생성되어있는지 확인해보세요.',
      askPrice: '가격 물어보기',
      purchaseWithPrice: '{{price}}원에 구매'
    },
    myTradingPhotoCardsPage: { pageName: '양도 카드 관리' },
    tradingPhotoCardChatRoom: {
      pageTitle: '메시지',
      noticeDeletedUser: '탈퇴된 회원입니다. 추가 문의가 필요한 경우 포카판으로 문의해주세요.',
      noticeLeftUser: '상대방이 대화를 종료하여, 더 이상 대화가 불가능합니다.',
      leaveChatRoomDialogTitle: '대화방을 나가시겠어요?',
      leaveChatRoomDialogDescription: '대화방을 나가게 되면, 이 거래는 종료되고 상대방에게 더 이상 메시지를 받을 수 없어요!',
      leaveChatRoomDialogConfirm: '대화방 나가기',
      saleTradingMenu: "판매중",
      purchasingTradingMenu: "구매중",
      completedTradingMenu: "거래 완료"
    },
    photoCardDetailPage: {
      firstCaptorTitle: '이 카드의 포카 요정들💖',
      collected: '보유중',
      collectedCount: '{{count}}장 보유중',
      notCollected: '미보유',
      changeToNotCollected: '미보유로 변경',
      changeCollectedCount: '수량 변경',
      exchangeTradingPhotoCardTitle: '아래 포카들과 지금 바로 교환이 가능해요!',
      saleTradingPhotoCardTitle: '이 포카를 판매중인 {{fandom}}',
      emptysaleTradingPhotoCards: '아직 해당 카드를 판매중인 사람이 없어요.',
      feedTitle: '{{fandom}}판',
      feedDescription: '더 많은 내용이 궁금하다면 피드로!',
      showAllFeed: '전체보기',
      firstCaptorBadge: '🧚퍼스트캡터',
      changeCollectedBottomSheetTitle: '카드를 보유하고 계시는군요!',
      changeCollectedBottomSheetDescription: '현재 보유중인 해당 카드의 수량을 입력해주세요.',
      tradeButton: "양도하기",
      title: '{{memberName}}의 {{categoryName}} {{categoryEmoji}}'
    },
    myPhotoCards: {
      pageName: '거래 내역',
      totalCount: '총 {{count}} 개',
      tradingStatusSelectorOngoing: '진행중인 거래',
      tradingStatusSelectorCompleted: '종료된 거래',
      tradingPhotoCardBoxByPhotoCardBadge: '대화방 {{count}}',
      exchangingPhotoCardsTabName: '교환',
      exchangingPhotoCardsTabEmptyCaseTitle: '아직 교환한 포카가 없어요',
      exchangingPhotoCardsTabEmptyCaseButton: '양도하러 가기',
      exchangingPhotoCardsTabReviewNotYet: "‘교환'에 대한 후기 보내기 기능은 아직 준비중이에요",
      sellingPhotoCardsTabName: '판매',
      sellingPhotoCardsTabEmptyCaseTitle: '아직 판매한 포카가 없어요',
      sellingPhotoCardsTabEmptyCaseButton: '판매하러 가기',
      purchasingPhotoCardsTabName: '구매',
      purchasingPhotoCardsTabEmptyCaseTitle: '아직 구매한 포카가 없어요',
      purchasingPhotoCardsTabEmptyCaseButton: '구매하러 가기',
      purchasingPhotoCardsTabSaleBadge: '판매용',
      purchasingPhotoCardsTabEmptyExchangeBadge: '교환용',
      chatRoomPageName: '거래중인 대화방',
      chatRoomPageSoldOutButton: '후기보내기는 지원 예정이에요🖤',
      chatRoomPageCompletedButton: '종료된 거래에요',
      chatRoomPageConvertToSaleButton: '판매용으로 변경',
      chatRoomPageConvertToExchangeButton: '교환으로 변경',
      chatRoomPageEmptyCase: '아직 거래중인 대화방이 없어요',
      chatRoomExchangeDialogTitle: '교환 전용으로 변경하시겠어요?',
      chatRoomExchangeDialogDescription: '기존 거래중인 대화방은 종료되고\n상대방에게 알려집니다.\n종료되도 대화는 계속 진행할 수 있어요!',
      chatRoomCompleteDialogTitle: '종료된 거래인가요?',
      chatRoomCompleteDialogDescription: '상대방에게 후기보내기 기능은\n곧 지원 예정입니다!',
      chatRoomSaleDialogTitle: '판매 전용으로 변경하시겠어요?',
      chatRoomSaleDialogDescription: '기존 거래중인 대화방은 종료됩니다.\n미입력시, 제시로 표기됩니다.',
      chatRoomSaleDialogInputPlaceholder: '금액을 입력해주세요',
      changePriceDialogTitle: '가격을 변경하시겠어요?',
      changePriceDialogDescription: `미입력시 "제시"로 표기됩니다.`,
      onlySale: '판매 전용',
      editPrice: '수정',
      sentReviewButton: "내가 보낸 후기 보기",
      sendReviewButton: '💘 양도 후기 보내기 💘',
      receivedReviewButton: "상대방에게 받은 후기 보기",
      doneAndSendReview: "종료하고 후기 남기기",
    },
    tradingReportPage: {
      pageName: '포카판 신고하기',
      title: '포카판 신고센터',
      descriptionFirstRow: '• 신고는 자동 시스템으로 1차적으로 처리되며, 중복 접수 시에는 운영자가 추가로 확인하여 진행됩니다.',
      descriptionSecondRow: '• 만약 신고 사항이 법적 문제로 발전할 경우, 포카판은 어떤 책임도 지지 않습니다. 필요한 경우 개인 정보와 대화 내용을 제공할 수 있습니다.',
      reportCode: '신고코드',
      reportDescription: '신고내역',
      reportDescriptionPlaceholder: '상세한 내용을 입력해주세요.',
      reportDescriptionEmptyErrorMessage: '내용을 입력해주세요.',
      reportAttchment: '첨부파일',
      reportAttchmentPlaceHolder: '파일을 첨부해주세요.',
      reportUserEmail: '신고자의 메일주소',
      reportUserEmailEmptyErrorMessage: '이메일을 입력해주세요.',
      reportUserEmailFormatErrorMessage: '이메일 형식을 확인해주세요.',
      reportAgreement: '개인정보제공 및 대화내용 열람에 동의합니다.',
      reportSubmit: '제출하기',
      successToastMessage: '신고가 정상적으로 접수 되었습니다.',
      failedToastMessage: '요청이 실패하였습니다. 잠시 후 다시 시도해주세요.'
    },
    blacklistCards: {
      manage: '관리',
      exclude: '매칭 제외 카드 관리',
      pageName: '매칭 제외 카드 관리',
      title: '매칭 제외 카드 관리',
      description: '제외하고 싶은 카드를 선택하면 매칭되지 않아요! 보유한 카드는 자동으로 제외됩니다.'
    },
    agreements: {
      baseAgreementBottomSheetTitle: '서비스 약관 변경에 따라 약관 내용을 확인하시고 동의 부탁드립니다.',
      baseAgreementBottomSheetDescription: '동의를 거부할 권리가 있으나, 동의 거부 시 서비스 이용에 제한이 있을 수 있습니다.',
      baseAgreementBottomSheetButton: '시작하기',
      baseAgreementAbove14: '서비스 약관 변경에 따라 약관 내용을 확인하시고 동의 부탁드립니다.',
      baseAgreementBottomAgreeAllButton: '모두 동의',
      baseAgreementRequiredCheckBox: '(필수)',
      baseAgreementRequiredAgreement: '포카판 약관 및 동의사항',
      baseAgreementOptionalCheckBox: '(선택)',
      baseAgreementPromotionalAgreement: '마케팅 정보 수신 동의'
    },
    errorBoundary: {
      title: '오류가 발생했습니다.',
      description: '홈으로 이동하여 다시 이용해주세요.',
      button: '홈으로가기'
    },
    newTradingPhotoCardsHorizontalCard: {
      authorName: '양도판',
      authoredAt: '방금 전',
      newSales: '새로 올라온 판매중인 포카 🛍️' ,
      newExchanges: '새로 올라온 교환 대기중인 포카 ↔️'
    },
    myUserProfileList: {
      privateProfile: '프라이빗 프로필',
      createPrivateProfile: '프라이빗 프로필',
      tradingProfile: '양도 프로필',
      addProfile: '프로필 추가',
      changeProfile: '프라이빗 프로필 변경'
    },
    profiles: {
      defaultButton: '시작하기',
      nickNameButton: '{{nickname}} 로 시작하기',
      hasBatchimNickNameButton: '{{nickname}} 으로 시작하기',
      emptyNicknameErrorMessage: '닉네임을 입력해주세요.',
      duplicatedNickName: '닉네임이 이미 사용중입니다.',
      failedToCreateAlert: '등록에 실패하였습니다.',
      mergedNicknameToastMessage: '프로필이 통합되었습니다.',
      title: '프라이빗 프로필 변경',
      inputNickNamePlaceholder: '닉네임을 입력해 주세요',
      mergeProfilePageTitle: '프로필 통합',
      mergeProfileTitle: '겸덕러들!\n프로필 하나만 선택해주세요',
      mergeProfileDescription: '더이상 양도판을 이용할때\n아티스트별로 왔다 갔다 하지 않아도돼요!',
      mergeProfileFirstDescriptionRow: '• 아티스트 탈덕은 ‘마이 > 아티스트관리’에서 가능해요.',
      mergeProfileSecondDescriptionRow: '• 멀티 프로필은 추후에 재정비하여 다시 지원예정이에요.',
      mergeProfileThirdDescriptionRow: '• 기존 대화중인 상대방에게는 변경된 닉네임으로 안내됩니다.',
      chooseMergedProfile: '선택',
      createNewNickname: '새 닉네임 만들기'
    },
    notifications: {
      pageTitle: '알림',
      messagesTab: '메시지',
      notificationsTab: '알림',
      notificationsTitle: '알림 리스트는 준비중',
      notificationsDescription: '메세지/댓글/좋아요 알림은 가고있어요!',
      messageCategoryAll: "전체",
      messageCategorySelling: "판매",
      messageCategoryPurchasing: "구매",
      messageCategoryExchanging: "교환",
    },
    selectArtists: {
      initArtistTitle: '초기화할 아티스트를\n선택해주세요',
      initArtistDescription: '보유 중인 포카만 초기화됩니다!\n거래내역은 그대로 남아요.',
      photoCardsCount: '{{count}}장 보유중'
    },
    tradingUserListPage: {
      pageName: '동일 이벤트 포카',
      title: '어떤 {{fandom}}랑 교환하시겠어요?',
      batchimTitle: '어떤 {{fandom}}이랑 교환하시겠어요?',
      orRow: '또는',
      saleSubTitleRow: '웃돈이라도 주고,, 빨리 사고싶다면..?',
      saleTitleFirstRow: '판매',
      saleTitleSecondRow: '중인 {fandom}}는 있어요!'
    },
    addTradingPhotoCard: {
      editNewTradingTitle: '등록완료😍',
      editNewTradingSubTitle: '혹시 판매전용인가요?',
      editNewTradingDescription: '판매전용이면 가격을 입력해주세요!',
      addButton: '추가',
      collectedBadge: '보유중',
      emptyCollectedCaseTitle: '보유중인 카드가 없습니다.',
      emptyCollectedCaseDescription: '등록하지 않은 카드도 검색해서 양도할 수 있어요!',
      emptyCollectedCaseButton: '카드 등록하러가기'
    },
    addPhotoCard: {
      searchPlaceholder: '🔍 이름, 앨범명 검색',
      description: '한 장만 추가 가능합니다.'
    },
    chatRoom: {
      read: '읽음',
      copyMessageToClipboard: '메시지가 복사되었습니다.',
      imageMenu: '이미지',
      showTraderPhotoCards: '상대방의 양도포카 보기',
      placeHolder: '메시지를 입력하세요',
      disabledPlaceHolder: '대화가 불가능합니다',
      traderPhotoCardsBottomSheetTitle: '{{nickname}} 님의 양도 포카',
      traderPhotoCardsBottomSheetEmptyCase: '양도 중인 포카가 없습니다.'
    },
    myCollectedPhotoCardsStat: {
      authorName: '{{nickname}}의 포카리스트',
      authoredAt: '방금 전',
      myCollectedPhotoCards: '내가 보유한 포카',
      myCollectedPhotoCardsCount: '{{count}}장',
      mostCollectedMember: '가장 많이 모은 멤버',
      hideMenu: '피드에서 숨기기',
      showAllButton: '모두 보기'
    },
    myPage: {
      pageName: '마이',
      title: '마이',
      tradingsRow: '거래 내역',
      wishedRow: '찜한 목록',
      manageArtistsRow: '나의 아티스트 관리',
      manageMembersRow: '나의 최애 관리',
      settings: '설정',
      manageAccount: '계정 관리',
      notice: '공지',
      report: '의견보내기'
    },
    manageArtistPage: {
      pageName: '아티스트 관리',
      likedDays: '{{fandom}} 된 지 {{likedDays}}일째💖',
      dislikeArtist: '탈덕',
      likeArtist: '새로 입덕하기',
      atLeastOneMoreArtistAlert: '하나의 아티스트는 있어야 해요.',
      pleaseCheckInvalidDateAlert: '날짜를 다시 확인해주세요.',
      disLikeArtistDialogTitle: '그동안 {{artist}}를\n사랑해줘서 고마워요!',
      disLikeArtistDialogDescription: '기존 정보는 그대로 보관되니\n휴덕하고 다시와도 좋아요!',
      disLikeArtistDialogConfirm: '그동안 {{artist}}를\n사랑해줘서 고마워요!',
      disLikeArtistDialogCancel: '그동안 {{artist}}를\n사랑해줘서 고마워요!',
      changeStartLikedDateDialogTitle: '{{fandom}} 입덕 날짜를 변경하시겠습니까?',
      changeStartLikedDateDialogConfirm: '변경',
      addLikedArtist: '추가하기'
    },
    manageMemberPage: { pageName: '나의 최애 관리' },
    myWishedPage: {
      pageName: '찜한 목록',
      wishedPhotoCardsTab: '찜한 포카',
      wishedExchangesTab: '찜한 교환',
      wishedSalesTab: '찜한 판매',
      createPhotoCardBoard: '찜한포카로 포카보드 만들기',
      emptyWishedSalesTradingPhotoCardTitle: '아직 찜한 판매 포카가 없네요!',
      emptyWishedSalesTradingPhotoCardDescription: '구매하고 싶은 포카를 여기에 담아보세요'
    },
    manageAccountPage: {
      pageTitle: '계정관리',
      accountEmailTitle: '포카판 계정',
      identityVerificationTitle: '파란뱃지달기',
      identityVerificationDescription: '본인인증 하고 파란뱃지를 가져가세요!',
      identityVerificationDone: '획득 완료',
      identityVerificationIncomplete: '미획득',
      countryTitle: '접속국가',
      countryDescription: '같은 나라의 덕메를 찾는데 도와줘요',
      localeTitle: '서비스 언어',
      localeDescription: '서비스의 언어를 설정해요',
      promotionTitle: '마케팅 관련 알림',
      promotionAgreed: '사용중',
      promotionDisagreed: '미사용',
      initializePhotoCards: '포토카드 초기화',
      deleteAccount: '계정삭제',
      logout: '로그아웃',
      appVersion: '앱버전',
      identityVerificationDialogTitle: '새로운 번호로\n다시 본인인증 하시겠어요?',
      identityVerificationDialogDescription: '{{phoneNumber}}\n{{certifiedAt}} 실명인증 완료',
      identityVerificationDialogYes: '네, 번호가 바뀌었어요',
      identityVerificationDialogNo: '아니요, 괜찮아요'
    },
    emptyMatchedTradingPhotoCard: {
      title: "없어.. 매칭될만한 포카가 없어.. 나오질않아..",
      description: "교환하고싶은 포카를 새로 추가하거나 이 참에 다른 멤버의 카드도 모아보는건 어떠세요..?",
      reMatching: "다시 찾기"
    },
    onGoingMatchingTradingPhotoCard: {
      title: '선택한 포카를 필요로하는 덕메를 찾고있어요!',
      description: '매칭되면 알림을 보내드릴께요'
    },
    changeCountry: {
      globalFeedTitle: "글로벌 피드 🌎",
      globalCountyDescription: "{{country}} 팬을 포함한 글로벌 팬들의 글이 모두 보여요",

      supportedCountyTitle: "{{country}} 피드",
      supportedCountyDescription: "{{country}} 팬들 끼리만 소통할 수 있어요!",

      unSupportedCountryTitle: "{{country}} 피드 (준비중)",
      unSupportedCountryDescription: "{{country}} 팬들이 많이 모이면 오픈합니다.",

      localeDrawerFirstRow: "포카판의 언어를 선택해주세요",
      localeDrawerLastRow: "다른 언어는 계속 추가 됩니다!",

      countryDrawerFirstRow: "접속 국가를 설정하세요.\n접속 국가는 일주일에 한번씩 변경 가능합니다.",

      changingAlertTitle: "{{country}}으로 접속 국가를\n변경하시겠어요?",
      changingAlertDescription: "알주일 뒤에 다시 변경 가능할 수 있어요.",

      notAllowedChangingAlertTitle: "접속 국가를 변경할 수 없어요.",
      notAllowedChangingAlertDescription: "접속 국가는 일주일에 한번씩 변경 가능해요.\n꼭 변경이 필요하면 메일로 문의 주세요.",
    },
    SelectTradingPhotoCardReviewPage: {
      pageTitle: "거래 완료 포카 선택",
      title: "거래 완료한\n포카를 선택해주세요",
      skip: "아직 거래 완료한 포카가 없어요",
    },
    SelectReviewChatRoomPage: {
      title: "거래를 진행한\n상대방을 선택해주세요.",
      dialogText: "포카 양도 완료",
      skip: "선택하지 않을래요",
    },
    ReviewIcon: {
      red: "별로에요",
      yellow: "좋아요",
      green: "최고에요",
    },
    ReviewColorText: {
      red: "빨강",
      yellow: "노랑",
      green: "초록",
    },
    ReviewFeedbackOptions: {
      DetailedDescription: "🧸물품 상태가 설명과 동일해요",
      IncludesBonus: "🎁덤이 들어있었어요",
      Friendly: "🫶친절하고 매너가 좋아요",
      QuickResponse: "⚡️답장 속도가 빨라요",
      WannaTradeAgain: "🙌다시 거래하고 싶은 상대에요",
      DifferentFromDescription: "😵‍💫물품 상태가 설명한 것과 달라요",
      BadPackaging: "🕸️포장 상태가 안좋아요",
      Unfriendly: "🧟‍♀️불친절하고 매너가 안좋아요",
      SlowResponse: "🤐채팅을 읽고도 답이 없어요",
      UnfairCancellation: "🫥거래를 일방적으로 취소해요",
    },
    AddTradingPhotoCardReviewPage: {
      title: "{{nickname}}님과의\n거래는 어떠셨나요?",
      description: "매너 신호를 골라주세요! 상대방에게는 공개되지 않습니다.",
      redReviewTitle: "어떤 점이 별로 였나요?",
      yellowReviewTitle: "어떤 점이 좋았나요?",
      greenReviewTitle: "어떤 점이 최고였나요?",
      blacklistReviewRating: "이 상대방을 다시 안만나고 싶어요.",
      reviewContentTitle: "추가로 남기고 싶은 말이 있나요?",
      reviewContentDescription: "후기를 남기면 본인의 거래 신호등도 좋아져요!",
      reviewContentPlaceHolder: "상대방에게 전하고 싶은 말을 입력하세요.",
      done: "후기 보내기 완료",
      doneDialogTitle: "후기를 작성해주셔서 고마워요.",
      doneDialogDescription: "당신 덕분에 포카판이 더 평화로워질거에요!",
      notConfirmedTradingButton: "이 사람과 거래를 한 적이 없어요",
    },
    TradingPhotoCardReviewDetailPage: {
      sentTitle: "상대방에게 후기를 보냈어요",
      receivedTitle: "상대방으로 부터\n후기가 도착했어요!",
      confirm: "나도 상대방에게 후기 남기기",
      notConfirmedTradingButton: "이 사람과 거래를 한적이 없어요",
      rejctDialogTitle: "{{nickname}}님과 거래를 한 적이 없으신가요?"
    },
    TradingPhotoCardReviewsPage: {
      pageTitle: "거래 후기",
      feedbackOptionsTitle: "이런점이 괜찮대요",
      reviewsTitle: "사람들이 남긴 후기들",
      emptyReviews: "아직 사람들이 남긴 후기들이 없어요.",
    },
    TradingUserProfilePage: {
      pageTitle: "양도 프로필",
      reviewsCount: "{{count}}개",
      reviewColorText: '<color>{{color}}</color>에 근접',
      mannerSignal: "매너 신호",
      saleTab: "판매중인 상품",
      exchangeTab: "교환중인 상품",
      saleEmptyCards: "판매중인 포카가 없어요",
      exchangeEmptyCards: "교환중인 포카가 없어요",
      statTradingReviews: "거래 후기",
      statLastLogin: "최근 활동",
      statResponseRate: "응답률",
    },
    TradingUserProfileSettingsPage: {
      changeProfileImage: "양도 프로필 사진 변경",
      pageTitle: "양도 프로필 설정",
      add: "추가하기",
      nickname: "닉네임",
      changeNicknameDialogTitle: "닉네임 변경",
      placeHolderNickname: "닉네임을 입력해주세요.",
      description: "상태메세지",
      changeDescriptionDialogTitle: "상태메시지 변경",
      placeHolderDescription: "상태메시지를 입력해주세요.",
      manageTradingCards: "양도 카드 관리",
      manageTradingHistories: "양도 내역 보기",
      manageTradingReviews: "내 후기 관리",
    },
    TradingPhotoCardFilterBottomDrawer: {
      title: "필터",
      identityVerifiedTitle: "본인 인증",
      identityVerifiedRadio: "본인 인증",
      categoryTitle: "포토카드유형",
      eventTitle: "포토카드가 포함된 활동",
    },
    PhotoCardFilterBottomDrawer: {
      title: "필터",
      categoryTitle: "포토카드유형",
      eventTitle: "포토카드가 포함된 활동",
      initButton: "초기화",
      defaultCategory: "일반 포카",
      specialCategory: "특전 포카",
      mdCategory: "MD 포카",
      tradingCategory: "트레카",
      emptyCategory: "선택한 유형에 해당하는 포토카드가 없어요",
    },
    ExchangesTabPanel: {
      matchingSubTab: "매칭목록",
      chattingSubTab: "채팅목록",
      availablePoca: "교환 가능한 포카",
      chatRoomsBoxText:	"이 포카로 거래중인 대화방이 {{count}}개 있어요",
      filter: "필터",
    },
    SalesTabPanel: {
      purchaseSubTab: "포카 구매하기",
      saleSubTab: "나의 판매 포카",
      filter: "필터",
    },
    ConfirmNewSalesTradingPhotoCardsPage: {
      title: "판매 가격을 입력해주세요",
      description: `미입력시 "제시"로 표기됩니다.`,
      removeAllDialog: "모두 삭제되었습니다. 다시 선택해 주세요."
    },
    DoneTradingPhotoCardsPage: {
      exchangeTitle: "교환 포카로 등록 완료!",
      saleTitle: "판매 포카로 등록 완료!",
      description: "상대방의 메세지를 놓치지 않도록\n알림 기능을 꼭 켜주세요!",
    },
    TradingPhotoCardPageV2: {
      chatRoomCountCard: "대화 {{count}}",
      exploreCard:	"둘러보기",
      addCard:	"추가하기",
      sellPhotoCardButton:	"내 포카 팔기",
      exchangePhotoCardButton:	"내 포카 교환하기",
    },
    EventCategory: {
      album: { title: "💿 음반 활동", description: "음반 발매시 얻을 수 있는 포카" },
      offline: { title: "🎤 공식 오프라인 활동", description: "콘서트/팬미팅 포카" },
      ADs: { title: "💄 커머셜 활동", description: "최애가 광고 활동으로 얻은 포카" },
      misc: { title: "🪪 팬클럽&응원봉&시그", description: "팬클럽, 응원봉, 시그 구매로 얻은 포카" },
      content: { title: "📺 컨텐츠", description: "자컨/화보 등 컨텐츠 관련 포카" },
    },
    ReportPhotoCardActivitiesPage: {
      pageName: "누락 포카 추가",
      title: "어떤 활동에 나온 포카인가요?",
      unknownActivity: "잘 모르겠어요"
    },
    ReportPhotoCardCandidatesPage: {
      pageName: "누락 포카 추가",
      title: "혹시 아래 포카중에\n동일한 포카가 있나요?",
      emptyTag: "태그 없음",
      addManullay: "직접추가하기"
    },
    PhotoCardCategory: {
      "album": {
          "default": {
              "name": "💿 앨범포카",
              "description": "음반안에 기본 포함되어 있는 포카"
          },
          "special": {
              "name": "🍀 특전포카",
              "description": "판매처 혹은 특정 장소에서 얻은 포카"
          },
          "md": {
              "name": "🧸 MD포카",
              "description": "굿즈를 살때 주는 포카"
          }
      },
      "offline": {
          "default": {
              "name": "💌 기본포카",
              "description": "공식 오프라인 참석시 모두 주는 포카"
          },
          "special": {
              "name": "🍀 특전포카",
              "description": "판매처 혹은 특정 장소에서 얻은 포카"
          },
          "md": {
              "name": "🧸 MD포카",
              "description": "굿즈를 살때 주는 포카"
          },
          "trading": {
              "name": "🤝 트레카",
              "description": "트레이딩포카"
          }
      },
      "ADs": {
          "default": {
              "name": "💌 기본포카",
              "description": "기본으로 포함되어 있는 포카"
          },
          "special": {
              "name": "🍀 특전포카",
              "description": "판매처 혹은 특정 장소에서 얻은 포카"
          },
          "md": {
              "name": "🧸 MD포카",
              "description": "굿즈를 살때 주는 포카"
          }
      },
      "content": {
          "default": {
              "name": "💌 기본포카",
              "description": "기본으로 포함되어 있는 포카"
          },
          "special": {
              "name": "🍀 특전포카",
              "description": "판매처 혹은 특정 장소에서 얻은 포카"
          },
          "md": {
              "name": "🧸 MD포카",
              "description": "굿즈를 살때 주는 포카"
          }
      },
      "misc": {
          "default": {
              "name": "💌 기본포카",
              "description": "기본으로 포함되어 있는 포카"
          },
          "special": {
              "name": "🍀 특전포카",
              "description": "판매처 혹은 특정 장소에서 얻은 포카"
          },
          "md": {
              "name": "🧸 MD포카",
              "description": "굿즈를 살때 주는 포카"
          }
      },
      "etc": {
          "default": {
              "name": "💌 기본포카",
              "description": "기본으로 포함되어 있는 포카"
          },
          "special": {
              "name": "🍀 특전포카",
              "description": "판매처 혹은 특정 장소에서 얻은 포카"
          },
          "md": {
              "name": "🧸 MD포카",
              "description": "굿즈를 살때 주는 포카"
          },
          "trading": {
              "name": "🤝 트레카",
              "description": "트레이딩포카"
          }
      }
    },
    ReportPhotoCardCategoriesPage: {
      pageName: "누락 포카 추가",
      title: "포카의 종류는 무엇인가요?"
    },
    ReportPhotoCardConfirmPage: {
      pageName: "누락 포카 추가",
      title: "포카의 종류는 무엇인가요?",
      confirmedTitle: "새로운 포카로 추가되었어요!",
      confirmedDescription: "{{nickname}}님의 도움으로 포카가 추가되었어요!",
      completedTitle: "제보완료",
      completedDescription: "해당 포카의 퍼스트캡터🧚로 등록됩니다",
      tagTitle: "덕메들이 쉽게 알 수 있도록\n버전명, 구매처, 별명 등을 추가해보세요!",
      tagDescription: "5개가 넘을 경우 많이 사용하는 태그만 노출됩니다.",
      tagInputPlaceholder: "ex) 쥬얼버전 또는 핫도그포카",
      addTag: "추가 +"
    },
    ReportPhotoCardMiscActivitiesPage: {
      membershipName: "팬클럽/멤버십",
      seasonsName: "시즌그리팅",
      lightstickName: "응원봉",
      title: "어떤 활동에 나온 포카인가요?",
      unknownEvent: "잘 모르겠어요",
    },
    ReportPhotoCardEventsPage: {
      albumTitle: "어떤 음반이 였나요?",
      contentTitle: "어떤 컨텐츠였나요?",
      offlineTitle: "어떤 공식 오프라인이였나요?",
      adsTitle: "어떤 광고였나요?",
      defaultTitle: "언제 발매된 포카인가요?",
      debutedDescription: "{{artistName}}의 데뷔년도는 <bold>{{debutedYear}}</bold>년 이에요",
      addManually: "직접 추가하기 +",
      eventDialogTitle: "음반명을 입력해주세요.",
      eventDialogInputPlaceholder: "앨범명, 행사명 등",
      albumDescription: "{{description}} {{volume}}집",
    },
    ReportPhotoCardSelectArtistPage: {
      title: "포카를 제보할\n아티스트를 선택해주세요",
    },
    ReportPhotoCardSelectMemberPage: {
      title: "어떤 멤버의 포카인가요?",
    },
    ReportPhotoCardUploadPhotoPage: {
      title: "갖고있는 포카를\n직접 올려주세요!",
      description: "덕메들이 직접 올린 포카가 많아지면,\n사진으로 포카를 검색할 수 있게 됩니다!",
      disclaimerRowLine1: "탑로더나 스티커는 빼고 찍어주세요",
      disclaimerRowLine2: "빛 번짐이 없는 상태에서 찍어주세요",
      disclaimerRowLine3: "덕메들의 검수를 통해 자동으로 등록됩니다!",
      addPhotoCardButton: "카메라 / 앨범 열기",
    },
    ShowTradingProfileBottomDrawer: {
      title: "포카를 더 빨리 판매하고 싶으신가요?",
      description: "프라이빗 프로필에 양도 프로필을 공개해보세요!",
      openButton: "지금 바로 공개하기",
      skipButton: "다음에 할래요",
    },
    ReportPhotoCardBox: {
      title: "갖고 있는 {{memberName}}의 포카가 안보인다면",
      description: "제보시 칭호🏆️ 를 획득할 수 있어요",
      reportButton: "직접 등록하기"
    },
    UserProfileSearchPage: {
      pageName: "덕메 검색",
      searchPlaceHolder: "닉네임으로 검색이 가능해요",
      mutualBias: "<bold>{{members}}</bold>을 함께 좋아해요",
      mutualBiasMore: "<bold>{{members}}</bold> 외 {{count}}명을 함께 좋아해요",
    },
    LikedUserProfilesPage: {
      pageName: "좋아요",
    },
    FollowersPage: {
      pageName: "팔로워",
    },
    FollowingsPage: {
      pageName: "팔로잉",
    },
    MutualMembersBottomDrawer: {
      title: "함께 좋아하는 아티스트"
    }
  }
}
