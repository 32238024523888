import React, { useEffect, useRef, useState } from "react";

import { Box, Divider, HStack, IconButton, Menu, MenuButton, MenuItem, MenuList, Switch, Textarea } from '@chakra-ui/react';
import { useQueryClient } from "react-query";
import { useLocation, useNavigate, useParams } from "react-router-dom";

import Page from "../../components/Page";

import { isEmpty } from "lodash";
import styled from "styled-components";
import addComment from "../../components/api/mutations/addComment";
import usePostDetailQuery from "../../components/api/queries/usePostDetailQuery";
import useUserQuery from "../../components/api/queries/useUserQuery";
import Post from "../../components/community/Post";
import colors from "../../constants/colors";

import { t } from "i18next";
import { Trans } from "react-i18next";
import { Virtuoso } from "react-virtuoso";
import CommonDialog from "../../components/alerts/CommonDialog";
import deletePost from "../../components/api/mutations/DeletePost";
import usePostCommentsQuery from "../../components/api/queries/usePostCommentsQuery";
import PostPreview from "../../components/community/PostPreview";
import AuthorToggleAvatar from "../../components/thumbnails/AuthorToggleAvatar";
import LightColors from "../../constants/LightColors";
import { ReactComponent as ChatFillIcon } from "../../icons/chat.fill.svg";
import { ReactComponent as MoreIcon } from "../../icons/more.svg";
import { ellipsis } from "../../utils/TextUtil";
import { openNewTab } from "../../utils/UIUtils";

const CommentsWrapper = styled.div`
  padding-bottom: 120px;
`

const DisplayOnFeedWrapper = styled.div`
  padding: 9px 12px;
  border-radius: 6px;
  background: ${LightColors.surface};
  display: flex;
  flex-direction: row;
  margin-top: 12px;
  margin-bottom: 10px;
`

const DisplayOnFeedText = styled.div`
  flex-grow: 1;
  font-size: 13px;
  font-weight: 500;
  line-height: 15.6px;
  text-align: left;
  color: ${colors.textMain};
`

const ParentPostBox = styled.div`
  border-radius: 6px;
  background: rgba(243, 243, 243, 1);
  margin-bottom: 18px;
`

const ParentPostWrapper = styled.div`
  display: flex;
  flex-direction: row;
  padding: 8px 12px 8px 21px;
  align-items: center;
`

const ParentPostText = styled.div`
  padding-left: 10px;
  font-size: 12px;
  font-weight: 400;
  line-height: 14px;
  text-align: left;
  color: ${LightColors.textmainlv4}
`

const SectionWrapper = styled.div`
  padding-top: 4px;
  padding-left: 24px;
  padding-right: 24px;
`

const CommentWrapper = styled.div`
  padding-left: 20px;
  padding-right: 20px;
`

export default function PostDetailPage() {
  const params = useParams();
  const queryClient = useQueryClient();

  const [content, setContent] = useState("");
  const [displayOnFeed, setDisplayOnFeed] = useState(false);
  const [isAnonymous, setIsAnonymous] = useState(false);
  const [paginatedComments, setPaginatedComments] = useState();
  const [isOpenAlert, setIsOpenAlert] = useState(false);

  const commentInputRef = useRef();

  const location = useLocation();
  const currentQueryParams = new URLSearchParams(location.search);
  const isAddComment = currentQueryParams.get("isAddComment");

  useEffect(() => {
    if (isAddComment && commentInputRef) {
      setTimeout(() => { commentInputRef.current.focus() }, 300);
    }
  }, [isAddComment])


  const user = useUserQuery();
  const navigate = useNavigate();
  const post = usePostDetailQuery(params.post_id);

  const back = () => {
    const isNewTab = currentQueryParams.get("newTabOpen");
    const isPushNotification = currentQueryParams.get("pushNotification");
    if(isPushNotification) {
      navigate("/feed", { replace: true });
    } else if (isNewTab) {
      window.close();
    } else {
      navigate(-1);
    }
  }

  useEffect(() => {
    if (post) {
      setIsOpenAlert(post.isDeleted);
      if (post.anonymous) {
        setIsAnonymous(true)
      }
    }
  }, [post])

  const { data, isFetching, fetchNextPage, hasNextPage } = usePostCommentsQuery({postId: params.post_id});

  const handlePaginatedComments = (paginatedData) => {
    const comments = paginatedData.pages?.flatMap((page) =>
      page.posts.map((comment) => comment)
    )
    setPaginatedComments(comments);
  }

  const loadMore = () => {
    if(!isFetching && hasNextPage) {
      fetchNextPage()
    }
  }

  useEffect(() => {
    if (!isEmpty(data)) {
      handlePaginatedComments(data);
    }
  }, [data]);

  useEffect(() => {
    if(content.length >= 100) {
      setDisplayOnFeed(true)
    }
  }, [content])


  const requestAddComment = async () => {
    if (isEmpty(user.baseUserProfile)) {
      navigate("/feed/create_feed_user_profile");
    } else if(content){
      const response = await addComment(params.post_id, content, displayOnFeed, isAnonymous);
      if(response && response.ok) {
        queryClient.refetchQueries(["PostDetail", params.post_id])
        queryClient.refetchQueries(["Comments", params.post_id])
        setContent("")
      }
    }
  }

  const onRemovePost = async (postId) => {
    const response = await deletePost(postId)
    if (response.ok) {
      back();
    }
  }

  return (
    <>
      <Page
        title={t("postDetailPage.pageTitle", {title: params.post_id})}
        onHistoryBackClick={() => back()}
        noPadding={true}
        noGutter={true}
        navigationElement={
          post && post.isMine &&
          <Menu>
            <MenuButton
              height={"24px"}
              as={IconButton}
              variant=""
              aria-label='Options'
              minWidth={"0px"}
              paddingX={"0px!important"}
              icon={<MoreIcon fill={LightColors.textmainlv1} width={"24px"}height={"24px"}/>}
            />
            <MenuList>
              <MenuItem onClick={() => onRemovePost(post.postId)}>
                {t("postDetailPage.deleteMenu")}
              </MenuItem>
            </MenuList>
          </Menu>
        }
        style={{paddingTop: "8px"}}
      >
        <SectionWrapper>
        {
          post?.parent &&
          <ParentPostBox>
            <ParentPostWrapper>
              <ChatFillIcon width={"14px"} height={"14px"} fill={LightColors.textmainlv4}/>
              {
                post.parent.isDeleted ?
                <ParentPostText>{t("postDetailPage.deletedDescription")}</ParentPostText> :
                <ParentPostText onClick={() => openNewTab(`/posts/${post.parent.postId}`)}>
                  <Trans
                    i18nKey="postDetailPage.sourcePostDescription"
                    components={{ bold: <strong /> }}
                    values={{post: ellipsis(isEmpty(post.parent.title) ? post.parent.content : post.parent.title, 15)}}
                  />

                </ParentPostText>
              }
            </ParentPostWrapper>
          </ParentPostBox>
        }
        { post && <Post post={post}/> }
        </SectionWrapper>

        <CommentsWrapper>
          <Virtuoso
            useWindowScroll
            overscan={480}
            data={paginatedComments}
            endReached={loadMore}
            style={{ height: "100px" }}
            itemContent={(index, comment) => (
              <div key={comment.postId} style={{padding: "16px 24px", borderBottom: `1px solid ${LightColors.separatorlv1}`}}>
                <PostPreview user={user} post={comment}/>
              </div>
            )}
          />
        </CommentsWrapper>
        <Box
          maxW={"xl"}
          className="bottomNavbar safeAreaPaddingBottom"
        >
          <Divider color={LightColors.separatorlv2}/>
          <CommentWrapper>
            {
              !isEmpty(content) &&
              // <SlideFade initialScale={0.5} in={!isEmpty(content)}>
                <DisplayOnFeedWrapper>
                  <DisplayOnFeedText>{t("postDetailPage.postOnFeed")}</DisplayOnFeedText>
                  <Switch size='sm' paddingLeft={"4px"} colorScheme="primary" isChecked={displayOnFeed} onChange={(e) => setDisplayOnFeed(e.target.checked)}/>
                </DisplayOnFeedWrapper>
              // </SlideFade>
            }
            <HStack spacing={"10px"} height={"54px"} marginY={"10px"} className="safeAreaMarginBottom">
              <div onClick={() => setIsAnonymous(!isAnonymous)}>
                <AuthorToggleAvatar
                  isAnonymous={isAnonymous}
                  profileImageUrl={user?.baseUserProfile?.pictureUrl}
                  widthAndHeight={36}
                  svgWidthAndHeight={24}
                />
              </div>
              <Textarea
                ref={commentInputRef}
                rows={1}
                type="text"
                variant={"unstyled"}
                padding={0}
                alignContent={"center"}
                placeContent={"center"}
                autoFocus={true}
                value={content}
                color={LightColors.textmainlv2}
                fontSize={"15px"}
                lineHeight={"18px"}
                height={"100%"}
                placeholder={t("postDetailPage.commentPlaceHolder")}
                _placeholder={{
                  color: LightColors.placeholder
                }}
                onChange={(e)=> setContent(e.target.value)} />
                <Box
                  className="text-center"
                  textColor={isEmpty(content) ? LightColors.purewhite : LightColors.primary}
                  onClick={requestAddComment}
                  marginLeft={"12px"}
                  fontSize={"15px"}
                  lineHeight={"18px"}
                  minWidth={"28px"}
                  fontWeight={600}
                >
                  {t("send")}
                </Box>
            </HStack>
          </CommentWrapper>
        </Box>
        {
          isOpenAlert &&
          <CommonDialog
            isOpen={isOpenAlert}
            description={t("postDetailPage.alertDeletedPost")}
            isOneButton={true}
            onClickDialogButton={() => back()}
          />
        }
      </Page>
    </>
  )
}
