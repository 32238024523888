import { useQuery } from "react-query";
import { queryGet } from "../../../utils/queryClient";

export default function useSearchableEventsQuery({artistId, memberId, category}) {
  const queryParams = new URLSearchParams();

  if(artistId) {
    queryParams.append("artist_id", artistId)
  }

  if(memberId) {
    queryParams.append("member_id", memberId)
  }

  if(category) {
    queryParams.append("category", category)
  }

  const { data: events } = useQuery(
    ["SearchableEvent", artistId, memberId, category],
    () => queryGet(`/api/v1/events/searchable?${queryParams.toString()}`),
    {
      enabled: !!artistId,
      select: (data) => data.events,
    },
  );
  return events
}
