import { useInfiniteQuery } from "react-query";
import { queryGet } from "../../../utils/queryClient";
import { GLOBAL_COUNTRY, IsSupportedCountry } from "../../../constants/Countries";
import { isEmpty, isUndefined } from "lodash";

const DEFAULT_PAGE = 15;

export default function usePostsQuery({country = GLOBAL_COUNTRY.code, categoryGroup, artistId, userProfileId, photoCardId, anonymous, perPage, onlyFollowers}) {
  async function fetchPosts({ pageParam = 1 }) {
    const queryParams = new URLSearchParams({
      per_page: perPage || DEFAULT_PAGE,
      page: pageParam,
    });

    if (categoryGroup) {
      queryParams.append("category_group", categoryGroup)
    }

    if (IsSupportedCountry(country) && !isEmpty(country)) {
      queryParams.append("country", country)
    }

    if (artistId) {
      queryParams.append("artist_id", artistId)
    }

    if (userProfileId) {
      queryParams.append("user_profile_id", userProfileId)
    }

    if (photoCardId) {
      queryParams.append("photo_card_id", photoCardId)
    }

    if (anonymous) {
      queryParams.append("anonymous", true);
    }

    if (onlyFollowers) {
      queryParams.append("only_followers", true);
    }

    const url = `/api/v1/posts?${queryParams.toString()}`;
    try {
      const posts = await queryGet(url);
      return posts;
    } catch (error) {
      throw new Error("Error fetching paginated data to get usePostsQuery");
    }
  };

  const {
    data,
    isFetching,
    fetchNextPage,
    hasNextPage,
    refetch,
  } = useInfiniteQuery(
    ["Posts", country, artistId, userProfileId, photoCardId, anonymous, onlyFollowers, categoryGroup],
    // ["Posts", artistId, userProfileId, photoCardId, anonymous, onlyArtist],
    fetchPosts,
    {
      enabled: !isUndefined(country),
      getNextPageParam: (lastPage, _) => lastPage?.meta?.pagination?.nextPage,
    }
  );

  return {
    data,
    isFetching,
    fetchNextPage,
    hasNextPage,
    refetch
  };
}
