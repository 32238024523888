import React from 'react';

const InfiniteScroll = ({ items, loadMore, isFetching, hasNextPage, renderItem }) => {
  return (
    <div>
      {items.map((item) => (
        renderItem ? renderItem(item) : <div>{item}</div>
      ))}
      {hasNextPage && (
        <div
          ref={(el) => {
            if (!el) return;
            const observer = new IntersectionObserver(
              ([entry]) => {
                if (entry.isIntersecting && !isFetching) {
                  loadMore();
                }
              },
              { threshold: 1.0 }
            );
            observer.observe(el);
            return () => observer.disconnect();
          }}
          style={{ height: "10px" }}
        />
      )}
    </div>
  );
}

export default InfiniteScroll; 
