import React, { useMemo, useState } from "react";

import { Box, useRadio, useRadioGroup } from "@chakra-ui/react";
import { t } from "i18next";
import { findIndex, first, isEmpty, isUndefined } from "lodash";
import { useQuery } from "react-query";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import styled from "styled-components";
import { useFilePicker } from "use-file-picker";

import CommonVerticalButtonDialog from "../../components/alerts/CommonVerticalButtonDialog";
import addTradingPhotoCardReview from "../../components/api/mutations/addTradingPhotoCardReview";
import useUserProfileQuery from "../../components/api/queries/useUserProfileQuery";
import useUserQuery from "../../components/api/queries/useUserQuery";
import PrimaryButton from "../../components/buttons/PrimaryButton";
import ProfileLineButton from "../../components/buttons/ProfileLineButton";
import PostImagesBox from "../../components/community/PostImagesBox";
import ReviewIcon from "../../components/icons/ReviewIcon";
import InputArea from "../../components/input/InputArea";
import Page from "../../components/Page";
import PriamryRadio from "../../components/radio/PriamryRadio";
import LightColors from "../../constants/LightColors";
import { ReactComponent as AlbumIcon } from "../../icons/album.fill.svg";
import { ReactComponent as ArrowRightSmall } from "../../icons/arrow.right.small.svg";
import { queryGet } from "../../utils/queryClient";
import { useHandleHistoryBack } from "../../utils/useHandleHistoryBack";

const Title = styled.div`
  color: ${LightColors.textmainlv1};
  font-size: 26px;
  font-weight: 700;
  line-height: 31px;
  text-align: left;
  white-space: pre-wrap;
  padding: 40px 24px 10px 24px;
`

const RatingWrapper = styled.div`
  margin-top: 25px;
  opacity: 0px;
  display: grid;
  grid-template-columns: repeat(3, minmax(0, 1fr));
  align-items: center;
  justify-items: center;
  padding: 0px 24px;
`

const Description = styled.div`
  color: ${LightColors.textmainlv4};
  font-size: 13px;
  font-weight: 400;
  line-height: 16px;
  white-space: pre-wrap;
`

const Row = styled.div`
  display: flex;
  flex-direction: row;
`

const SubTitle = styled.div`
  color: ${LightColors.textmainlv1};
  font-size: 18px;
  font-weight: 700;
  line-height: 22px;
  text-align: left;
  white-space: pre-wrap;
`

const RadioWrapper = styled.div`
  padding: 16px 0px;
  border-bottom: 1px solid ${LightColors.separatorlv1};
`

const ReviewWrapper = styled.div`
  margin-top: 40px;
  padding: 0px 24px;
`;

const BottomSkipWrapper = styled.div`
  position: fixed;
  left: 50%;
  bottom: 20px;
  transform: translate(-50%, -50%);
  margin: 0 auto;
  font-weight: 700;
  font-size: 18px;
  line-height: 22px;
`

const SkipText = styled.div`
  color: ${LightColors.textmainlv3};
  font-size: 13px;
  font-weight: 400;
  line-height: 16px;
  text-align: left;
  white-space: pre-wrap;
`

const PositiveFeedBackOptions = [
  "DetailedDescription",
  "IncludesBonus",
  "Friendly",
  "QuickResponse",
  "WannaTradeAgain",
];

const NegativeFeedBackOptions = [
  "DifferentFromDescription",
  "BadPackaging",
  "Unfriendly",
  "SlowResponse",
  "UnfairCancellation",
];

const RadioRow = ({isChecked, text, onChanged, props}) => {
  const { getInputProps } = useRadio(props);
  const input = getInputProps();

  return (
    <Row as="label">
      <input {...input} />
      <PriamryRadio text={text} isChecked={isChecked} onChanged={onChanged}/>
    </Row>
  );
};


export default function AddTradingPhotoCardReviewPage() {
  const params = useParams();
  const location = useLocation();
  const navigate = useNavigate();
  const handleHistoryBack = useHandleHistoryBack();

  const currentQueryParams = new URLSearchParams(location.search);
  const traderUserProfileId = currentQueryParams.get("traderUserProfileId");
  const reviewerId = currentQueryParams.get("reviewerId");
  const chatRoomId = currentQueryParams.get("chatRoomId");
  const isAddSkip = currentQueryParams.get("isAddSkip");

  const [reviewRating, setReviewRating] = useState();
  const [blacklisted, setBlacklisted] = useState();
  const [feedbackOptions, setFeedbackOptions] = useState([]);
  const [reviewContent, setReviewContent] = useState("");
  const [isShowDoneDialog, setIsShowDoneDialog] = useState(false);
  const [isShowErrorDialog, setIsShowErrorDialog] = useState(false);

  const { openFilePicker, filesContent, clear } = useFilePicker({
    readAs: 'DataURL',
    accept: 'image/*',
    multiple: false,
  });

  const user = useUserQuery();
  const reviwer = useUserProfileQuery(reviewerId);
  const { data: chatRoom } = useQuery(
    ["chatRoom", params.chat_room_id],
    () => queryGet(`/chat_rooms/${chatRoomId}`),
    {
      enabled: !!chatRoomId,
      select: (r) => r.chatRoom,
      cacheTime: 0,
    },
  );

  const tradingUserProfile = useMemo(() => {
    const index = findIndex(chatRoom?.userProfiles, (p) => p.userId !== chatRoom?.currentUserId);
    return chatRoom?.userProfiles[index];
  }, [chatRoom]);

  const SelectReviewRating = (rating, initFeedbackOption=false) => {
    setReviewRating(rating);
    if(initFeedbackOption) {
      setBlacklisted(false);
      setFeedbackOptions([]);
      setReviewContent("");
    }
  }

  const pushFeedBackOption = (option) => {
    if (feedbackOptions.includes(option)) {
      setFeedbackOptions(feedbackOptions.filter(item => item !== option));
    } else {
      setFeedbackOptions([...feedbackOptions, option]);
    }
  }

  const FeedbackOptionTitle = () => {
    if(reviewRating === "red") {
      return t("AddTradingPhotoCardReviewPage.redReviewTitle");
    } else if (reviewRating === "yellow") {
      return t("AddTradingPhotoCardReviewPage.yellowReviewTitle");
    } else if (reviewRating === "green") {
      return t("AddTradingPhotoCardReviewPage.greenReviewTitle");
    }
  }

  const onFinishReview = async () => {
    const response = await addTradingPhotoCardReview({
      tradingPhotoCardId: params.trading_photo_card_id,
      reviewerId: user.tradingUserProfile.userProfileId,
      traderUserProfileId: reviewerId || traderUserProfileId,
      rating: reviewRating,
      blacklisted: blacklisted,
      feedbackOptions: feedbackOptions,
      content: reviewContent,
      dataUrl: first(filesContent)?.content,
    })
    if (response.ok) {
      setIsShowDoneDialog(true);
    } else {
      const responseBody = await response.json();
      if (
          responseBody.error &&
          responseBody.error.code === "1002" &&
          responseBody.error.message.includes("You can only leave one review per item")
        ) {
          // TODO : Show Error Dialog for already added Review
          // setIsShowErrorDialog(true);
      }
    }
  }

  const onClosedDialog = () => {
    setIsShowDoneDialog(false);
    navigate("/home");
  }

  const onDeletedImage = () => {

  }

  const { getRootProps, getRadioProps } = useRadioGroup({ name: "feedback", defaultValue: "" });
  const RenderFeedbackOptions = () => {
    const group = getRootProps();
    return (
      <Box {...group} style={{paddingTop: "10px"}}>
        {(reviewRating === "red" ? NegativeFeedBackOptions : PositiveFeedBackOptions).map((option, index) => {
          const radio = getRadioProps({ value: index });
          return (
            <Row key={option}>
              <RadioWrapper>
                <RadioRow
                  isChecked={feedbackOptions.includes(option)}
                  text={t(`ReviewFeedbackOptions.${option}`)}
                  onChanged={() => pushFeedBackOption(option)}
                  props={{...radio}}
                />
              </RadioWrapper>
            </Row>
          );
        })}
      </Box>
    );
  };

  return (
  <div>
    <Page
      title={t("tradingPhotoCardPage.pageTitle")}
      showBackButton={true}
      userProfile={user}
      noPadding={true}
    >
      <Title>
        {t("AddTradingPhotoCardReviewPage.title", { nickname: tradingUserProfile?.nickname || reviwer?.nickname || "-" })}
      </Title>
      <Row style={{padding: "4px 24px 10px 24px"}}>
        <Description>
          {t("AddTradingPhotoCardReviewPage.description")}
        </Description>
      </Row>
      <RatingWrapper>
        <ReviewIcon
          ratingText={"red"}
          isSelected={reviewRating === "red"}
          size={106}
          onClick={() => SelectReviewRating("red", true)}
        />
        <ReviewIcon
          ratingText={"yellow"}
          isSelected={reviewRating === "yellow"}
          size={106}
          onClick={() => SelectReviewRating("yellow", true)}
        />
        <ReviewIcon
          ratingText={"green"}
          isSelected={reviewRating === "green"}
          size={106}
          onClick={() => SelectReviewRating("green", true)}
        />
      </RatingWrapper>
      {
        isUndefined(reviewRating) ? <></> :
        (
          (reviewRating === "red") &&
          <Row style={{padding: "0px 24px", marginTop: "10px"}}>
            <RadioWrapper>
              <RadioRow
                isChecked={blacklisted}
                text={t("AddTradingPhotoCardReviewPage.blacklistReviewRating")}
                onChanged={() => setBlacklisted(!blacklisted)}
              />
            </RadioWrapper>
          </Row>
        )
      }
      {
        !isUndefined(reviewRating) &&
        <>
          <ReviewWrapper>
            <SubTitle>{FeedbackOptionTitle()}</SubTitle>

            {RenderFeedbackOptions()}

            <SubTitle style={{ marginTop: "32px" }}>
              {t("AddTradingPhotoCardReviewPage.reviewContentTitle")}
            </SubTitle>
            <Description style={{ paddingTop: "6px" }}>
              {t("AddTradingPhotoCardReviewPage.reviewContentDescription")}
            </Description>
            <div style={{ padding: "24px 0px" }}>
              <InputArea
                value={reviewContent}
                onChange={(e) => setReviewContent(e.target.value)}
                placeholder={t("AddTradingPhotoCardReviewPage.reviewContentPlaceHolder")}
                size="sm"
                resize="vertical"
              />
            </div>
          </ReviewWrapper>
          <Row style={{padding: "0px 24px 24px 24px"}}>
          {
            isEmpty(filesContent) ?
            <ProfileLineButton
              paddingX={"12px"}
              paddingY={"7px"}
              fontSize={"13px"}
              lineHeight={"16px"}
              text={t("picture")}
              leftIcon={<AlbumIcon width={"14px"} height={"14px"} fill={LightColors.textmainlv2}/>}
              onClick={openFilePicker}
            /> :
            <PostImagesBox
              images={[first(filesContent)?.content]}
              enableDelete={true}
              enableFullSizeViewer={false}
              onDeletedImage={clear}
            />
          }
          </Row>
        </>
      }

      {
        reviewRating &&
        <div style={{ padding: "0px 24px 24px 24px"}}>
          <PrimaryButton
            fontSize={"17px"}
            paddingY={"16px"}
            lineHeight={"20px"}
            text={t("AddTradingPhotoCardReviewPage.done")}
            onClick={() => onFinishReview()}
          />
        </div>
      }
      {
        isAddSkip && !reviewRating &&
        <BottomSkipWrapper onClick={handleHistoryBack}>
          <Row style={{ alignItems: "center" }}>
            <SkipText>
              {t("AddTradingPhotoCardReviewPage.notConfirmedTradingButton")}
            </SkipText>
            <ArrowRightSmall fill={LightColors.textmainlv3} width={"20px"} height={"20px"}/>
          </Row>
        </BottomSkipWrapper>
      }
    </Page>
    {
      isShowDoneDialog &&
      <CommonVerticalButtonDialog
        isOpen={isShowDoneDialog}
        onClose={() => onClosedDialog()}
        title={t("AddTradingPhotoCardReviewPage.doneDialogTitle")}
        description={t("AddTradingPhotoCardReviewPage.doneDialogDescription")}
        onClickDialogUpButton={() => onClosedDialog(false)}
        upButtonText={t("confirm")}
        isOneButton={true}
      />
    }
    {
      isShowErrorDialog &&
      <CommonVerticalButtonDialog
        isOpen={isShowErrorDialog}
        onClose={() => setIsShowErrorDialog(false)}
        title={"You already leave a review for this item."}
        onClickDialogUpButton={() => onClosedDialog(false)}
        upButtonText={t("confirm")}
        isOneButton={true}
      />
    }
  </div>
  )
}
