import React from "react";
import { Box, Center } from '@chakra-ui/react';

export default function SecondaryButton(
  {
    text,
    icon,
    disabled,
    ...props
  }
) {
  return (
    <Box
      className="text-center"
      textColor={disabled ? "#B9B9B9" : "primary.500"}
      backgroundColor={disabled ? "#E7E7E7" : "secondary.600"}
      borderRadius={"52px"}
      lineHeight={"17px"}
      paddingX={"12px"}
      paddingY={"8px"}
      fontWeight={500}
      fontSize={"14px"}
      alignContent={"center"}
      {...props}
    >
      <Center>
        <p>{text}</p>
        {
          icon && <>{icon}</>
        }
      </Center>
    </Box>
  )
}
