import baseUrl from "../../../utils/baseUrl";
import getToken from "../../../utils/getToken";

export default async function AddReport({reportableType, reportableId, category, content, dataUrl}) {
  return fetch(baseUrl() + `/api/v1/reports`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + getToken(),
    },
    body: JSON.stringify({
      report: {
        reportable_type: reportableType,
        reportable_id: reportableId,
        category: category,
        content: content,
        data_url: dataUrl,
      },
    }),
  });
}
