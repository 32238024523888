import { useInfiniteQuery } from "react-query";
import { queryGet } from "../../../utils/queryClient";

const DEFAULT_PAGE = 20;

// 사용자 프로필 검색을 위한 커스텀 훅
export default function useSearchUserProfiles(nickname, perPage = DEFAULT_PAGE) {
  // 검색 결과를 가져오는 함수
  const fetchSearchUserProfiles = async ({ pageParam = 1 }) => {
    const queryParams = createQueryParams({ nickname, perPage, pageParam });
    const url = `/api/v1/user_profiles/search?${queryParams.toString()}`;

    try {
      return await queryGet(url);
    } catch (error) {
      throw new Error("사용자 프로필 검색 중 오류가 발생했습니다.");
    }
  };

  // react-query를 사용한 무한 스크롤 쿼리
  return useInfiniteQuery(
    ["SearchUserProfiles", nickname],
    fetchSearchUserProfiles,
    {
      enabled: !!nickname,
      getNextPageParam: (lastPage) => lastPage?.meta?.pagination?.nextPage || undefined,
    }
  );
}

// 쿼리 파라미터 생성 함수
function createQueryParams({ nickname, perPage, pageParam }) {
  return new URLSearchParams({
    nickname,
    per_page: perPage,
    page: pageParam,
  });
}
