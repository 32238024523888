import React, { useEffect, useState } from "react";

import { t } from "i18next";
import { isEmpty } from "lodash";
import { useParams } from "react-router-dom";
import styled from "styled-components";

import { useFollowingsQuery } from "../components/api/queries/FollowUserProfiles";
import useUserQuery from "../components/api/queries/useUserQuery";
import StickyHeader from "../components/StickyHeader";
import { SocialUserProfileList } from "../components/userProfile/SocialUserProfileList";
import LightColors from "../constants/LightColors";
import { ReactComponent as CloseIcon } from "../icons/xmark.svg";
import { useHandleHistoryBack } from "../utils/useHandleHistoryBack";

const PageWrapper = styled.div`
  margin-top: calc(64px + env(safe-area-inset-top));
`

const FollowingsPage = () => {
  const [userProfiles, setUserProfiles] = useState([]);
  const params = useParams();
  const handleHistoryBack = useHandleHistoryBack();

  const user = useUserQuery();
  const { data, fetchNextPage, hasNextPage, isFetching } = useFollowingsQuery(params.user_profile_id)

  useEffect(() => {
    if (!isEmpty(data)) {
      handlePaginatedUserProfiles(data);
    }
  }, [data]);

  const handlePaginatedUserProfiles = (paginatedData) => {
    const profiles = paginatedData.pages?.flatMap((page) =>
      page.followings.map((profile) => profile)
    )
    setUserProfiles(profiles);
  };

  const onFollowed = (userProfileId, isFollowed) => {
    const updatedProfiles = userProfiles.map(profile =>
      profile.userProfileId === userProfileId ? { ...profile, isFollowed: !isFollowed } : profile
    );
    setUserProfiles(updatedProfiles);
  }

  return (
    <>
      <StickyHeader
        title={t("FollowingsPage.pageName")}
        alwaysShowTitle={true}
        showBackButton={false}
        leftContent={
          <CloseIcon width={"20px"} height={"20px"} fill={LightColors.pureblack} onClick={handleHistoryBack}/>
        }
        showTitle={true}
        transparent={true}
      />
      <PageWrapper>
        <SocialUserProfileList
          currentUserProfileId={user?.baseUserProfile?.userProfileId}
          userProfiles={userProfiles || []}
          hasNextPage={hasNextPage}
          fetchNextPage={fetchNextPage}
          isFetching={isFetching}
          onFollowed={onFollowed}
        />
      </PageWrapper>
    </>
  );
};

export default FollowingsPage;
