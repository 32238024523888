import { useCallback, useEffect, useRef, useState } from "react";

import { Center, Spinner } from "@chakra-ui/react";
import { isEmpty, isUndefined } from "lodash";
import styled from "styled-components";

import LightColors from "../../constants/LightColors";
import { ReactComponent as ChatFillIcon } from "../../icons/chat.fill.svg";
import { ReactComponent as MegaphoneFillIcon } from "../../icons/megaphone.fill.svg";
import { ellipsis } from "../../utils/TextUtil";
import EmptyCase from "../errors/EmptyCase";
import GridSkeletons from "../skeletons/GridSkeletons";
import NewTradingPhotoCardsHorizontalCard from "./NewTradingPhotoCardsHorizontalCard";
import PostPreview from "./PostPreview";
import { t } from "i18next";


const ListWrapper = styled.div`
  padding-bottom: 72px;
`;

const RepostWrapper = styled.div`
  display: flex;
  flex-direction: row;
  padding-left: 21px;
  padding-bottom: 5px;
`;

const RepostText = styled.div`
  padding-left: 10px;
  font-size: 12px;
  font-weight: 400;
  line-height: 14px;
  text-align: left;
  color: ${LightColors.textmainlv4};
`;

export default function PostsList({ user, posts, artistId, loadMore, hasMore, followOnly=false }) {
  const [visibility, setVisibility] = useState(false);
  const observer = useRef();


  const lastPostElementRef = useCallback(
    (node) => {
      if (observer.current) observer.current.disconnect();
      observer.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting && hasMore) {
          loadMore();
        }
      });
      if (node) observer.current.observe(node);
    },
    [loadMore, hasMore]
  );

  useEffect(() => {
    setTimeout(() => {
      setVisibility(true);
    }, 300);
  }, []);

  if (isUndefined(posts)) {
    return (
      <GridSkeletons
        gridSize={1}
        skeletonSize={3}
        skeletonHeight={"113px"}
        paddingX={"22px"}
        paddingTop={"16px"}
      />
    );
  }

  if (isEmpty(posts)) {
    if(followOnly) {
      return <EmptyCase description={t("post.emptyFollowerPost")} />;
    }
    return <EmptyCase description={t("post.emptyPost")} />;
  }


  const filteredPosts = posts.filter(
    (post) => !(post.isDeleted || (post.postType === "megaphone" && isEmpty(post.megaphonePost)))
  );

  const PreCalculateMinHeight = (post) => {
    if (!isEmpty(post.pictureUrls)) {
      if (post.pictureUrls.length === 2) {
        return "314px";
      } else if (post.pictureUrls.length >= 3) {
        return "320px";
      } else {
        return "221px";
      }
    } else if (post.quotedPost) {
      return "163px";
    } else if (post.megaphonePost || post.parent) {
      return "134px";
    }
    return "115px";
  };

  return (
    <ListWrapper style={{ visibility: visibility ? "visible" : "hidden" }}>
      {filteredPosts.map((post, index) => {
        const isLastElement = index === filteredPosts.length - 1;
        return (
          <div key={post.postId}>
            {
              index === 5 &&
              <NewTradingPhotoCardsHorizontalCard artistId={artistId} category={"sale"}/>
            }
            {
              // index === 10 &&
              // <NewTradingPhotoCardsHorizontalCard artistId={artistId} category={"exchange"}/>
            }
            <div
              key={post.postId}
              ref={isLastElement ? lastPostElementRef : null}
              style={{
                minHeight: PreCalculateMinHeight(post),
                padding: "20px 22px",
                borderBottom: `1px solid ${LightColors.separatorlv1}`,
              }}
            >
              <div>
                {(post.quotedPost || post.megaphonePost || post.parent) ? (
                  <>
                    {post.megaphonePost && (
                      <>
                        <RepostWrapper>
                          <MegaphoneFillIcon
                            width={"14px"}
                            height={"14px"}
                            fill={LightColors.textmainlv4}
                          />
                          <RepostText>{t("post.megaphonedPost", { author: post.authorName })}</RepostText>
                        </RepostWrapper>
                        <PostPreview user={user} post={post.megaphonePost} />
                      </>
                    )}
                    {post.quotedPost && (
                      <PostPreview user={user} post={post} navigatePath={`/posts/${post.postId}`} />
                    )}
                    {post.parent && (
                      <div>
                        <RepostWrapper>
                          <ChatFillIcon
                            width={"14px"}
                            height={"14px"}
                            fill={LightColors.textmainlv4}
                          />
                          {post.parent.isDeleted ? (
                            <RepostText>{t("post.deletedPost")}</RepostText>
                          ) : (
                            <RepostText>{t("post.commentedPost", { content: isEmpty(post.parent.title) ? ellipsis(post.parent.content, 15) : ellipsis(post.parent.title, 15) })}</RepostText>
                          )}
                        </RepostWrapper>
                        <PostPreview user={user} post={post} navigatePath={`/posts/${post.postId}`} />
                      </div>
                    )}
                  </>
                ) : (
                  <PostPreview user={user} post={post} navigatePath={`/posts/${post.postId}`} />
                )}
              </div>
            </div>
          </div>
        );
      })}
      {hasMore && (
        <Center marginTop={"8px"}>
          <Spinner size="sm" color="primary.500" marginRight={"20px"} />
        </Center>
      )}
    </ListWrapper>
  );
}
