import { Fab } from "react-tiny-fab";

import { useState } from "react";
import LightColors from "../../constants/LightColors";
import { ReactComponent as PlusIcon } from "../../icons/plus.svg";
import RegularSolidButton from "../buttons/RegularSolidButton";

export const PopupFabButton = ({ onClickFabMainButton, onClickFabChildrenButton, buttonTexts }) => {
  const [isOpenFab, setIsOpenFab] = useState(false)

  const handleClick = (e) => {
    setIsOpenFab(!isOpenFab);
    if(onClickFabMainButton) {
      onClickFabMainButton(e)
    }
  }

  const handleClickFabChildrenButton = (index) => {
    if(onClickFabChildrenButton) {
      onClickFabChildrenButton(index);
    }
  }


  return(
    <div onClick={(e) => handleClick(e)}>
      <Fab
        alwaysShowTitle={false}
        style={{ bottom: "calc(env(safe-area-inset-bottom) + 64px)", right: 0 }}
        icon={
          <PlusIcon fill="white" width={"24px"} height={"24px"}/>
        }
        mainButtonStyles={{
          // backgroundColor: isOpenFab ? LightColors.pureblack : LightColors.primary,
          backgroundColor: LightColors.primary,
          boxShadow: "0px 0px 4px 0px rgba(92, 61, 250, 0.40), 0px 2px 10px 0px rgba(92, 61, 250, 0.30)"
        }}
        // onClick={(e) => console.log(e)}
      >
        {
          buttonTexts?.map((buttonText, index) => (
            <div key={index}>
              <RegularSolidButton
                paddingX={"24px"}
                paddingY={"16px"}
                fontSize={"17px"}
                lineHeight={"20px"}
                text={buttonText}
                style={{
                  boxShadow: "0px 0px 4px 0px rgba(92, 61, 250, 0.40), 0px 2px 10px 0px rgba(92, 61, 250, 0.30)"
                }}
                onClicked={() => handleClickFabChildrenButton(index)}
              />
            </div>
            )
          )
        }
      </Fab>
    </div>
  )
}
