import React from 'react';

import { Box, HStack, Image, Text, VStack } from '@chakra-ui/react';
import { t } from 'i18next';
import { isEmpty } from "lodash";
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';

import LightColors from '../../constants/LightColors';
import { ReactComponent as ProfileFillIcon } from "../../icons/profile.fill.svg";
import DisabledBadge from '../badges/DisabledBadge';
import VerifiedIcon from "../icons/VerifiedIcon";
import GridSkeletons from '../skeletons/GridSkeletons';

const ProfileBox = styled.div`
  background: linear-gradient(91.59deg, rgba(255, 255, 255, 0.5) 31.85%, rgba(255, 255, 255, 0.1) 102.92%);
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.04), inset 0px 0px 24px rgba(255, 255, 255, 0.3);
  backdrop-filter: blur(20px);
  border-radius: 18px;
  padding: 18px 16px;
  width: 100%;
  max-width: calc(var(--chakra-sizes-xl));
`

const badgeStyle = {
  width: "fit-content",
  borderRadius: "30px",
  color: LightColors.textmainlv4,
  fontWeight: 600,
  fontSize: "11px",
  lineHeight: "13px",
  background: LightColors.surface
}

const DefaultUserProfileBox = () => {
  return (
    <div style={{ position: 'absolute', top: 0, left: 0, zIndex: 1 }}>
      <svg width="48" height="48">
        <circle cx="24" cy="24" r="22" fill="none" stroke="#B2A3FF" strokeWidth="2" strokeDasharray="5,5" />
      </svg>
    </div>
  )
}

const UserProfileList = ({ user }) => {
  const navigate = useNavigate();

  // const [isShowBaseProfileBottomSheet, setIsShowBaseProfileBottomSheet] = useState(false);
  // const [isShowTradingProfileBottomSheet, setIsShowTradingProfileBottomSheet] = useState(false);

  if (isEmpty(user)) {
  // if (true) {
  // if (false) {
    return (
      <>
        <GridSkeletons gridSize={1} skeletonSize={1} skeletonHeight={"24px"} marginBottom={"12px"}/>
        <GridSkeletons gridSize={1} skeletonSize={1} skeletonHeight={'80px'}/>
      </>
    )
  }

  const navigateAddUserProfile = (category) => {
    if (category === "base") {
      if (isEmpty(user.baseUserProfile)) {
        navigate("/feed/create_feed_user_profile");
      } else {
        navigate(`/feed/user_profile/${user.baseUserProfile.userProfileId}`)
        // setIsShowBaseProfileBottomSheet(true);
      }
    } else {
      navigate(`/trading/user_profile/${user.tradingUserProfile.userProfileId}`)
      // setIsShowTradingProfileBottomSheet(true);
    }
  }

  return (
    <>
    <VStack>
      <VStack
        spacing="11px"
        width= "100%"
        maxWidth={"xl"}
      >
        {
            <ProfileBox
              onClick={() => navigateAddUserProfile("base")}
            >
            <HStack>
              <Box
                style={{
                  position: 'relative'
                }}
              >
                {
                  isEmpty(user.baseUserProfile?.thumbnailUrl) ?
                  <div>
                    <DefaultUserProfileBox/>
                    <Box>
                      <Box width={"48px"} height={"48px"} p={"12px"}>
                        <ProfileFillIcon fill="#B2A3FF"/>
                      </Box>
                    </Box>
                  </div> :
                  <Image
                    borderRadius={"full"}
                    width={"48px"}
                    height={"48px"}
                    objectFit={"cover"}
                    src={user.baseUserProfile.thumbnailUrl}
                  />
                }
              </Box>

              <VStack spacing="5px" alignItems="left">
                <HStack alignItems="left" spacing="2px">
                  {
                    isEmpty(user.baseUserProfile?.nickname) ?
                    <Text fontWeight={500} fontSize="15px" lineHeight="18px" textColor={LightColors.onSecondary}>
                      {t("myUserProfileList.createPrivateProfile")}
                    </Text> :
                    <Text fontWeight={500} fontSize="15px" lineHeight="18px" textColor="#444444">
                      {user.baseUserProfile?.nickname}
                    </Text>
                  }
                </HStack>
                {
                  user.baseUserProfile &&
                  <DisabledBadge text={t("myUserProfileList.privateProfile")} style={badgeStyle}/>
                }
              </VStack>
            </HStack>
          </ProfileBox>
        }

        {
          user?.tradingUserProfile &&
          <ProfileBox
            onClick={() => navigateAddUserProfile("trading")}
          >
            <HStack
            >
              <Box
                style={{
                  position: 'relative'
                }}
              >
                {
                  user.tradingUserProfile.thumbnailUrl ?
                  <Image
                    borderRadius={"full"}
                    width={"48px"}
                    height={"48px"}
                    objectFit={"cover"}
                    src={user.tradingUserProfile.thumbnailUrl}
                  /> :
                  <div>
                    <DefaultUserProfileBox/>
                    <Box>
                      <Box width={"48px"} height={"48px"} p={"12px"}>
                        <ProfileFillIcon fill="#B2A3FF"/>
                      </Box>
                    </Box>
                  </div>
                }
              </Box>

              <VStack spacing="5px" alignItems="left">
                <HStack alignItems="left" spacing="2px">
                  <Text fontWeight={500} fontSize="15px" lineHeight="18px" textColor="#444444">{user.tradingUserProfile.nickname}</Text>
                  {
                    user.identified &&
                    <VerifiedIcon width="14px" height="14px"/>
                  }
                </HStack>
                {
                  user.tradingUserProfile &&
                  <DisabledBadge text={t("myUserProfileList.tradingProfile")} style={badgeStyle}/>
                }
              </VStack>
            </HStack>
          </ProfileBox>
        }
      </VStack>
    </VStack>
    {/* {
      (setIsShowBaseProfileBottomSheet) &&
      <AddUserProfileV1BottomSheet
        title={isEmpty(user.baseUserProfile) ? t("myUserProfileList.addProfile") : t("myUserProfileList.changeProfile")}
        userProfile={user.baseUserProfile}
        isShowProfileBottomSheet={isShowBaseProfileBottomSheet}
        setIsShowProfileBottomSheet={setIsShowBaseProfileBottomSheet}
        category={"base"}
        isMergeProfile={false}
      />
    }

    {
      (user?.tradingUserProfile && isShowTradingProfileBottomSheet) &&
      <AddUserProfileV1BottomSheet
        userProfile={user.tradingUserProfile}
        isShowProfileBottomSheet={isShowTradingProfileBottomSheet}
        setIsShowProfileBottomSheet={setIsShowTradingProfileBottomSheet}
        isMergeProfile={false}
      />
    } */}
    </>
  );
};
export default UserProfileList;
