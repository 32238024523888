import { Divider, VStack } from "@chakra-ui/react";
import { motion } from 'framer-motion';
import { isEmpty } from "lodash";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import StickyHeader from "../../components/StickyHeader";
import RegularSolidButton from "../../components/buttons/RegularSolidButton";
import ChatMessage from "../../components/chats/ChatMessage";
import LightColors from "../../constants/LightColors";
import { ReactComponent as CloseIcon } from "../../icons/xmark.svg";
import styled from "styled-components";

const QUESTIONS = {
  "S-N": [
    { content: "야! 대박! 이번에 티저봤어?", messageType: "text", isMine: false },
  ],
  "J-P": [
    { content: "하 근데 이번 앨범 미공포 완전많던데ㅠㅠ 너 어떻게 할꺼야?", messageType: "text", isMine: false },
  ],
  "F-T": [
    { content: "대박 알겠음ㅎ", messageType: "text", isMine: false },
    { content: "오랜만에 공계들어가봐야징.. 헐 @@이 아파서 쉬는거ㅠ 글썼네ㅠㅠ", messageType: "text", isMine: false },
  ],
  "E-I": [
    { content: "헐 이번에 팬콘도한다고!!! 너 갈꺼야???", messageType: "text", isMine: false },
  ],
  "FINISH": [],
};

const RESPONSES = {
  "S-N": {
    "ㅇㅇ 미쳤더라! 이번에 초동 잘나오겠지??": { nextQuestion: "J-P", responseType: "S" },
    "와 컨셉 미쳤음!!! 먼 떡밥을 들고온거야ㅠㅠ?": { nextQuestion: "J-P", responseType: "N" },
  },
  "J-P": {
    "ㄴㄴ 나 걍 풀셋 모을거야, 다살꺼야!!": { nextQuestion: "F-T", responseType: "P" },
    "포카판 미공포가 젤 예쁘더라.. 거기것만 살랭": { nextQuestion: "F-T", responseType: "J" },
  },
  "F-T": {
    "요샌 괜찮아졌나?ㅠ 다른멤들은 괜찮겠지?ㅠㅠ": { nextQuestion: "E-I", responseType: "F" },
    "걱정ㄴ! 팬콘때 돌아온댔음 아프면 쉬는게 맛따.": { nextQuestion: "E-I", responseType: "T" },
  },
  "E-I": {
    "당연히 가야지,, 용병을 또 어디서 구한담^^..": { nextQuestion: "FINISH", responseType: "E" },
    "아니요, 저는 언제나 방구석에서 온콘을 합니다만?": { nextQuestion: "FINISH", responseType: "I" },
  },
  "FINISH": {
    "다음 단계로 이동하기": { nextQuestion: "NEXT_PAGE", responseType: "navigation" }
  },
};

const Description = styled.div`
  padding-top: 14px;
  padding-bottom: 10px;
  font-size: 14px;
  font-weight: 500;
  line-height: 17px;
  text-align: center;
  color: ${LightColors.textmainlv4};
`

export default function FanMBTIChatBotPage() {
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState("S-N");
  // const [chatHistory, setChatHistory] = useState([]);
  // const [showResponses, setShowResponses] = useState(false);

  // 초기 질문을 대화 기록에 추가
  const initialQuestions = QUESTIONS[currentQuestionIndex];
  const initialChatHistory = [...initialQuestions]; // 첫 번째 질문을 추가

  const chatContainerRef = React.useRef(null); // 스크롤을 위한 ref 추가

  // 상태 초기화
  const [chatHistory, setChatHistory] = useState(initialChatHistory);
  const [showResponses, setShowResponses] = useState(true);
  const [responseTypes, setResponseTypes] = useState([]); // 선택된 responseType을 저장할 상태 추가

  const navigate = useNavigate();

  const exit = () => {
    navigate("/feed")
  }

  // 응답 버튼 클릭 시 다음 질문으로 이동
  const handleButtonClick = (response) => {
    // 다음 질문으로 이동
    const nextQuestionIndex = RESPONSES[currentQuestionIndex][response.content].nextQuestion;
    if (nextQuestionIndex === "NEXT_PAGE") {
      return navigate(`/fan-buddy/personal-keywords?mbti=${sortedResponseTypes}`)
    }
    setShowResponses(false);

    const newMessage = {
      content: response.content,
      messageType: response.messageType,
      isMine: true,
      sentAt: Date.now(),
    };

    // 대화 기록에 현재 응답 추가
    setChatHistory((prev) => {
      const updatedChatHistory = [...prev, newMessage];
      // 스크롤을 아래로 내리기
      if (chatContainerRef.current) {
        setTimeout(() => {
          chatContainerRef.current.scrollIntoView({ behavior: 'smooth' , block: "end" });
        }, 500);
      }
      return updatedChatHistory;
    });

    // 다음 질문을 표시하기 위해 currentQuestionIndex 업데이트
    setCurrentQuestionIndex(nextQuestionIndex);

    // 다음 질문을 대화 기록에 추가
    const nextQuestions = QUESTIONS[nextQuestionIndex];

    if (isEmpty(nextQuestions)) {
      setTimeout(() => {
        setShowResponses(true)
      }, 500);
    } else {
      nextQuestions.forEach((question, index) => {
        setTimeout(() => {
          setChatHistory((prev) => [...prev, question]);
          if (index + 1 === nextQuestions.length) {
            setShowResponses(true);
          }
        }, (index + 1) * 1000); // 첫 index부터 1초 간격으로 질문 추가
      });
    }

    // 현재 응답 값의 타입을 확인할 수 있습니다.
    const responseType = RESPONSES[currentQuestionIndex][response.content].responseType;
    setResponseTypes((prev) => [...prev, responseType]); // 선택된 responseType 저장
  };

  // MBTI 순으로 정렬된 responseTypes를 반환하는 함수
  const sortedResponseTypes = React.useMemo(() => {
    const mbtiOrder = ["E", "I", "S", "N", "T", "F", "J", "P"];
    return responseTypes
      .sort((a, b) => mbtiOrder.indexOf(a) - mbtiOrder.indexOf(b))
      .join(""); // 정렬된 후 JOIN 한 스트링으로 변환
  }, [responseTypes]);

  return (
    <>
    <StickyHeader
      title={"덕메찾기"}
      alwaysShowTitle={true}
      showBackButton={false}
      leftContent={
        <CloseIcon width={"20px"} height={"20px"} fill={LightColors.pureblack} onClick={exit}/>
      }
      showTitle={true}
    />
      <div style={{padding: "calc(64px + env(safe-area-inset-top)) 24px 0px 24px"}}>
        <VStack style={{ height: "100%", display: "flex", flexDirection: "column" }}>
          <div ref={chatContainerRef} style={{ width: "100%", flex: 1, overflowY: "auto", paddingBottom: currentQuestionIndex === "FINISH" ? "240px" : "320px" }}>
            <motion.div>
              {chatHistory.map((msg, index) => (
                <motion.div
                  key={index}
                  initial={{ opacity: 0, y: -10 }} // 위에서 아래로 이동
                  animate={{ opacity: 1, y: 0 }}
                  transition={{ duration: 0.3 }}
                  style={{ marginBottom: "10px" }} // 각 메시지 간의 간격 추가
                >
                  <ChatMessage message={msg} isLastUnSeenMessage={false} />
                </motion.div>
              ))}
            </motion.div>
          </div>
          {showResponses && (
            <VStack spacing={2} style={{ background: "white", position: "fixed", bottom: "calc(4px + env(safe-area-inset-bottom))", width: "100%", padding: "20px 0" }}>
              <Divider />
              {
                currentQuestionIndex !== "FINISH" &&
                <motion.div
                  initial={{ opacity: 0, y: 0 }} // 아래에서 위로 나타남
                  animate={{ opacity: 1, y: 0 }}
                  transition={{ duration: 0.5, delay: 0.5 }} // 딜레이 추가
                >
                  <Description>{"내 대답과 가장 가까운 대답을 눌러주세요"}</Description>
                </motion.div>
              }

              {Object.entries(RESPONSES[currentQuestionIndex]).map(([responseContent], index) => (
                <motion.div
                  key={responseContent}
                  initial={{ opacity: 0, y: 0 }}
                  animate={{ opacity: 1, y: 0 }}
                  transition={{ duration: 0.1, delay: 0.5 }}
                  style={{ width: "100%", display: "flex", justifyContent: "center" }}
                >
                  <RegularSolidButton
                    text={responseContent}
                    onClick={() => handleButtonClick({ content: responseContent, messageType: "text", isMine: true })}
                    style={{ fontSize: "15px", fontWeight: 500, padding: "16px 20px", width: "calc(100% - 48px)", borderRadius: "8px" }}
                  >
                  </RegularSolidButton>
                </motion.div>
              ))}
            </VStack>
          )}
        </VStack>
      </div>
    </>
  );
}
