import { Box, Button, Center, Image, Menu, MenuButton, Spacer, Spinner, Stack, Switch, Text, useToast, Wrap } from "@chakra-ui/react";
import { t } from "i18next";
import { first, isEmpty, isUndefined, throttle, uniq } from "lodash";
import React, { useEffect, useMemo, useState } from "react";
import { useQueryClient } from "react-query";
import { useLocation, useNavigate } from "react-router-dom";
import styled from "styled-components";

import StickyHeader from "../components/StickyHeader";
import CommonDialog from "../components/alerts/CommonDialog";
import EventResponse from "../components/api/model/EventResponse";
import upsertCollectedPhotoCards from "../components/api/mutations/upsertCollectedPhotoCards";
import useArtistQuery, { useArtistQueryLikedOnly } from "../components/api/queries/useArtistQuery";
import useCollectedPhotoCardIdsQuery from "../components/api/queries/useCollectedPhotoCardIdsQuery";
import usePhotoCardEventsQuery from "../components/api/queries/usePhotoCardEventsQuery";
import MyPhotoCardBottomSheet from "../components/bottomSheet/MyPhotoCardBottomSheet";
import SecondaryLineButton from "../components/buttons/SecondaryLineButton";
import PhotoCardFilterBottomDrawer from "../components/drawer/PhotoCardFilterBottomDrawer";
import EmptyCase from "../components/errors/EmptyCase";
import LikedMembersList from "../components/lists/LikedMembersList";
import { ReportPhotoCardBox } from "../components/report/ReportPhotoCardBox";
import ArtistTextSelector from "../components/selectors/ArtistTextSelector";
import EmptyPhotoCardEventSkeletonV2 from "../components/skeletons/EmptyPhotoCardEventSkeletonV2";
import PhotoCardsByEventEditor from "../components/virtualized/PhotoCardsByEvent/PhotoCardsByEventEditor";
import PhotoCardsByEventViewer from "../components/virtualized/PhotoCardsByEvent/PhotoCardsByEventViewer";
import LightColors from "../constants/LightColors";
import { ReactComponent as ChevronDownIcon } from "../icons/chevron.down.svg";
import SelectPhotoCardBottomNav from "../layout/SelectPhotoCardBottomNav";
import ScrollRestoration from "../utils/ScrollRestoration";
import { openNewTab } from "../utils/UIUtils";
import { getArtistId } from "../utils/etcUtils";
import { setEvent } from "../utils/ga";
import saveData from "../utils/saveData";
import { useHandleHistoryBack } from "../utils/useHandleHistoryBack";

const Row = styled.div`
  display: flex;
  flex-direction: row;
`

const Title = styled.div`
  font-size: 17px;
  color: ${LightColors.textmainlv2};
  font-weight: 500;
  line-height: 20px;
  margin-top: 16px;
`

const ArtistRowWrapper = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  margin-bottom: 5px;
`

const LikedMembersListWrapper = styled.div`
  padding-top: 6px;
  padding-bottom: 16px;
  margin-bottom: 96px;
  padding-left: 24px;
  padding-right: 24px;
`
const ReportPhotoCardBoxWrapper = styled.div`
  margin-top: 18px;
`

const FilterButtonText = styled.div`
  color: ${(props) => (props.isChecked ? LightColors.primary : LightColors.textmainlv1)};
  font-size: 14px;
  font-weight: 600;
  line-height: 17px
`

export default function ArtistPhotoCardsPageV2() {
  // #region states & variables
  // 편집모드인지
  const [editable, setEditable] = useState(false);
  const [isOpenExitDialog, setIsOpenExitDialog] = useState(false);

  // 바텀시트 보이는지
  const [isMyPhotoCardsBottomSheetOpen, setIsMyPhotoCardsBottomSheetOpen] = useState(false);
  const [currentMember, setCurrentMember] = useState();

  // Initialize state for paginated data and updated data
  const [meta, setMeta] = useState({});
  const [paginatedEvents, setPaginatedEvents] = useState();
  const [updatedCollectedData, setUpdatedCollectedData] = useState({});
  const [selectedCollectedData, setSelectedCollectedData] = useState({});
  const [isColorModeOnEditable, setIsColorModeOnEditable] = useState(false);
  const [forceRender, setForceRender] = useState(false);
  const [selectedArtist, setSelectedArtist] = useState(null);
  const [isShowFilterBottomDrawer, setIsShowFilterBottomDrawer] = useState(false);
  const [selectedEventIds, setSelectedEventIds] = useState([]);
  const [selectedPhotoCardCategories, setSelectedPhotoCardCategories] = useState([]);

  const artistId = getArtistId();
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const location = useLocation();
  const handleHistoryBack = useHandleHistoryBack();

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const editable = queryParams.get('editable');
    if(editable === "true"){
      setEditable(true);
    }
  }, [location.search]);
  // #endregion

  // #region API
  const artists = useArtistQueryLikedOnly();
  const artist = useArtistQuery(artistId);
  const collectedPhotoCardIds = useCollectedPhotoCardIdsQuery({
    artistId: artistId,
    memberId: currentMember?.memberId,
    eventIds: selectedEventIds,
    photoCardCategories: selectedPhotoCardCategories,
  });
  const { data, isFetching, fetchNextPage, hasNextPage } = usePhotoCardEventsQuery({
    artistId: selectedArtist?.artistId || artistId,
    memberId: currentMember?.memberId,
    eventIds: selectedEventIds,
    photoCardCategories: selectedPhotoCardCategories,
  });
  // #endregion

  ScrollRestoration(window.location.pathname);

  // #region Function to handle updated data set.
  const updatedPhotoCardWithPrevData = (prevData, photoCard) => {
    const memberId = (photoCard.memberId || photoCard.member?.memberId || currentMember?.memberId)
    return {
      ...prevData,
      [memberId]: {
        ...prevData[memberId],
        [photoCard.photoCardId]: {
          ...prevData[memberId]?.[photoCard.photoCardId],
          collectedCount: photoCard.collectedCount,
        },
      },
    };
  }

  const handleUpdatedCollectedPhotoCard = (photoCard) => {
    setUpdatedCollectedData((prevData) => {
      return updatedPhotoCardWithPrevData(prevData, photoCard)
    });
  };

  const handleSelectedCollectedPhotoCard = (photoCard) => {
    setSelectedCollectedData((prevData) => {
      return updatedPhotoCardWithPrevData(prevData, photoCard)
    });
  };

  const collectedCount = useMemo(() => {
    const currentCollectedMemberData = updatedCollectedData[currentMember?.memberId] || {};
    const currentSelectedMemberData = selectedCollectedData[currentMember?.memberId] || {};
    if(isEmpty(currentCollectedMemberData) && isEmpty(currentSelectedMemberData)) {
      return collectedPhotoCardIds?.length || 0;
    }

    const newCollectedPhotoCardIds = Object.entries(currentCollectedMemberData).filter(([_, photoCard]) => {
      return photoCard.collectedCount > 0;
    }).flatMap((entries) => { return entries[0] }) || [];

    const newSelectedPhotoCardIds = Object.entries(currentSelectedMemberData).filter(([_, photoCard]) => {
      return photoCard.collectedCount > 0;
    }).flatMap((entries) => { return entries[0] }) || [];

    const newInsertedIds = uniq(newCollectedPhotoCardIds.concat(collectedPhotoCardIds, newSelectedPhotoCardIds));

    const newUncollectedPhotoCardIds = Object.entries(currentCollectedMemberData).filter(([_, photoCard]) => {
      return photoCard.collectedCount <= 0;
    }).flatMap((entries) => { return entries[0] }) || [];

    const newUnselectedPhotoCardIds = Object.entries(currentSelectedMemberData).filter(([_, photoCard]) => {
      return photoCard.collectedCount <= 0;
    }).flatMap((entries) => { return entries[0] }) || [];

    const newDeletedIds = uniq(newUncollectedPhotoCardIds.concat(newUnselectedPhotoCardIds));

    return newInsertedIds.length - newDeletedIds.length;
  }, [currentMember, collectedPhotoCardIds, updatedCollectedData, selectedCollectedData]);

  // #endregion


  // Function to handle the received paginated data
  const handlePaginatedData = (newPaginatedData) => {
    const updatedEvents = newPaginatedData.flatMap((page) =>
      page.events.map((event) => {
        const newEvent = {
          ...event,
          photoCards: event?.photoCards?.map((photoCard) => {
            const memberId = photoCard.memberId;

            let newCollectedCount = photoCard.collectedCount;
            if(selectedCollectedData[memberId] && selectedCollectedData[memberId][photoCard.photoCardId]) {
              newCollectedCount = selectedCollectedData[memberId][photoCard.photoCardId]?.collectedCount
            }else if(updatedCollectedData[memberId] && updatedCollectedData[memberId][photoCard.photoCardId]) {
              newCollectedCount = updatedCollectedData[memberId][photoCard.photoCardId]?.collectedCount
            }

            return {
              ...photoCard,
              ...{ collectedCount: newCollectedCount },
            };
          }),
        };
        // Exclude null or empty events and photoCards
        if (!isEmpty(newEvent) && !isEmpty(newEvent.photoCards)) {
          return new EventResponse(newEvent);
        }
        return null;
      })
    );
    setPaginatedEvents(updatedEvents.filter((event) => event !== null));
  };

  // Use useEffect to update paginatedData and updatedData on new data arrival
  useEffect(() => {
    if (!isEmpty(data)) {
      handlePaginatedData(data.pages);
      setMeta(first(data.pages)?.meta);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, updatedCollectedData, selectedCollectedData]);

  // #region events
  const loadMore = () => {
    if(hasNextPage){
      fetchNextPage()
    }
  }

  // 멤버 선택 변경
  const onChangedMember = (member) => {
    setCurrentMember(member);
  }

  // 편집 버튼 클릭
  // const onEditButtonClick = () => {
  //   if(editable) {
  //     navigate(
  //       "/photo_cards/report",
  //       { state: { artist: artist, showBackButton: true, navBarVisible: false } }
  //     )
  //   } else {
  //     setEvent({
  //       category: 'PhotoCard',
  //       action: 'Edit Collected PhotoCards',
  //       label: 'PhotoCardsPage',
  //     });
  //     setEditable(true)
  //   }
  // }

  const IgnoreAndExit = () => {
    setSelectedCollectedData({});
    setIsOpenExitDialog(false);
    return setEditable(false);
  }

  // 네비게이션 뒤로가기 눌렀을 경우
  const onHistoryBackClick = () => {
    if(editable){
      const upsertRequest = selectedCollectedData[currentMember?.memberId];
      if(isEmpty(upsertRequest)){
        return IgnoreAndExit();
      }
      return setIsOpenExitDialog(true);
    }
    if(!isEmpty(updatedCollectedData)){
      // API V1 Query keys
      queryClient.invalidateQueries(["photoCardEvents"]);
      queryClient.invalidateQueries(["collectedPhotoCardIds"]);
      // Legacy API Query keys
      queryClient.invalidateQueries(["artistPhotoCards"]);
      queryClient.invalidateQueries(["collectedPhotoCards"]);
      queryClient.invalidateQueries(["userProfile"]);
    }
    handleHistoryBack();
  }

  const onClickPhotoCard = (event, photoCard) => {
    if(editable){
      if(photoCard.collectedCount > 0) {
        handleSelectedCollectedPhotoCard({...photoCard, collectedCount: 0});
      } else {
        handleSelectedCollectedPhotoCard({...photoCard, collectedCount: 1});
      }
    } else {
      openNewTab(`/photo_cards/${photoCard.photoCardId}`);
    }
  }

  const onCompletedEditable = async () => {
    const upsertRequest = selectedCollectedData[currentMember?.memberId];
    if(isEmpty(upsertRequest)){
      return IgnoreAndExit();
    }

    const response = await upsertCollectedPhotoCards(artistId, upsertRequest);
    response.collectedPhotoCards.forEach(photoCard => {
      handleUpdatedCollectedPhotoCard(photoCard);
    });

    setSelectedCollectedData({});
    setEditable(false);
  }

  const onChangeArtist = (artist) => {
    setSelectedArtist(artist)
    saveData("artist", artist.artistId);
    initFilters();
    // queryClient.clear();
    // navigate(`/artists/photo_cards_v2`, { replace: true })
    setForceRender(!forceRender);
  }

  const onColorModeSwitchChanged = () => {
    setIsColorModeOnEditable(!isColorModeOnEditable);
  }

  const initFilters = () => {
    setSelectedEventIds([]);
    setSelectedPhotoCardCategories([]);
  }

  const onChangePhotoCardFilter = () => {
    setIsShowFilterBottomDrawer(!isShowFilterBottomDrawer)
  }

  const onConfirmPhotoCardFilter = (filteredObj) => {
    setIsShowFilterBottomDrawer(false);

    if (!isEmpty(filteredObj)) {
      setSelectedEventIds(filteredObj.eventIds);
      setSelectedPhotoCardCategories(filteredObj.photoCardCategories);

      queryClient.invalidateQueries(["photoCardEvents", artistId]);
      queryClient.invalidateQueries(["collectedPhotoCardIds"]);
    }
  }

  const isCheckedPhotoCardFilter = () => {
    return !isEmpty(selectedPhotoCardCategories) || !isEmpty(selectedEventIds)
  }

  useEffect(() => {
    const receiveMessage = throttle((event) => {
        if(event.origin !== window.location.origin) return;
        if(isEmpty(event.data?.type)) return;

        if (event.data?.type === "reported-new-photo-cards") {
            queryClient.invalidateQueries(["photoCardEvents"]);
            queryClient.invalidateQueries(["collectedPhotoCardIds"]);
        }

        // collected 상태 업데이트 이벤트 처리
        if (event.data?.type === "update-collected-count-on-artist-photo-cards") {
            // TODO : Use PhotoCardId to refresh paginatedEvents
            queryClient.invalidateQueries(["photoCardEvents"]);
        }
    }, 1000);

    window.addEventListener("message", receiveMessage, false);

    return () => {
        window.removeEventListener("message", receiveMessage);
    };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [queryClient, currentMember]);
  // #endregion

  // 크기, 위치 등을 맞추기 힘들어서 텍스트만 보이지만 메뉴를 활용한다
  const DropdownTextOnly = ({artist}) => {
    return (
      <Menu>
        <MenuButton
          height={"40px"}
          as={Button}
          colorScheme=""
          color={"black"}
          width={"fit-content"}
          padding={0}
          rightIcon={
            <Box height="40px" display="flex" alignItems="center" justifyContent="center">
              <ChevronDownIcon width={"22px"} height={"22px"} fill={"#666666"} opacity={0}/>
            </Box>
          }>
          <Box height="40px" display="flex" alignItems="center" justifyContent="center">
            <Text fontSize={"26px"} fontWeight={700} color={"#222"}>{artist?.name}</Text>
          </Box>
        </MenuButton>
      </Menu>
    );
  };

  // const NavigationMenus = (editable) => {
  //   if (editable) {
  //     return (<Text onClick={() => navigate(
  //       "/photo_cards/report",
  //       { state: { artist: artist, showBackButton: true, navBarVisible: false } }
  //     )}>{t("photoCardPage.navigationReport")}</Text>)
  //   } else {
  //     return(
  //       <Menu>
  //         <MenuButton
  //           minWidth={"0px"}
  //           height={"18px"}
  //           as={IconButton}
  //           variant=""
  //           aria-label='Options'
  //           paddingX={"0px!important"}
  //           icon={<MoreIcon fill={LightColors.textsublv2} width={"17px"} height={"17px"}/>}
  //         />
  //         <MenuList>
  //           <MenuItem onClick={() =>
  //             navigate(
  //               "/photo_cards/report",
  //               { state: { artist: artist, showBackButton: true, navBarVisible: false } }
  //             )
  //           }>
  //             {t("photoCardPage.navigationReport")}
  //           </MenuItem>
  //           <MenuItem onClick={() => saveData("hiddenCollectedPhotoCardStat", false)}>
  //             {t("photoCardPage.showPhotoCardsOnFeed")}
  //           </MenuItem>
  //         </MenuList>
  //       </Menu>
  //     )
  //   }
  // }

  return (
    <div style={{background: "white"}}>
      <StickyHeader
        showBackButton={true}
        showTitle={true}
        title={t("photoCardPage.pageName")}
        onClickBackButton={onHistoryBackClick}
        transparent={false}
      />
      <Stack spacing={0} paddingX={"24px"} marginTop={"calc(58px + env(safe-area-inset-top))"}>
        <Title>{t("photoCardPage.listTitle")}</Title>
        {
          // #region artist name & selector
        }
        <Row>
        {
          editable
            ?
            <ArtistRowWrapper>
              <DropdownTextOnly artist={selectedArtist}/>
              <Spacer/>
              <SecondaryLineButton
                text={
                  <FilterButtonText isChecked={isCheckedPhotoCardFilter()}>
                    {t("photoCardPage.filter")}
                  </FilterButtonText>
                }
                rightElement={
                  <Switch
                    size={'sm'}
                    alignContent={"center"}
                    colorScheme={"primary"}
                    isChecked={isCheckedPhotoCardFilter()}
                    onChange={() => onChangePhotoCardFilter()}
                    style={{"--switch-track-height": "0.6rem"}}
                    // TODO Switch BGColor
                  />
                }
                style={{
                  margin: "1.5px 0px",
                  border: isCheckedPhotoCardFilter() ? `1.5px solid ${LightColors.primary}` : `1px solid ${LightColors.separatorlv1}`,
                  boxShadow: isCheckedPhotoCardFilter() ? "0px 0px 5px 0px rgba(255, 118, 250, 0.2), 0px 0px 8px 0px rgba(161, 142, 255, 0.3)" : "",
                  padding: "8px 14px",
                  gap: "4px",
                }}
              />
            </ArtistRowWrapper>
            :
            <ArtistRowWrapper>
            <ArtistTextSelector
              artists={artists}
              onSelected={onChangeArtist}
              alignDropdown="left"
              customTextAreaElementMultiple={
                (artist) =>
                    <MenuButton
                      height={"40px"}
                      as={Button}
                      colorScheme=""
                      width={"fit-content"}
                      padding={0}
                      rightIcon={
                        <Box height="40px" display="flex" alignItems="center" justifyContent="center" marginInlineStart={"-0.5rem"} paddingLeft={"6px"}>
                          <ChevronDownIcon width={"22px"} height={"22px"} fill={"#666666"}/>
                        </Box>
                      }>
                      <Box height="40px" display="flex" alignItems="center" justifyContent="center">
                        <Text fontSize={"26px"} fontWeight={700} color={"#222"}>{artist?.shortName === "TXT" ? artist?.shortName : artist?.name}</Text>
                      </Box>
                    </MenuButton>
              }
              customTextAreaElementSingle={
                (artist) => <DropdownTextOnly artist={artist}/>
              }
              customExtraDropdownMenus={[
                {
                  name: t("photoCardPage.manageArtist"),
                  onClick: () => {
                    navigate('/artists/manage');
                  }
                }
              ]}
              />
              <Spacer/>
              <SecondaryLineButton
                text={
                  <FilterButtonText isChecked={isCheckedPhotoCardFilter()}>
                    {t("photoCardPage.filter")}
                  </FilterButtonText>
                }
                rightElement={
                  <Switch
                    size={'sm'}
                    alignContent={"center"}
                    colorScheme={"primary"}
                    isChecked={isCheckedPhotoCardFilter()}
                    onChange={() => onChangePhotoCardFilter()}
                    style={{"--switch-track-height": "0.6rem"}}
                    // TODO Switch BGColor
                  />
                }
                style={{
                  margin: "1.5px 0px",
                  border: isCheckedPhotoCardFilter() ? `1.5px solid ${LightColors.primary}` : `1px solid rgba(0, 0, 0, 0.05)`,
                  boxShadow: isCheckedPhotoCardFilter() ? "0px 0px 5px 0px rgba(255, 118, 250, 0.2), 0px 0px 8px 0px rgba(161, 142, 255, 0.3)" : "",
                  padding: "8px 14px",
                  gap: "4px"
                }}
              />
            </ArtistRowWrapper>
        }
        </Row>

        {
          editable &&
            <Box
              marginTop={"18px!important"}
              marginBottom={"5px!important"}
              paddingX={"20px"}
              paddingY={"14px"}
              width={"100%"}
              borderRadius={"12px"}
              backgroundColor="#0000000A"
            >
              <Wrap justify={"center"}>
                <Box
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                >
                  <Image width="16px" height="16px" src="/assets/icons/color.mode.png" marginRight={"7px"}/>
                  <Text color={"#444444"} fontSize={"14px"} fontWeight={500} lineHeight={"16.8px"}>
                    {t("photoCardPage.showColorMode")}
                  </Text>
                </Box>
                <Spacer/>
                <Switch size='sm' colorScheme="primary" isChecked={isColorModeOnEditable} onChange={()=>onColorModeSwitchChanged()}/>
              </Wrap>
            </Box>
        }

        {
          // #endregion
        }
      </Stack>
      {/* <Wrap spacing={"0px"} paddingTop="6px" paddingBottom="16px" marginBottom={"96px"} paddingX={"24px"}> */}
      <LikedMembersListWrapper>
        <LikedMembersList artistMembers={artist?.members?.filter((member) => member.isLiked)} onChangeLikedMember={onChangedMember}/>
        {
          isUndefined(paginatedEvents) ?
          <EmptyPhotoCardEventSkeletonV2/> :
          (
            isEmpty(paginatedEvents) ?
            <div style={{width: "100%"}}>
              <EmptyCase emoji={"🥺"} description={t("photoCardPage.emptyPhotoCards")}/>
            </div> :
            <div className="w-full">
              {
                editable
                ? <PhotoCardsByEventEditor
                    events={paginatedEvents}
                    onClickedPhotoCard={(photoCard, e) => onClickPhotoCard(e, photoCard)}
                    endReached={loadMore}
                    enableAll={false}
                    isColorMode={isColorModeOnEditable}
                  />
                : <PhotoCardsByEventViewer
                    events={paginatedEvents}
                    onClickedPhotoCard={(photoCard, e) => onClickPhotoCard(e, photoCard)}
                    endReached={loadMore}
                    enableAll={false}
                  />
              }
              {isFetching && <Center marginTop={"8px"}><Spinner size='sm' color="primary.500" marginRight={"20px"}/></Center>}
            </div>
          )
        }
        {
          (selectedArtist || artist) && currentMember &&
          <ReportPhotoCardBoxWrapper>
            <ReportPhotoCardBox artist={selectedArtist || artist} member={currentMember}/>
          </ReportPhotoCardBoxWrapper>
        }
      {/* </Wrap> */}
      </LikedMembersListWrapper>


    <SelectPhotoCardBottomNav
      selectedMember={currentMember}
      selectedCardCount={collectedCount}
      totalCardCount={meta?.photoCardsInfo?.totalCount}
      onConfirmedButton={onCompletedEditable}
      setEditable={setEditable}
      editable={editable}
      showEditButton={true}
    />

    <MyPhotoCardBottomSheet
      isMyPhotoCardsBottomSheetOpen={isMyPhotoCardsBottomSheetOpen}
      setIsMyPhotoCardsBottomSheetOpen={setIsMyPhotoCardsBottomSheetOpen}
    />
    {
      isOpenExitDialog &&
      <CommonDialog
        isOpen={isOpenExitDialog}
        onClose={() => setIsOpenExitDialog(false)}
        description={t("photoCardPage.discardAlertDialog")}
        noButtonText={t("no")}
        yesButtonText={t("yes")}
        onClickDialogButton={() => IgnoreAndExit()}
      />
    }
    {
      isShowFilterBottomDrawer &&
      <PhotoCardFilterBottomDrawer
        artistId={artistId}
        isOpen={isShowFilterBottomDrawer}
        photoCardCategories={selectedPhotoCardCategories}
        eventIds={selectedEventIds}
        onClose={() => setIsShowFilterBottomDrawer(false)}
        onConfirm={(obj) => onConfirmPhotoCardFilter(obj)}
      />
    }
    </div>
  )
}
