import { Box } from "@chakra-ui/react";
import LightColors from "../../constants/LightColors";
import { ReactComponent as BellIcon } from "../../icons/bell.svg";
import { useNavigate } from "react-router-dom";

export const NotificationBadge = () => {
  const navigate = useNavigate();

  const clickedNotification = () => {
    navigate("/notifications");
  }

  return(
    <Box
      width={"33px"}
      height={"33px"}
      display={"flex"}
      alignSelf={"center"}
      borderRadius={"full"}
      alignItems={"center"}
      justifyContent={"center"}
      onClick={() => clickedNotification()}
      backgroundColor={LightColors.secondary}
    >
      <BellIcon width={19} height={19} fill={LightColors.primary}/>
    </Box>
  );
}
