import { t } from "i18next";

import LightColors from "../../constants/LightColors";
import { ReactComponent as CheckIcon } from "../../icons/check.svg";
import { ReactComponent as PlusIcon } from "../../icons/plus.svg";
import ToggleFollow from "../api/mutations/ToggleFollow";
import ProfileLineButton from "./ProfileLineButton";
import SecondaryButton from "./SecondaryButton";

export const FollowButton = ({currentUserProfileId, targetUserProfile, onFollowed}) => {
  const handleFollowUser = async (isFollowed, targetUserProfileId) => {
    const response = await ToggleFollow({
      isFollowed: isFollowed,
      currentUserProfileId: currentUserProfileId,
      followUserProfileId: targetUserProfileId,
    });

    if (response && response.ok) {
      if (onFollowed) {
        onFollowed(targetUserProfileId, isFollowed)
      }
    }
  }

  return (
    <>
    {targetUserProfile.isFollowed ? (
      <ProfileLineButton
        text={t("following")}
        onClick={() => handleFollowUser(true, targetUserProfile.userProfileId)}
        fontSize="13px"
        lineHeight="16px"
        padding={"6px 12px"}
        minWidth="80px"
        rightIcon={
          <div style={{marginLeft: "4px"}}>
            <CheckIcon fill={LightColors.onLabel} width={"14px"} height={"14px"} />
          </div>
        }
      />
    ) : (
      <SecondaryButton
        text={t("follow")}
        onClick={() => handleFollowUser(false, targetUserProfile.userProfileId)}
        fontSize="13px"
        lineHeight="16px"
        paddingX="12px"
        paddingY="6px"
        minWidth="80px"
        border={`1px solid ${LightColors.secondary}`}
        icon={
          <div style={{marginLeft: "4px"}}>
            <PlusIcon fill={LightColors.primary} width={"14px"} height={"14px"} />
          </div>
        }
      />
    )}
    </>
  )
}
