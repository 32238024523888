import { Box, Button, Center, Menu, MenuButton, Spacer, Spinner, Switch, Text, useToast, VStack } from "@chakra-ui/react";
import { t } from "i18next";
import { first, isEmpty, isUndefined, throttle } from "lodash";
import React, { useEffect, useState } from "react";
import { useQueryClient } from "react-query";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";

import { Helmet } from "react-helmet-async";
import ToastMessageBox from "../components/alerts/ToastMessageBox";
import EventResponse from "../components/api/model/EventResponse";
import updateCollectedPhotoCard from "../components/api/mutations/legacy/updateCollectedPhotoCard";
import useArtistQuery, { useArtistQueryLikedOnly } from "../components/api/queries/useArtistQuery";
import usePhotoCardEventsQuery from "../components/api/queries/usePhotoCardEventsQuery";
import useRefreshJwtQuery from "../components/api/queries/useRefreshJwtQuery";
import useUserQuery from "../components/api/queries/useUserQuery";
import ChangePhotoCardCollectedCountBottomSheet from "../components/bottomSheet/ChangePhotoCardCollectedCountBottomSheet";
import MyPhotoCardBottomSheet from "../components/bottomSheet/MyPhotoCardBottomSheet";
import PrimaryButton from "../components/buttons/PrimaryButton";
import SecondaryLineButton from "../components/buttons/SecondaryLineButton";
import PhotoCardFilterBottomDrawer from "../components/drawer/PhotoCardFilterBottomDrawer";
import EmptyCase from "../components/errors/EmptyCase";
import { PopupFabButton } from "../components/fab/PopupFabButton";
import FanBuddyBar from "../components/fan-buddy/FanBuddyBar";
import LikedMembersList from "../components/lists/LikedMembersList";
import { ReportPhotoCardBox } from "../components/report/ReportPhotoCardBox";
import ArtistTextSelector from "../components/selectors/ArtistTextSelector";
import EmptyPhotoCardEventSkeletonV2 from "../components/skeletons/EmptyPhotoCardEventSkeletonV2";
import PhotoCardsByEventViewer from "../components/virtualized/PhotoCardsByEvent/PhotoCardsByEventViewer";
import LightColors from "../constants/LightColors";
import { ReactComponent as CardFillIcon } from "../icons/card.fill.svg";
import { ReactComponent as ChevronDownIcon } from "../icons/chevron.down.svg";
import { ReactComponent as GiftFillIcon } from "../icons/gift.fill.svg";
import { ReactComponent as MapFillIcon } from "../icons/map.fill.svg";
import { ReactComponent as TshirtFillIcon } from "../icons/tshirt.fill.svg";
import { ReactComponent as YoutubeIcon } from "../icons/youtube.svg";
import HomeBottomNav from "../layout/HomeBottomNav";
import { openNewTab } from "../utils/UIUtils";
import { getArtistId, getArtistIdAnyway } from "../utils/etcUtils";
import { setEvent } from "../utils/ga";
import saveData from "../utils/saveData";
import { NotificationBadge } from "./badge/NotificationBadge";
import BoxTabs from "./tabs/BoxTabs";

const PageWrapper = styled.div`
  padding-top: calc(env(safe-area-inset-top));
  background-attachment: scroll;
  height: 100vh;
`

const Row = styled.div`
  display: flex;
  flex-direction: row;
`

const ArtistRowWrapper = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  padding: 12px 24px;
`

const BoxTabWrapper = styled.div`
  padding: 4px 24px;
`

const LikedMembersListWrapper = styled.div`
  padding: 6px 24px 0px 24px;
`

const PhotoCardViewerWrapper = styled.div`
  padding: 0px 24px;
`
const ReportPhotoCardBoxWrapper = styled.div`
  padding: 32px 24px 128px 24px;
`

const FilterButtonText = styled.div`
  color: ${(props) => (props.isChecked ? LightColors.primary : LightColors.textmainlv1)};
  font-size: 14px;
  font-weight: 600;
  line-height: 17px
`

const DummyTitle = styled.div`
  color: ${LightColors.textmainlv2};
  font-size: 17px;
  font-weight: 700;
  padding: 0px 12px;
  line-height: 24px;
  margin-top: 26px;
  text-align: center;
  white-space: pre-wrap;
`;

const DummyDescription = styled.div`
  color: ${LightColors.textmainlv4};
  font-size: 15px;
  font-weight: 500;
  line-height: 18px;
  padding: 0px 12px;
  margin-top: 8px;
  text-align: center;
  white-space: pre-wrap;
`;

const DummyImageWrapper = styled.div`
  width: 100%;
  height: 100vh;
  background-size: contain;
  display: flex;
  justify-content: center;
`;

const DummyContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 20%;
`;

const DummyImage = styled.img`
  width: 140px;
  margin: 0 auto;
`;

const WatingButtonWrapper = styled.div`
  margin-top: 12px;
`

const WAITING_LOCATION_KEY = "WAITING_QUEUE_LOCATION";
const WAITING_GOODS_KEY = "WAITING_QUEUE_GOODS";
const WAITING_FASHION_KEY = "WAITING_QUEUE_FASHION";
const WAITING_HOME_MASTER_KEY = "WAITING_QUEUE_HOME_MASTER";

const TABS = [
  <CardFillIcon width={20} height={20} fill={LightColors.textmainlv4}/>,
  <MapFillIcon width={20} height={20} fill={LightColors.textmainlv4}/>,
  <GiftFillIcon width={20} height={20} fill={LightColors.textmainlv4}/>,
  <TshirtFillIcon width={20} height={20} fill={LightColors.textmainlv4}/>,
  <YoutubeIcon width={20} height={20} fill={LightColors.textmainlv4}/>
]

export default function CollectionsHomePage() {
  // 바텀시트 보이는지
  const [isMyPhotoCardsBottomSheetOpen, setIsMyPhotoCardsBottomSheetOpen] = useState(false);
  const [isShowChangeCollectCountBottomSheet, setIsShowChangeCollectCountBottomSheet] = useState(false);
  const [isShowFilterBottomDrawer, setIsShowFilterBottomDrawer] = useState(false);

  // Initialize state for paginated data and updated data
  const [currentMember, setCurrentMember] = useState();
  const [selectedPhotoCard, setSelectedPhotoCard] = useState();
  const [photoCardSummary, setPhotoCardSummary] = useState({});
  const [paginatedEvents, setPaginatedEvents] = useState();
  const [updatedCollectedData, setUpdatedCollectedData] = useState({});
  const [selectedCollectedData, setSelectedCollectedData] = useState({});
  const [forceRender, setForceRender] = useState(false);
  const [selectedArtist, setSelectedArtist] = useState(null);
  const [selectedEventIds, setSelectedEventIds] = useState([]);
  const [selectedPhotoCardCategories, setSelectedPhotoCardCategories] = useState([]);
  const [tabIndex, setTabIndex] = useState(0);

  // Waiting Queue 저장 State
  const [isWaitingLocationVisible, setIsWaitingLocationVisible] = useState(localStorage.getItem(WAITING_LOCATION_KEY) !== "true");
  const [isWaitingGoodsVisible, setIsWaitingGoodsVisible] = useState(localStorage.getItem(WAITING_GOODS_KEY) !== "true");
  const [isWaitingFashionVisible, setIsWaitingFashionVisible] = useState(localStorage.getItem(WAITING_FASHION_KEY) !== "true");
  const [isWaitingHomeMasterVisible, setIsWaitingHomeMasterVisible] = useState(localStorage.getItem(WAITING_HOME_MASTER_KEY) !== "true");

  const user = useUserQuery();
  const artistId = getArtistId();
  const queryClient = useQueryClient();

  const navigate = useNavigate();
  const toast = useToast();

  // #region API
  const authToken = useRefreshJwtQuery();
  if (authToken?.jwt) {
    saveData("token", JSON.stringify(authToken?.jwt));
  }
  const artists = useArtistQueryLikedOnly();
  const artist = useArtistQuery(artistId);
  const { data, isFetching, fetchNextPage, hasNextPage } = usePhotoCardEventsQuery({
    artistId: selectedArtist?.artistId || artistId,
    memberId: currentMember?.memberId,
    eventIds: selectedEventIds,
    photoCardCategories: selectedPhotoCardCategories,
  });
  // #endregion


  // Function to handle the received paginated data
  const handlePaginatedData = (newPaginatedData) => {
    const updatedEvents = newPaginatedData.flatMap((page) =>
      page.events.map((event) => {
        const newEvent = {
          ...event,
          photoCards: event?.photoCards?.map((photoCard) => {
            const memberId = photoCard.memberId;

            let newCollectedCount = photoCard.collectedCount;
            if(selectedCollectedData[memberId] && selectedCollectedData[memberId][photoCard.photoCardId]) {
              newCollectedCount = selectedCollectedData[memberId][photoCard.photoCardId]?.collectedCount
            }else if(updatedCollectedData[memberId] && updatedCollectedData[memberId][photoCard.photoCardId]) {
              newCollectedCount = updatedCollectedData[memberId][photoCard.photoCardId]?.collectedCount
            }

            return {
              ...photoCard,
              ...{ collectedCount: newCollectedCount },
            };
          }),
        };
        // Exclude null or empty events and photoCards
        if (!isEmpty(newEvent) && !isEmpty(newEvent.photoCards)) {
          return new EventResponse(newEvent);
        }
        return null;
      })
    );
    setPaginatedEvents(updatedEvents.filter((event) => event !== null));
  };

  // Use useEffect to update paginatedData and updatedData on new data arrival
  useEffect(() => {
    if (!isEmpty(data)) {
      handlePaginatedData(data.pages);
      setPhotoCardSummary(first(data.pages)?.meta?.photoCardsInfo);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, updatedCollectedData, selectedCollectedData]);

  // #region events
  const loadMore = () => {
    if(hasNextPage){
      fetchNextPage()
    }
  }

  // 멤버 선택 변경
  const onChangedMember = (member) => {
    setCurrentMember(member);
  }

  const onClickPhotoCard = (event, photoCard) => {
    openNewTab(`/photo_cards/${photoCard.photoCardId}`);
  }

  const onLongClickedPhotoCard = (photoCard) => {
    setSelectedPhotoCard(photoCard);
    setIsShowChangeCollectCountBottomSheet(true);
  }

  const onChangeArtist = (artist) => {
    setSelectedArtist(artist)
    saveData("artist", artist.artistId);
    initFilters();
    setForceRender(!forceRender);
  }

  const initFilters = () => {
    setSelectedEventIds([]);
    setSelectedPhotoCardCategories([]);
  }
  const onTabSelected = (index) => {
    setTabIndex(index);
  }

  const onChangePhotoCardFilter = () => {
    setIsShowFilterBottomDrawer(!isShowFilterBottomDrawer)
  }

  const onConfirmPhotoCardFilter = (filteredObj) => {
    setIsShowFilterBottomDrawer(false);

    if (!isEmpty(filteredObj)) {
      setSelectedEventIds(filteredObj.eventIds);
      setSelectedPhotoCardCategories(filteredObj.photoCardCategories);
      invalidateQueries();
    }
  }

  const updateCollectedCount = async (photoCard, collectedCount) => {
      const response = await updateCollectedPhotoCard(photoCard.photoCardId, collectedCount);
      if(response.success) {
        invalidateQueries();
      }
    }

  const isCheckedPhotoCardFilter = () => {
    return !isEmpty(selectedPhotoCardCategories) || !isEmpty(selectedEventIds)
  }

  useEffect(() => {
    const receiveMessage = throttle((event) => {
      if(event.origin !== window.location.origin) return;
      if(isEmpty(event.data?.type)) return;

      if (event.data?.type === "reported-new-photo-cards") {
        invalidateQueries();
      }

      // collected 상태 업데이트 이벤트 처리
      if (event.data?.type === "update-collected-count-on-artist-photo-cards") {
          // TODO : Use PhotoCardId to refresh paginatedEvents
          invalidateQueries();
      }
    }, 1000);

    window.addEventListener("message", receiveMessage, false);
    return () => {
        window.removeEventListener("message", receiveMessage);
    };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [queryClient, currentMember]);
  // #endregion

  const onClickFabButton = (index) => {
    if (index === 0) {
      openNewTab(`/report-photo-card/select-member?artistId=${selectedArtist?.artistId}`)
    } else if (index === 1) {
      openNewTab("/collected_photo_cards/edit")
    }
  }

  const invalidateQueries = () => {
    queryClient.invalidateQueries(["photoCardEvents", artistId]);
    queryClient.invalidateQueries(["collectedPhotoCardIds"]);
  }

  const onClickWaitingButton = (tabIndex) => {
    if(tabIndex === 1) {
      setEvent({
        category: "Collection Waiting",
        action: 'Button Click Location',
        label: 'Location',
      })
      localStorage.setItem(WAITING_LOCATION_KEY, true);
      setIsWaitingLocationVisible(false);
    } else if(tabIndex === 2) {
      setEvent({
        category: "Collection Waiting",
        action: 'Button Click Goods',
        label: 'Goods',
      })
      localStorage.setItem(WAITING_GOODS_KEY, true);
      setIsWaitingGoodsVisible(false);
    } else if (tabIndex === 3) {
      setEvent({
        category: "Collection Waiting",
        action: 'Button Click Fashion',
        label: 'Fashion',
      })
      localStorage.setItem(WAITING_FASHION_KEY, true);
      setIsWaitingFashionVisible(false);
    } else if (tabIndex === 4) {
      setEvent({
        category: "Collection Waiting",
        action: 'Button Click Home Master',
        label: 'Home Master',
      })
      localStorage.setItem(WAITING_FASHION_KEY, true);
      setIsWaitingHomeMasterVisible(false);
    }
    toast({
      duration: 1500,
      render: () => (
        <ToastMessageBox
          message={t("CollectionsHomePage.WatingToast")}
          showOnBottom={true}
        />
      ),
    });
  }

  const renderDummyImage = (tabIndex) => {
    switch(tabIndex){
      case 1:
        return (
          <DummyImageWrapper style={{ backgroundImage: 'url(/assets/backgrounds/collections/dummy-birthdaycafes.png)' }}>
            <DummyContentWrapper>
              <DummyImage src="/assets/icons/collections/Milky Way.png" alt="teddy_bear" />
              <DummyTitle>
                {t("CollectionsHomePage.DummyBirthdayCafe")}
              </DummyTitle>
              <DummyDescription>
                {t("CollectionsHomePage.DummyDescription")}
              </DummyDescription>
              <WatingButtonWrapper>
                <PrimaryButton
                  disabled={!isWaitingLocationVisible}
                  style={{padding: "12px 18px"}}
                  text={t("CollectionsHomePage.WatingButton")}
                  onClick={() => onClickWaitingButton(1)}
                />
              </WatingButtonWrapper>

            </DummyContentWrapper>
          </DummyImageWrapper>
        )
      case 2:
        return (
          <DummyImageWrapper style={{ backgroundImage: 'url(/assets/backgrounds/collections/dummy-goods.png)' }}>
            <DummyContentWrapper>
              <DummyImage src="/assets/icons/collections/Teddy Bear.png" alt="teddy_bear" />
              <DummyTitle>
                {t("CollectionsHomePage.DummyGoods")}
              </DummyTitle>
              <DummyDescription>
                {t("CollectionsHomePage.DummyDescription")}
              </DummyDescription>
              <WatingButtonWrapper>
                <PrimaryButton
                  disabled={!isWaitingGoodsVisible}
                  style={{padding: "12px 18px"}}
                  text={t("CollectionsHomePage.WatingButton")}
                  onClick={() => onClickWaitingButton(2)}
                />
              </WatingButtonWrapper>
            </DummyContentWrapper>
          </DummyImageWrapper>
        )
      case 3:
        return (
          <DummyImageWrapper style={{ backgroundImage: 'url(/assets/backgrounds/collections/dummy-fashions.png)' }}>
            <DummyContentWrapper>
              <DummyImage src="/assets/icons/collections/Women Holding Hands Light Skin Tone.png" alt="teddy_bear" />
              <DummyTitle>
                {t("CollectionsHomePage.DummyFashion")}
              </DummyTitle>
              <DummyDescription>
                {t("CollectionsHomePage.DummyDescription")}
              </DummyDescription>
              <WatingButtonWrapper>
                <PrimaryButton
                  disabled={!isWaitingFashionVisible}
                  style={{padding: "12px 18px"}}
                  text={t("CollectionsHomePage.WatingButton")}
                  onClick={() => onClickWaitingButton(3)}
                />
              </WatingButtonWrapper>
            </DummyContentWrapper>
          </DummyImageWrapper>
        )
        case 4:
          return (
            <DummyImageWrapper style={{ backgroundImage: 'url(/assets/backgrounds/collections/dummy-home-master.png)' }}>
              <DummyContentWrapper>
                <DummyImage src="/assets/icons/collections/Film Projector.png" alt="teddy_bear" />
                <DummyTitle>
                  {t("CollectionsHomePage.DummyHomeMaster")}
                </DummyTitle>
                <DummyDescription>
                  {t("CollectionsHomePage.DummyDescription")}
                </DummyDescription>
                <WatingButtonWrapper>
                  <PrimaryButton
                    disabled={!isWaitingHomeMasterVisible}
                    style={{padding: "12px 18px"}}
                    text={t("CollectionsHomePage.WatingButton")}
                    onClick={() => onClickWaitingButton(4)}
                  />
                </WatingButtonWrapper>
              </DummyContentWrapper>
            </DummyImageWrapper>
          )
      default:
        return <></>
    }
  }

  const isShowFabButton = () => {
    return (tabIndex === 0 && (!isShowFilterBottomDrawer && !isShowChangeCollectCountBottomSheet && !isShowChangeCollectCountBottomSheet))
  }

  return (
    <PageWrapper className="homeBackground">
      <Helmet>
        <title>{t("tabs.collections")}</title>
        <meta name="theme-color" content={LightColors.purewhite} />
      </Helmet>
      <Header artists={artists} onChangeArtist={onChangeArtist}/>
      {
        user?.country === "KR" && <FanBuddyBar style={{marginBottom: "12px"}}/>
      }
      <BoxTabWrapper>
        <BoxTabs tabLabels={TABS} onTabSelected={onTabSelected}/>
      </BoxTabWrapper>
      {
        tabIndex === 0 ?
        (
          <>
            <PhotoCardsSummaryRow
              totalCount={photoCardSummary?.totalCount}
              collectedCount={photoCardSummary?.collectedCount}
              isCheckedPhotoCardFilter={isCheckedPhotoCardFilter}
              onChangePhotoCardFilter={onChangePhotoCardFilter}
            />
            <LikedMemberLists
              artist={artist}
              selectedArtist={selectedArtist}
              currentMember={currentMember}
              onChangedMember={onChangedMember}
            />
            <PhotoCardViewerWrapper>
              {
                isUndefined(paginatedEvents) ?
                <EmptyPhotoCardEventSkeletonV2/> :
                (
                  isEmpty(paginatedEvents) ?
                  <div style={{width: "100%"}}>
                    <EmptyCase emoji={"🥺"} description={t("photoCardPage.emptyPhotoCards")}/>
                  </div> :
                  <div className="w-full">
                    <PhotoCardsByEventViewer
                      events={paginatedEvents}
                      onClickedPhotoCard={(photoCard, e) => onClickPhotoCard(e, photoCard)}
                      onLongClickedPhotoCard={(photoCard) => onLongClickedPhotoCard(photoCard)}
                      endReached={loadMore}
                      enableAll={false}
                    />
                    {isFetching && <Center marginTop={"8px"}><Spinner size='sm' color="primary.500" marginRight={"20px"}/></Center>}
                  </div>
                )
              }
            </PhotoCardViewerWrapper>
            {
              artist && currentMember &&
              <ReportPhotoCardBoxWrapper>
                <ReportPhotoCardBox artist={selectedArtist} member={currentMember}/>
              </ReportPhotoCardBoxWrapper>
            }
          </>
        ): (renderDummyImage(tabIndex))
      }

      {
        isShowFabButton() &&
        <PopupFabButton
          buttonTexts={[t("CollectionsHomePage.ReportPhotoCardFabButton"), t("CollectionsHomePage.EditPhotoCardFabButton")]}
          onClickFabChildrenButton={onClickFabButton}
        />
      }

      <HomeBottomNav
        tabIndex={0}
        artistId={getArtistIdAnyway(artist)}
      />

      {
        isMyPhotoCardsBottomSheetOpen &&
        <MyPhotoCardBottomSheet
          isMyPhotoCardsBottomSheetOpen={isMyPhotoCardsBottomSheetOpen}
          setIsMyPhotoCardsBottomSheetOpen={setIsMyPhotoCardsBottomSheetOpen}
        />
      }
      {
        isShowFilterBottomDrawer &&
        <PhotoCardFilterBottomDrawer
          artistId={artistId}
          isOpen={isShowFilterBottomDrawer}
          photoCardCategories={selectedPhotoCardCategories}
          eventIds={selectedEventIds}
          onClose={() => setIsShowFilterBottomDrawer(false)}
          onConfirm={(obj) => onConfirmPhotoCardFilter(obj)}
        />
      }
      {
        selectedPhotoCard && isShowChangeCollectCountBottomSheet &&
        <ChangePhotoCardCollectedCountBottomSheet
          isShowChangeCollectCountBottomSheet={isShowChangeCollectCountBottomSheet}
          setIsShowChangeCollectCountBottomSheet={setIsShowChangeCollectCountBottomSheet}
          currentCollectedCount={selectedPhotoCard.collectedCount > 0 ? selectedPhotoCard.collectedCount : 1}
          updatedCollectedCount={(collectedCount) => updateCollectedCount(selectedPhotoCard, collectedCount)}
        />
      }
    </PageWrapper>
  )
}

const DropdownTextOnly = ({artist}) => {
  return (
    <Menu>
      <MenuButton
        height={"40px"}
        as={Button}
        colorScheme=""
        color={"black"}
        width={"fit-content"}
        padding={0}
        rightIcon={
          <Box height="40px" display="flex" alignItems="center" justifyContent="center">
            <ChevronDownIcon width={"22px"} height={"22px"} fill={"#666666"} opacity={0}/>
          </Box>
        }>
        <Box height="40px" display="flex" alignItems="center" justifyContent="center">
          <Text fontSize={"26px"} fontWeight={700} color={"#222"}>{artist?.name}</Text>
        </Box>
      </MenuButton>
    </Menu>
  );
};

const Header = ({artists, onChangeArtist}) => {
  const navigate = useNavigate();
  return(
    <ArtistRowWrapper>
      {
        artists ? (
          <ArtistTextSelector
            artists={artists}
            onSelected={onChangeArtist}
            alignDropdown="left"
            customTextAreaElementMultiple={
              (artist) =>
                <MenuButton
                  height={"40px"}
                  as={Button}
                  colorScheme=""
                  width={"fit-content"}
                  padding={0}
                  rightIcon={
                    <Box height="40px" display="flex" alignItems="center" justifyContent="center" marginInlineStart={"-0.5rem"} paddingLeft={"6px"}>
                      <ChevronDownIcon width={"22px"} height={"22px"} fill={"#666666"}/>
                    </Box>
                  }>
                  <Box height="40px" display="flex" alignItems="center" justifyContent="center">
                    <Text fontSize={"26px"} fontWeight={700} color={"#222"}>{artist?.shortName === "TXT" ? artist?.shortName : artist?.name}</Text>
                  </Box>
                </MenuButton>
            }
            customTextAreaElementSingle={
              (artist) => <DropdownTextOnly artist={artist}/>
            }
            customExtraDropdownMenus={[
              {
                name: t("photoCardPage.manageArtist"),
                onClick: () => {
                  navigate('/artists/manage');
                }
              }
            ]}
          />
        ) : (
          <Text fontSize={"26px"} fontWeight={700} color={"#222"}>{"-"}</Text>
        )

      }

      <Spacer/>
      <NotificationBadge/>
    </ArtistRowWrapper>
  )
}

const PhotoCardsSummaryRow = ({totalCount, collectedCount, isCheckedPhotoCardFilter, onChangePhotoCardFilter}) => {
  return(
    <Row style={{padding: "12px 24px"}}>
      <VStack align={"start"} spacing={"4px"}>
        <Text textColor={"#444"} fontWeight={600} fontSize={"17px"} lineHeight={"20px"}>
          {t("CollectionsHomePage.RegisteredPhotoCards", { count: totalCount })}
        </Text>
        <Text textColor={"#777"} fontWeight={400} fontSize={"13px"} lineHeight={"16px"}>
          {t("CollectionsHomePage.CollectedPhotoCards", { count: collectedCount })}
        </Text>
      </VStack>
      <Spacer/>
      <SecondaryLineButton
        text={
          <FilterButtonText isChecked={isCheckedPhotoCardFilter()}>
            {t("photoCardPage.filter")}
          </FilterButtonText>
        }
        rightElement={
          <Switch
            size={'sm'}
            alignContent={"center"}
            colorScheme={"primary"}
            isChecked={isCheckedPhotoCardFilter()}
            onChange={() => onChangePhotoCardFilter()}
            style={{"--switch-track-height": "0.6rem"}}
            // TODO Switch BGColor
          />
        }
        style={{
          margin: "1.5px 0px",
          border: isCheckedPhotoCardFilter() ? `1.5px solid ${LightColors.primary}` : `1px solid rgba(0, 0, 0, 0.05)`,
          boxShadow: isCheckedPhotoCardFilter() ? "0px 0px 5px 0px rgba(255, 118, 250, 0.2), 0px 0px 8px 0px rgba(161, 142, 255, 0.3)" : "",
          padding: "8px 14px",
          gap: "4px",
        }}
      />
    </Row>
  )
}

const LikedMemberLists = ({artist, onChangedMember}) => {
  return(
    <LikedMembersListWrapper>
      <LikedMembersList artistMembers={artist?.members?.filter((member) => member.isLiked)} onChangeLikedMember={onChangedMember}/>
    </LikedMembersListWrapper>
  )
}
