import styled from "styled-components";
import LightColors from "../../constants/LightColors";

const StyledButton = styled.div`
  padding: 24px 8px;
  color: ${LightColors.textmainlv3}; // 기본 텍스트 색상
  text-align: center;
  align-content: center;
  border-radius: 8px;
  border: 1px solid ${LightColors.separatorlv1};
  font-size: 13px;
  font-weight: 500;
  line-height: 16px;

  &.selected { // hover 및 선택된 상태일 때
    border: 1px solid ${LightColors.primary};
    background-color: ${LightColors.secondary};
    color: ${LightColors.primary};
  }
`;

export const LineButton = ({text, isSelected, ...props}) => {
  return(
    <StyledButton className={isSelected ? 'selected' : ''} {...props}>
      {text}
    </StyledButton>
  )
}
