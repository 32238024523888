import baseUrl from "../../../utils/baseUrl";
import getToken from "../../../utils/getToken";

export default async function AddBuddyPreference({
  userProfileId,
  mbti,
  allowAgeGap,
  birthyear,
  gender,
  onlyTheSameGender,
  keywords,
}) {
  return fetch(baseUrl() + `/api/v1/user_profiles/${userProfileId}/buddy_preferences`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + getToken(),
    },
    body: JSON.stringify({
      buddy_preference: {
        mbti: mbti,
        allow_age_gap: allowAgeGap,
        birthyear: birthyear,
        gender: gender,
        only_the_same_gender: onlyTheSameGender,
        keywords: keywords.split(',')
      },
    }),
  })
}
