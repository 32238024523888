import React, { useState } from 'react';
import styled from 'styled-components';

import { ReactComponent as CheckedIcon } from "../../icons/check.circle.fill.svg";
import { ReactComponent as UnCheckedIcon } from "../../icons/check.circle.line.svg";
import LightColors from '../../constants/LightColors';

const CheckboxContainer = styled.div`
  display: inline-block;
  vertical-align: middle;
  cursor: pointer;
`;

const PriamryCheckBox = ({ size="md", isChecked, onChanged }) => {
  const getSize = () => {
    return size === "md" ? "24px": "32px"
  }
  return (
    <CheckboxContainer onClick={() => onChanged(!isChecked)}>
      {
        isChecked ? <CheckedIcon width={getSize()} height={getSize()} fill={LightColors.primary}/> : <UnCheckedIcon width={getSize()} height={getSize()}/>
      }
    </CheckboxContainer>
  );
};

export default PriamryCheckBox;
