import { useEffect } from "react";
import eventBus from "./eventBus";

export function refreshScreenSize() {
  let style = document.documentElement.style;
  const vh = window.innerHeight * 0.01;
  const vw = window.innerWidth * 0.01;

  style.setProperty('--vh', `${vh}px`);
  style.setProperty('--vw', `${vw}px`);
}

export function setViewportSize(width, height) {
  let style = document.documentElement.style;
  const vh = height * 0.01;
  const vw = width * 0.01;

  style.setProperty('--vh', `${vh}px`);
  style.setProperty('--vw', `${vw}px`);

  // console.log(`setViewportSize : (${width}, ${height})`);
  eventBus.emit('onSetViewportSize')
}

// 전역 변수로 함수를 노출
window.setViewportSize = setViewportSize;

export function onElementVisible(ref, onVisible) {
  const observer = new IntersectionObserver(
    ([entry]) => {
      if (entry.isIntersecting) {
        // 컴포넌트가 뷰포트에 보일 때 원하는 로직을 실행합니다.
        onVisible?.();
      }
    },
    {
      root: null, // 기본값은 브라우저 뷰포트입니다.
      rootMargin: '0px',
      threshold: 1.0 // 1.0은 타겟 요소가 100% 뷰포트에 보여질 때를 의미합니다.
    }
  );

  if (ref.current) {
    observer.observe(ref.current); // 요소를 관찰합니다.
  }

  return () => {
    if (ref.current) {
      observer.unobserve(ref.current); // 컴포넌트 정리 시 관찰을 중지합니다.
    }
  };
}

export function openNewTab(url) {
  var newQueryParam = 'newTabOpen=true';
  var newUrl;

  // 기존 query parameter가 있는지 확인 후 추가
  if (url.indexOf('?') !== -1) {
    newUrl = url + '&' + newQueryParam;
  } else {
    newUrl = url + '?' + newQueryParam;
  }

  // const newWindow = window.open(url, '_blank', 'noopener,noreferer');
  // const newWindow = window.open(newUrl, '_blank', 'noopener');
  const newWindow = window.open(newUrl, '_blank');

  if (!newWindow) {
    // alert('팝업 차단을 해제해주세요.');
    return;
  }
  newWindow.onload = () => {
    newWindow.onpopstate = () => {
      // alert(`called : ${newWindow.document.referrer}`)
      if (newWindow.history.length > 1) {
        // newWindow.history.back();
      } else {
        newWindow.close();
      }
    };
  };
}


export function usePreventScroll() {
  useEffect(() => {
    // 페이지가 로드될 때 스크롤 막기
    const prev = document.body.style.overflow
    document.body.style.overflow = 'hidden';
    return () => {
      // 페이지를 떠날 때 원래 상태로 복구
      document.body.style.overflow = prev;
    };
  }, []);
}
