import React from "react";

import { GridItem, SimpleGrid, Stack, useBreakpointValue } from '@chakra-ui/react';
import { motion } from "framer-motion";
import { Virtuoso } from "react-virtuoso";
import styled from 'styled-components';
import { useLongPress } from 'use-long-press';
import { EventNameBox } from '../../event/EventNameBox';
import PhotoCard from "../../photocard/PhotoCard";

const EventNameBoxWrapper = styled.div`
  overflow: hidden;
  padding-top: 20px;
  padding-bottom: 9px;
`

export default function PhotoCardsByEventViewer({
  events,
  onClickedPhotoCard,
  onLongClickedPhotoCard,
  endReached,
}) {

  const columns = useBreakpointValue({
    base: 4,   // 모바일
    md: 6,     // iPad
    lg: 6,     // 큰 화면
  });

  const longPressHandler = useLongPress((e, { context: photoCard }) => {
    onLongClickedPhotoCard(photoCard);
  });


  // #region draw cards
  const drawCardsWithEventName = (e) => {
    if (e.photoCards <= 0) {
      return (<div key={e.eventId}></div>)
    } else {
      return(
        <motion.div
          initial={{ opacity: 0, y: -1 }}
          animate={{ opacity: 1, y: 0 }}
          exit={{ opacity: 0, y: -1 }}
          transition={{ duration: 0.5 }}
        >
          <Stack key={e.eventId} paddingBottom={"4px"}>
            <EventNameBoxWrapper>
              <EventNameBox event={e}/>
            </EventNameBoxWrapper>
            <SimpleGrid columns={columns} spacing={"4px"} marginInlineStart={0} marginTop={"0px!important"}>
              {(e.photoCards)?.map((photoCard) => {
                return (
                  <GridItem key={photoCard.photoCardId} aspectRatio={"55/87"}>
                    {
                      photoCard &&
                      <PhotoCard
                        photoCard={photoCard}
                        onClick={() => onClickedPhotoCard(photoCard, e)}
                        {...longPressHandler(photoCard)}
                      />
                    }
                  </GridItem>
                )
              })}
            </SimpleGrid>
          </Stack>
        </motion.div>
      )
    }
  };
  // #endregion

  return (
    <Virtuoso
      useWindowScroll
      style={{ height: '100%' }}
      endReached={endReached}
      overscan={480}
      data={events}
      itemContent={(_, e) => { return drawCardsWithEventName(e)}}
    />
  )
}
