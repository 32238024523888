import { useEffect, useState } from "react"
import { useLocation, useNavigate, useSearchParams } from "react-router-dom"
import styled from "styled-components"
import LightColors from "../../constants/LightColors"
import { setEvent } from "../../utils/ga"

const TabWrapper = styled.div`
  display: grid;
  grid-gap: 0px;
  grid-template-columns: repeat(${(props) => props.tabsCount}, minmax(0, 1fr));
  place-items: center;
  border-radius: 12px;
  background-color: ${LightColors.surface};
  padding: 5px;
  margin-top: 4px;
  margin-bottom: 4px;
`

const TabItem = styled.div`
  border-radius: 10px;
  background: ${(props) => props.isSelected ? LightColors.purewhite : "transparent"};
  box-shadow: ${(props) => props.isSelected ? "0px 2px 4px 0px rgba(0, 0, 0, 0.05)" : ""};
  padding: 9px 0; /* 상하 패딩을 통합하여 중앙 정렬 */
  width: 100%;
  font-weight: 600;
  font-size: 15px;
  line-height: 18px;
  text-align: center;
  display: flex; /* 플렉스 박스를 사용하여 아이콘을 중앙에 배치 */
  justify-content: center; /* 수평 중앙 정렬 */
  align-items: center; /* 수직 중앙 정렬 */
`

const BoxTabs = ({onTabSelected, tabLabels}) => {
  const location = useLocation();
  const currentQueryParams = new URLSearchParams(location.search);
  const [tabIndex, setTabIndex] = useState(parseInt(currentQueryParams.get("boxTabIndex") || 0));

  const [searchParams, _] = useSearchParams();
  const navigate = useNavigate();

  useEffect(() => {
    if (onTabSelected) {
      onTabSelected(tabIndex);
    }
  }, [onTabSelected, tabIndex]);

  const selectTab = (tabIndex) => {
    setTabIndex(tabIndex);
    setEvent({
      category: "BoxTabs",
      action: 'Click BoxTab',
      label: `${location.pathname}-${tabIndex}`,
    })
    const newSearchParams = new URLSearchParams(searchParams);
    newSearchParams.set('boxTabIndex', tabIndex);
    navigate(`${location.pathname}?${newSearchParams.toString()}`, { replace: true });
  }

  return (
    <TabWrapper tabsCount={tabLabels.length}>
      {
        tabLabels.map((label, index) => {
          return (
            <TabItem
              key={index}
              isSelected={tabIndex === index}
              onClick={() => selectTab(index)}
            >
              {label}
            </TabItem>
          )
        })
      }
    </TabWrapper>
  )
}

export default BoxTabs;
