import { motion } from "framer-motion";
import styled from "styled-components";
import LightColors from "../../constants/LightColors";

const Title = styled.div`
  font-size: 17px;
  font-weight: 600;
  line-height: 20px;
  text-align: left;
  white-space: pre-wrap;
  padding: 32px 24px 16px 24px;
  color: ${LightColors.textmainlv1};
`;

const StepComponent = ({ nextStep, currentStep, maxStep, title, children }) => {
  return (
    <>
      {(nextStep <= currentStep + 1) && (
        <motion.div
          initial={{ opacity: 0, y: -10 }}
          animate={{ opacity: 1, y: 0 }}
          exit={{ opacity: 0, y: -10 }}
          transition={{ duration: 0.3, delay: 0.3 }}
          style={{marginBottom: currentStep >= maxStep ? "128px" : "0px"}}
        >
          {title && <Title>{title}</Title>}
          {children}
        </motion.div>
      )}
    </>
  );
};

export default StepComponent;
