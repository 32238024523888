import React from "react";

import { Box } from "@chakra-ui/react";
import styled from "styled-components";
import LightColors from "../../constants/LightColors";

const ToastMessage = styled.div`
  font-size: 14px;
  font-weight: 600;
  line-height: 17px;
  text-align: center;
  color: ${LightColors.purewhite}
`


const defaultToastStyle = {
  color: "white",
  backgroundColor: "rgba(0, 0, 0, 0.64)",
  borderRadius: "32px",
  marginBottom: "75vh",
}

const bottomToastStyle = {
  color: "white",
  backgroundColor: "rgba(0, 0, 0, 0.64)",
  borderRadius: "32px",
  marginBottom: "10vh",
}

export default function ToastMessageBox({leftIcon, message, showOnBottom=false}) {
  return (
    <Box
      className="text-center"
      p={2}
      style={showOnBottom ? bottomToastStyle : defaultToastStyle}
      display={"flex"}
      alignItems={"center"}
      justifyContent={"center"}
      flexDirection={"row"}
      gap={"4px"}>
        {leftIcon && leftIcon}
        <ToastMessage>{message}</ToastMessage>
    </Box>
  )
}
