import React, { useState } from "react";
import LightColors from "../../constants/LightColors";
import styled from "styled-components";

const StyledSelect = styled.select`
  padding: 16px 0;
  border: 1px solid ${LightColors.separatorlv2};
  width: 100%;
  text-align: center;
  font-size: 17px;
  font-weight: 600;
  line-height: 20px;
  border-radius: 8px;
  appearance: none; /* Hides the default arrow */
  -webkit-appearance: none; /* Safari/Chrome */
  -moz-appearance: none; /* Firefox */
  outline: none; /* Removes focus outline */
  background-color: ${LightColors.purewhite};

  &:focus {
    outline: none; /* Removes browser default focus outline */
    border: 1px solid ${LightColors.primary};
    background-color: ${LightColors.secondary};
    color: ${LightColors.primary};
  }
  &.selected { // hover 및 선택된 상태일 때
    border: 1px solid ${LightColors.primary};
    background-color: ${LightColors.secondary};
    color: ${LightColors.primary};
  }
`;


const YearPicker = ({initYear, minYear, maxYear, postfix="", prefix="", onSelectedYear}) => {
  const startYear = minYear || new Date().getFullYear() - 100;
  const endYear = maxYear || new Date().getFullYear();
  const [selectedYear, setSelectedYear] = useState();

  const handleChange = (event) => {
    setSelectedYear(event.target.value);
    onSelectedYear(event.target.value);
  };

  return (
    <div className="w-full">
      <StyledSelect
        value={selectedYear || initYear}
        onChange={handleChange}
        className={selectedYear ? 'selected' : ''}
        style={{
          textAlign: "-webkit-center",
          textAlignLast: "center",
          direction: "ltr"
        }}
      >
        {Array.from({ length: endYear - startYear + 1 }, (_, index) => {
          const year = startYear + index;
          return (
            <option key={index} value={year} selected={year === selectedYear} style={{textAlign: "center", direction: "ltr"}}>
              {prefix}{year}{postfix}
            </option>
          );
        })}
      </StyledSelect>
    </div>
  );
};

export default YearPicker;
