
import React, { useEffect, useState } from "react";

import { Center, Flex, HStack, SimpleGrid, Spacer, Spinner, Switch } from '@chakra-ui/react';
import styled from "@emotion/styled";
import { t } from "i18next";
import { chunk, isEmpty, isNull } from "lodash";
import EasyVirtualized from 'react-easy-virtualized';
import 'react-horizontal-scrolling-menu/dist/styles.css';
import { useQueryClient } from "react-query";
import { useNavigate } from "react-router-dom";
import { Virtuoso } from "react-virtuoso";

import LightColors from "../../constants/LightColors";
import { ReactComponent as RightArrowIcon } from "../../icons/arrow.right.svg";
import baseUrl from "../../utils/baseUrl";
import getToken from "../../utils/getToken";
import TradingPhotoCardResponse from "../api/model/TradingPhotoCardResponse";
import useExploreTradingPhotoCardsQueryV2 from "../api/queries/useExploreTradingPhotoCardsQueryV2";
import { useMatchedPhotoCards, useTradingPhotoCardsAll } from "../api/queries/useTradingPhotoCards";
import SecondaryLineButton from "../buttons/SecondaryLineButton";
import TradingPhotoCardFilterBottomDrawer from "../drawer/TradingPhotoCardFilterBottomDrawer";
import HorizontalScrollMembers from "../lists/HorizontalScrollMembers";
import { ListRenderer } from "../lists/ListRenderer";
import ArtistIconSelector from "../selectors/ArtistIconSelector";
import GridSkeletons from "../skeletons/GridSkeletons";
import EmptyExploreTradingPhotoCard from "./EmptyExploreTradingPhotoCard";
import EmptyMatchedTradingPhotoCard from "./EmptyMatchedTradingPhotoCard";
import ExploreTradingPhotoCard from "./ExploreTradingPhotoCard";
import MatchedTargetTradingPhotoCard from "./MatchedTargetTradingPhotoCard";
import OnGoingMatchingTradingPhotoCard from "./OngoingMatchedTradingPhotoCard";
import TradingManagerBarV2 from "./TradingManagerBarV2";
import { queryGet } from "../../utils/queryClient";

async function requestReMatching(memberId) {
  return fetch(baseUrl() + `/trading_photo_cards/rematching`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + getToken(),
    },
    body: JSON.stringify({
      member_id: memberId,
    }),
  }).then((data) => data.json());
}

const ArtistIconSelectorWrapper = styled.div`
  padding-top: 20px;
`

const ManagerBarWrapper = styled.div`
  padding-bottom: 24px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.05);
`

const ListWrapper = styled.div`
  padding: 0px 24px;
`

const TitleWrapper = styled.div`
  padding: 20px 24px 4px 24px;
`
const MembersWrapper = styled.div`
  padding: 0px 0px 14px 0px;
`

const Title = styled.div`
  font-size: 17px;
  font-weight: 700;
  line-height: 20px;
  text-align: left;
  color: ${LightColors.textmainlv2};
`

const FilterButtonText = styled.div`
  color: ${(props) => (props.isChecked ? LightColors.primary : LightColors.textmainlv1)};
  font-size: 14px;
  font-weight: 600;
  line-height: 17px
`

const ChatRoomsBox = styled.div`
  background: #0000000D;
  padding-top: 5px;
  padding-bottom: 5px;
`

const ChatRoomsText = styled.div`
  padding: 11px 0px;
  font-size: 14px;
  font-weight: 600;
  line-height: 17px;
  text-align: center;
  background: -webkit-linear-gradient(180deg, #1E4BC7, #8400F0);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  -webkit-background-clip: text;
  color: transparent;
`

export default function ExchangesTabPanel({fabWrapperRef}){
  const queryClient = useQueryClient();
  const navigate = useNavigate();

  // const [boxTabIndex, setBoxTabIndex] = useState(0);
  const [selectedArtist, setSelectedArtist] = useState(null);
  const [onlyIdentityVerified, setOnlyIdentityVerified] = useState(false);
  const [selectedMember, setSelectedMember] = useState(null);
  const [selectedEventIds, setSelectedEventIds] = useState([]);
  const [selectedPhotoCardCategories, setSelectedPhotoCardCategories] = useState([]);
  const [selectedTradingPhotoCard, setSelectedTradingPhotoCard] = useState();
  const [paginatedTradingPhotoCards, setPaginatedTradingPhotoCards] = useState();
  const [isFilteringDrawerOpen, setIsFilteringDrawerOpen] = useState(false);

  const myTradingPhotoCards = useTradingPhotoCardsAll({category: "exchange"});
  const handlePaginatedTradingPhotoCards = (paginatedData) => {
    const tradingPhotoCards = paginatedData.pages?.flatMap((page) =>
      page.tradingPhotoCards.map((tradingPhotoCard) => new TradingPhotoCardResponse(tradingPhotoCard))
    )
    setPaginatedTradingPhotoCards(tradingPhotoCards);
  }

  const { data, isFetching, fetchNextPage, hasNextPage } = useExploreTradingPhotoCardsQueryV2({
    artistId: selectedArtist?.artistId,
    onlyIdentityVerified: onlyIdentityVerified,
    memberIds: selectedMember?.memberId ?? [],
    photoCardCategories: selectedPhotoCardCategories,
    eventIds: selectedEventIds,
    category: "exchange",
  });

  const loadMore = () => {
    if(!isFetching && hasNextPage) {
      fetchNextPage()
    }
  }

  useEffect(() => {
    if (!isEmpty(data)) {
      handlePaginatedTradingPhotoCards(data);
    }
  }, [data]);

  const matchedTradingPhotoCards = useMatchedPhotoCards({
    artistId: selectedTradingPhotoCard?.artistId,
    tradingPhotoCardId: selectedTradingPhotoCard?.tradingPhotoCardId,
    memberId: selectedMember?.memberId,
    onlyIdentityVerified: onlyIdentityVerified,
    photoCardCategories: selectedPhotoCardCategories,
    eventIds: selectedEventIds,
  });


  const isOngoingMatching = () => {
    if (isEmpty(selectedTradingPhotoCard)) return false;
    return isNull(selectedTradingPhotoCard.matchedCount) || selectedTradingPhotoCard.matchedCount <= 0;
  };

  const onClickReMatch = async () => {
    if(isEmpty(selectedTradingPhotoCard)) return;
    if(!selectedTradingPhotoCard.memberId) return;

    const response = await requestReMatching(selectedTradingPhotoCard.memberId)
    if (response.success) {
      queryClient.invalidateQueries(["tradingPhotoCards"]);
      setTimeout(() => { queryClient.invalidateQueries(["matchedTradingPhotoCards"]) }, 1500)
    }
  }

  const onSelectedArtist = (artist) => {
    setSelectedArtist(artist);
    initFilters();
  }

  const onSelectedMember = (member) => {
    if(isEmpty(member)) {
      setSelectedMember(null);
    } else {
      setSelectedMember(member);
    }
  }

  const onSelectedTradingPhotoCard = async (tradingPhotoCard) => {
    if (isEmpty(tradingPhotoCard)) {
      setSelectedTradingPhotoCard(null);
      initFilters();
    } else {
      const url = `/api/v1/artists/${tradingPhotoCard.artistId}`;
      const response = await queryGet(url);
      if (response?.artist) {

        onSelectedArtist(response?.artist);
        setSelectedTradingPhotoCard(tradingPhotoCard)
      }
    }
  }

  const initFilters = () => {
    setOnlyIdentityVerified(false)
    setSelectedEventIds([]);
    setSelectedPhotoCardCategories([]);
  }

  const handleFilterSelected = (filteredObj) => {
    setIsFilteringDrawerOpen(false);
    if (!isEmpty(filteredObj)) {
      setOnlyIdentityVerified(filteredObj.identityVerified);
      setSelectedEventIds(filteredObj.eventIds);
      setSelectedPhotoCardCategories(filteredObj.photoCardCategories);

      queryClient.invalidateQueries(["matchedTradingPhotoCards"]);
    }
  }

  const isCheckedPhotoCardFilter = () => {
    return onlyIdentityVerified || !isEmpty(selectedPhotoCardCategories) || !isEmpty(selectedEventIds)
  }

  const onOpenFilterDrawer = (isOpen) => {
    if(fabWrapperRef.current) {
      fabWrapperRef.current.style.visibility = isOpen ? 'hidden' : 'visible';
    }
    setIsFilteringDrawerOpen(isOpen);
  }

  return (
    <>
      <ManagerBarWrapper>
        <TradingManagerBarV2
          tradingPhotoCards={myTradingPhotoCards}
          isShowExploreCard={true}
          category={"exchange"}
          isShowChatRoomsCount={true}
          onClickTradingPhotoCard={(tradingPhotoCard) => onSelectedTradingPhotoCard(tradingPhotoCard)}
        />
      </ManagerBarWrapper>
      {
        isEmpty(selectedTradingPhotoCard) ?
        <ArtistIconSelectorWrapper>
          <ArtistIconSelector onSelected={(artist) => onSelectedArtist(artist)}/>
        </ArtistIconSelectorWrapper>
        :
        selectedTradingPhotoCard.chatRoomsCount > 0 &&
        <ChatRoomsBox onClick={() => navigate(`/trading_photo_cards/${selectedTradingPhotoCard.tradingPhotoCardId}/my_chat_rooms`)}>
          <HStack placeContent={"center"}>
            <ChatRoomsText>
              {t("ExchangesTabPanel.chatRoomsBoxText", {count: selectedTradingPhotoCard.chatRoomsCount})}
            </ChatRoomsText>
            <RightArrowIcon width={"15px"} height={"15px"} fill="#1E4AC7"/>
          </HStack>
        </ChatRoomsBox>
      }

      <TitleWrapper>
        <RenderTitleAndFilterSection
          selectedTradingPhotoCard={selectedTradingPhotoCard}
          isCheckedPhotoCardFilter={isCheckedPhotoCardFilter()}
          setIsFilteringDrawerOpen={onOpenFilterDrawer}
        />
      </TitleWrapper>
      <MembersWrapper>
        <HorizontalScrollMembers
          members={selectedArtist?.members}
          selectedMember={selectedMember}
          onSelectedMember={(member) => onSelectedMember(member)}
        />
      </MembersWrapper>
      {
        isEmpty(selectedTradingPhotoCard) ?
          <ListWrapper>
            <ListRenderer
              data={paginatedTradingPhotoCards}
              skeletonComponent={<GridSkeletons gridSize={3} skeletonSize={3} skeletonHeight={'96px'} showText={true} />}
              emptyComponent={<EmptyExploreTradingPhotoCard />}
              renderList={(data) => (
                <PaginatedTradingPhotoCardsList
                  paginatedTradingPhotoCards={data}
                  loadMore={loadMore}
                  hasNextPage={hasNextPage}
                />
              )}
            />
          </ListWrapper> :
        (
          isEmpty(matchedTradingPhotoCards) ? (
            <RenderOngoingOrEmptyMatch
              isOngoingMatching={isOngoingMatching()}
              onClickReMatch={onClickReMatch}
            />
          ) :
          <ListWrapper>
            <RenderMatchedTradingPhotoCardsList
              matchedTradingPhotoCards={matchedTradingPhotoCards}
            />
          </ListWrapper>
        )
      }
      {
        isFilteringDrawerOpen &&
        <TradingPhotoCardFilterBottomDrawer
          isOpen={isFilteringDrawerOpen}
          onClose={() => onOpenFilterDrawer(false)}
          onConfirm={(filteredObj) => handleFilterSelected(filteredObj)}
          identityVerified={onlyIdentityVerified}
          artistId={selectedArtist?.artistId}
          eventIds={selectedEventIds}
          photoCardCategories={selectedPhotoCardCategories}
        />
      }
    </>
  )
}


const EmptyFilterButton = styled.div`
  height: 35px;
`

const RenderTitleAndFilterSection = ({selectedTradingPhotoCard, isCheckedPhotoCardFilter, setIsFilteringDrawerOpen}) => (
  <Flex align={"center"} marginY={"2px"}>
    <Title>
      {t("ExchangesTabPanel.availablePoca")}
    </Title>
    <Spacer/>
    {
      isEmpty(selectedTradingPhotoCard) ?
      <SecondaryLineButton
        text={
          <FilterButtonText isChecked={isCheckedPhotoCardFilter}>
            {t("photoCardPage.filter")}
          </FilterButtonText>
        }
        rightElement={
          <Switch
            size={'sm'}
            alignContent={"center"}
            colorScheme={"primary"}
            isChecked={isCheckedPhotoCardFilter}
            onChange={() => setIsFilteringDrawerOpen(true)}
            style={{"--switch-track-height": "0.6rem"}}
            // TODO Switch BGColor
          />
        }
        style={{
          margin: "1.5px 0px",
          border: isCheckedPhotoCardFilter ? `1.5px solid ${LightColors.primary}` : `1px solid rgba(0, 0, 0, 0.05)`,
          boxShadow: isCheckedPhotoCardFilter ? "0px 0px 5px 0px rgba(255, 118, 250, 0.2), 0px 0px 8px 0px rgba(161, 142, 255, 0.3)" : "",
          padding: "8px 14px",
          gap: "4px",
        }}
        onClickButton={() => setIsFilteringDrawerOpen(true)}
      /> :
      <EmptyFilterButton/>
    }

  </Flex>
)

const RenderOngoingOrEmptyMatch = ({isOngoingMatching, onClickReMatch}) => (
  <ListWrapper>
    {isOngoingMatching ? (
      <OnGoingMatchingTradingPhotoCard />
    ) : (
      <EmptyMatchedTradingPhotoCard onClickReMatch={onClickReMatch} />
    )}
  </ListWrapper>
);


const ContainerWithPadding = styled.div`
  padding-bottom: calc(56px + env(safe-area-inset-bottom));
`;

const RenderMatchedTradingPhotoCardsList = ({ matchedTradingPhotoCards }) => (
  (
    <ContainerWithPadding>
      <Virtuoso
        useWindowScroll
        overscan={480}
        data={chunk(matchedTradingPhotoCards, 3)}
        style={{ height: "100px" }}
        itemContent={(index, chunkCards) => (
          <SimpleGrid key={index} columns={3} spacing={"7px"} marginBottom={"24px"}>
            {
              chunkCards.map((card) => {
                return(
                  <MatchedTargetTradingPhotoCard
                    key={card.matchedTradingPhotoCardId}
                    matchedTradingPhotoCard={card}
                  />
                )
              })
            }
          </SimpleGrid>
        )}
      />
    </ContainerWithPadding>
  )
);

const PaginatedTradingPhotoCardsList = ({ paginatedTradingPhotoCards, hasNextPage, loadMore }) => (
  <ContainerWithPadding>
    <EasyVirtualized
      useParentScrollElement={true}
      overscanRowCount={3}
      onLoadMore={loadMore}
      hasMore={hasNextPage}
      loader={
        <Center marginTop={"8px"}>
          <Spinner size='sm' color="primary.500" marginRight={"20px"}/>
        </Center>
      }
    >
      {
        chunk(paginatedTradingPhotoCards, 3).map((chunkCards, chunkIndex) => (
          <SimpleGrid key={chunkIndex} columns={3} spacing={"7px"} marginBottom={"24px"}>
            {
              chunkCards.map((card) => {
                return(
                  <ExploreTradingPhotoCard
                    key={card.tradingPhotoCardId}
                    tradingPhotoCard={card}
                  />
                )
              })
            }
          </SimpleGrid>
          ))
      }
    </EasyVirtualized>
  </ContainerWithPadding>
);
