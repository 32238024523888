import styled from "styled-components"
import LightColors from "../../constants/LightColors"
import { useLocation, useNavigate } from "react-router-dom"

import { ReactComponent as NextFillIcon } from "../../icons/next.fill.svg";
import { setEvent } from "../../utils/ga"
import useUserQuery from "../api/queries/useUserQuery";
import { isEmpty } from "lodash";

const FanBuddyBarWrapper = styled.div`
  padding: 12px 18px;
  background: linear-gradient(90deg, #FF73C7 0%, #FF2A71 50%, #FF73C7 100%);
  background-size: 200% 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  animation: gradient-flow 3s linear infinite;

  @keyframes gradient-flow {
    0% {
      background-position: 0% 50%;
    }
    100% {
      background-position: 200% 50%;
    }
  }
`

const FanBuddyBarText = styled.div`
  display: flex;
  flex-direction: row;
  gap: 4px;
  font-size: 14px;
  font-weight: 400;
  line-height: 17px;
  text-align: center;
  color: ${LightColors.purewhite};
  align-items: center;
`

const FanBuddyBar = ({...props}) => {
  const location = useLocation();
  const navigate = useNavigate();

  const user = useUserQuery();

  const onClickSearchBuddy = () => {
    setEvent({
      category: 'FanBuddy',
      action: 'StartFanBuddy',
      label: location.pathname,
    });

    if (isEmpty(user.mbti)) {
      navigate("/fan-buddy/intro");
    } else {
      navigate(`/fan-buddy/show-fan-buddy-card?mbti=${user.mbti}`);
    }
  }
  return(
    <FanBuddyBarWrapper
      onClick={() => onClickSearchBuddy()}
      {...props}
    >
      <FanBuddyBarText style={{ fontWeight: "600"}}>
        {"덕메찾기 사전 등록 OPEN💛"}
      </FanBuddyBarText>
      <FanBuddyBarText style={{ fontSize: "13px"}}>
        <div>{"신청하기"}</div>
        <NextFillIcon width={"13px"} height={"13px"} fill={LightColors.purewhite}/>
      </FanBuddyBarText>
    </FanBuddyBarWrapper>
  )
}

export default FanBuddyBar;
