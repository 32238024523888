import { AbsoluteCenter, Text, VStack } from '@chakra-ui/react';
import React from "react";
import FilledButton from "../FilledButton";
import { t } from 'i18next';

export default function ErrorBoundaryHome() {
  const navigateArtistHome = () => {
    window.location.href = `/home`;
  }

  return (
      <AbsoluteCenter>
        <VStack marginTop={"40px"} className="text-center">
          <Text fontSize={"50px"}>🥺</Text>
          <Text fontSize={"17px"} fontWeight={700} color={"#444444"}>
            {t("errorBoundary.title")}
          </Text>
          <Text fontSize={"14px"} fontWeight={400} color={"#888888"} paddingBottom={"20px"}>
            {t("errorBoundary.description")}
          </Text>
          <FilledButton
            paddingX={"18px"}
            paddingY={"11px"}
            filled={false}
            color={"#444444"}
            fontWeight={600}
            fontSize={"14px"}
            text={t("errorBoundary.button")}
            backgroundColor={"#FFF"}
            borderWidth={"1px"}
            borderColor={"#E7E7E7"}
            onClicked={()=>navigateArtistHome()}
          />
        </VStack>
      </AbsoluteCenter>
  )
}
