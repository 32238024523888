import { Box, Grid, GridItem, Image, Stack, Text, WrapItem } from '@chakra-ui/react';
import { t } from 'i18next';
import { findIndex } from "lodash";
import React, { useState } from "react";
import { EventNameBox } from './event/EventNameBox';
import PhotoCard from "./photocard/PhotoCard";
import styled from 'styled-components';

const EventNameBoxWrapper = styled.div`
  overflow: hidden;
  padding-top: 10px;
  padding-bottom: 4px
`

export default function BlacklistPhotoCardsByEvent({photoCardsByEvent, selectedPhotoCards, onClickedPhotoCard, collectingMode=true, editable=true, removeCollected=false}) {
  const getIndex = (photoCard) => {
    return findIndex(selectedPhotoCards, (c) => c.photoCardId === photoCard.photoCardId)
  }
  const isEnabledCard = (photoCard) => {
    if(selectedPhotoCards){
      return getIndex(photoCard) >= 0;
    }
    else{
      return photoCard.collectedCount > 0
    }
  }

  const [endOfScroll, setEndOfScroll] = useState(false);
  const listComponent = document.querySelector('.endOfList');
  document.addEventListener('scroll', () => {
    if (listComponent && (window.scrollY > listComponent.getBoundingClientRect().top)) {
      setEndOfScroll(true)
    }
  });

  const getPaginatedEvent = () => {
    return photoCardsByEvent?.events
  }

  return (
    <div className="endOfList">
    {
        (getPaginatedEvent())?.map((e) => {
          if(e.photoCards <= 0){
            return (<div key={e.event?.name}></div>)
          }else{
            return(
              <Stack key={e.event?.name} paddingBottom={"18px"}>
                <EventNameBoxWrapper>
                  <EventNameBox event={e.event}/>
                </EventNameBoxWrapper>
                <Grid templateColumns={`repeat(4, 1fr)`} gap={"4px"}>
                  {(removeCollected ? e.photoCards.filter((p) => p.collectedCount > 0) : e.photoCards).map((photoCard) => {
                    return (
                      <GridItem key={photoCard.photoCardId}>
                        {
                          (editable || collectingMode) ? (
                            <WrapItem className="relative">
                              <Image
                                src={photoCard.mediumThumbnailUrl || photoCard.pictureUrl}
                                className={isEnabledCard(photoCard) ? "grayscale opacity-50" : "grayscale-0"}
                                height={"100%"}
                                width={"100%"}
                                border={"2px"}
                                borderColor={collectingMode && isEnabledCard(photoCard) ? "secondary.1000" : "#444"}
                                borderRadius="8px"
                                onClick={() => onClickedPhotoCard(photoCard, e.event)}
                                style={{boxSizing: "border-box"}}
                                loading={endOfScroll ? "lazy" : null}
                              />
                              {
                                (
                                  collectingMode &&
                                  <div
                                    className="absolute right-0 bottom-0"
                                    style={{ backgroundColor: isEnabledCard(photoCard) ? "#444" : "#5C3DFA", borderTopLeftRadius: "8px", borderBottomRightRadius: "8px", padding: "3px" }}
                                  >
                                    <Box paddingX={"6px"}>
                                      <Text
                                        color={"#FFF"}
                                        fontSize={"11px"}
                                        fontWeight={700}>
                                        {isEnabledCard(photoCard) ? t("tradingPhotoCardPage.excludeMatching") : t("tradingPhotoCardPage.allowMatching")}
                                      </Text>
                                    </Box>
                                  </div>
                                )
                              }
                            </WrapItem>
                          ) : (
                            photoCard && <PhotoCard photoCard={photoCard} onClick={() => onClickedPhotoCard(photoCard, e.event)}/>
                          )
                        }
                      </GridItem>
                    )
                  })}
                </Grid>
              </Stack>
            )
          }
        })
    }
    </div>
  )
}
